import {
  Component,
  OnInit,
  Input,
  DestroyRef,
  inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Constants } from 'src/app/shared/globals/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationService } from 'src/app/core/navigation.service';
import { BehaviorSubject } from 'rxjs';
import _ from 'lodash';
import { Reference } from 'src/app/settings-app/reference/model/reference.model';
import { Exception } from 'src/app/shared/models/exception';
import { ReferenceCardService } from 'src/app/settings-app/reference/card/reference-card.service';

@Component({
  selector: 'tmt-main-reference',
  templateUrl: './main-reference.component.html',
  styleUrl: './main-reference.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainReferenceComponent implements OnInit {
  @Input() public entityId: string;

  public activeTab: string;
  public readonly: boolean;
  public referenceForm: UntypedFormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    isActive: false,
  });
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);

  private destroyRef = inject(DestroyRef);

  constructor(
    public actionService: ActionPanelService,
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
    private referenceCardService: ReferenceCardService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.actionService.set([
      {
        title: 'shared2.actions.save',
        hint: 'shared2.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.save(),
      },
    ]);

    this.actionService.setHasAutosave(false);
    this.load();

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.reload();
      });
  }

  /**
   * Saves reference.
   *
   * @returns Promise <void>.
   */
  public async save(): Promise<void> {
    this.referenceForm.markAllAsTouched();

    if (this.referenceForm.invalid) {
      this.notificationService.warningLocal(
        'shared2.messages.requiredFieldsError',
      );
      return;
    }

    this.actionService.action('save').start();

    this.referenceCardService
      .patchReference(this.entityId, this.referenceForm.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.referenceForm.markAsPristine();
          this.actionService.action('save').stop();
          this.referenceCardService.updateName(this.referenceForm.value.name);
          this.cdr.markForCheck();
          this.notificationService.successLocal(
            'components.mainReferenceComponent.messages.saved',
          );
        },
        error: (error: Exception) => {
          this.actionService.action('save').stop();
          this.notificationService.error(error.message);
        },
      });
  }

  private load(): void {
    this.state$.next(CardState.Loading);

    this.referenceForm.markAsPristine();
    this.referenceForm.markAsUntouched();

    this.referenceCardService.getReferences(this.entityId).subscribe({
      next: (reference: Reference) => {
        this.referenceForm.patchValue(reference);
        this.readonly = false;
        this.actionService.action('save').isShown = !this.readonly;
        // TODO: when backend ready
        this.readonly
          ? this.referenceForm.enable()
          : this.referenceForm.enable();

        this.state$.next(CardState.Ready);

        this.navigationService.addRouteSegment({
          id: reference.id,
          title: reference.name,
        });
      },
      error: (error: Exception) => {
        this.state$.next(CardState.Error);
        this.notificationService.error(error.message);
      },
    });
  }

  /* Reloads view. */
  private reload(): void {
    if (!this.referenceForm.dirty) {
      this.load();
    } else {
      this.messageService.confirmLocal('shared2.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }
}
