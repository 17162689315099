import { Component, OnInit, Inject, DestroyRef, inject } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';
import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { LegalEntity } from 'src/app/shared/models/entities/settings/legal-entity.model';

import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';

@Component({
  selector: 'tmt-legal-entity-card',
  styleUrl: './legal-entity-card.component.scss',
  templateUrl: './legal-entity-card.component.html',
  host: {
    class: 'legal-entity-main',
  },
})
export class LegalEntityCardComponent implements OnInit {
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  public readonly: boolean;
  public isSaving = false;
  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    code: ['', Validators.maxLength(Constants.formCodeMaxLength)],
    manager: [null, Validators.required],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    isDefault: [false],
    isActive: [false],
    currency: [null, Validators.required],
  });

  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public legalEntityId: string,
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private actionPanelService: ActionPanelService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private customFieldService: CustomFieldService,
    private appService: AppService,
    private navigationService: NavigationService,
  ) {}

  public ngOnInit(): void {
    this.customFieldService.enrichFormGroup(this.form, 'LegalEntity');

    this.actionPanelService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.save(),
      },
      {
        title: 'shared.actions.useByDefault',
        hint: 'shared.actions.useByDefault',
        name: 'setAsDefault',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: () => this.setAsDefault(),
      },
    ]);

    this.load();
    this.setSubscriptions();
  }

  /** Saves form. */
  public save(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );

      return;
    }

    this.isSaving = true;
    this.actionPanelService.action('save').start();

    const legalEntity = this.form.value;

    const data: any = {
      name: legalEntity.name,
      code: legalEntity.code,
      description: legalEntity.description,
      isActive: legalEntity.isActive,
      managerId: legalEntity.manager?.id,
      currencyId: legalEntity.currency?.id,
    };

    this.customFieldService.assignValues(data, this.form.value, 'LegalEntity');

    this.dataService
      .collection('LegalEntities')
      .entity(this.legalEntityId)
      .patch(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving = false;
          this.actionPanelService.action('save').stop();
          this.notificationService.successLocal(
            'settings.legalEntity.messages.saved',
          );
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.actionPanelService.action('save').stop();
          this.notificationService.error(error.message);
        },
      });
  }

  /** Sets the legal entity as default. */
  public setAsDefault(): void {
    this.dataService
      .collection('LegalEntities')
      .entity(this.legalEntityId)
      .action('WP.SetAsDefault')
      .execute()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.notificationService.successLocal(
            'settings.legalEntity.messages.hasBeenSetByDefault',
          );
          this.actionPanelService.action('setAsDefault').isShown = false;
          this.form.get('isDefault').setValue(true);
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.actionPanelService.action('save').stop();
          this.notificationService.error(error.message);
        },
      });
  }

  /** Loads data. */
  private load(): void {
    this.state$.next(CardState.Loading);
    this.form.markAsPristine();
    this.form.markAsUntouched();

    const query = {
      select: ['name', 'code', 'description', 'isActive', 'isDefault', 'id'],
      expand: [
        {
          manager: { select: ['id', 'name'] },
        },
        {
          currency: { select: ['id', 'name'] },
        },
      ],
    };

    this.customFieldService.enrichQuery(query, 'LegalEntity');

    this.dataService
      .collection('LegalEntities')
      .entity(this.legalEntityId)
      .get<LegalEntity>(query)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (legalEntity) => {
          this.navigationService.addRouteSegment({
            id: legalEntity.id,
            title: legalEntity.name,
          });

          this.form.patchValue(legalEntity);
          this.readonly = !this.appService.checkEntityPermission(
            'LegalEntity',
            PermissionType.Modify,
          );

          this.actionPanelService.action('save').isShown = !this.readonly;
          this.actionPanelService.action('setAsDefault').isShown =
            !legalEntity.isDefault && !this.readonly;

          if (this.readonly) {
            this.form.disable();
          } else {
            this.form.enable();
          }

          this.state$.next(CardState.Ready);
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          this.notificationService.error(error.message);
        },
      });
  }

  /** Reloads data. */
  private reload(): void {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.messageService.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  private setSubscriptions(): void {
    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.reload();
      });
  }
}
