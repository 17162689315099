import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import {
  GridColumnType,
  GridNavigationColumn,
  NavigationType,
} from 'src/app/shared-features/grid2/models/grid-column.interface';

export const EMPLOYEE_LIST: List = {
  version: 1,
  name: 'employees',
  clrType: 'User',
  customFieldPrefixForDataField: 'user.',
  dataCollection: 'ExtendedEmployees',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'name',
          width: 2,
          visibleByDefault: true,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'code',
          width: 1,
          visibleByDefault: false,
        },
        {
          column: 'department',
          width: 2,
          visibleByDefault: true,
        },
        {
          column: 'resourcePool',
          width: 2,
          visibleByDefault: false,
        },
        {
          column: 'supervisor',
          width: 2,
          visibleByDefault: false,
        },
        {
          column: 'email',
          width: 1,
          visibleByDefault: false,
        },
        {
          column: 'phone',
          width: 1,
          visibleByDefault: false,
        },
        {
          column: 'location',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'level',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'grade',
          width: 1,
          visibleByDefault: false,
        },
        {
          column: 'role',
          width: 1,
          visibleByDefault: false,
        },
        {
          column: 'competence',
          width: 1,
          visibleByDefault: false,
        },
        {
          column: 'position',
          width: 1,
          visibleByDefault: false,
        },
        {
          column: 'utilizationOverPeriod',
          width: 120,
          visibleByDefault: true,
        },

        {
          column: 'actualCostOverPeriod',
          width: 120,
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHoursOverPeriod',
          width: 120,
          visibleByDefault: true,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualRevenueOverPeriod',
          width: 120,
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'scheduleHours',
          width: 120,
          visibleByDefault: true,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'scheduleDeviation',
          width: 120,
          visibleByDefault: true,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    {
      name: 'name',
      type: GridColumnType.ResourceNavigation,
      state: 'employee',
      header: 'employees.list.columns.name',
      hint: 'employees.list.columns.name',
      availableTotals: [TotalType.Count],
      navigationType: NavigationType.User,
    } as GridNavigationColumn,
    {
      name: 'code',
      header: 'shared.props.code',
      hint: 'shared.pros.code',
      type: GridColumnType.String,
    },
    {
      name: 'department',
      header: 'shared.props.department',
      hint: 'shared.props.department',
      type: GridColumnType.String,
    },
    {
      name: 'resourcePool',
      header: 'shared.props.resourcePool',
      hint: 'shared.props.resourcePool',
      type: GridColumnType.String,
    },
    {
      name: 'supervisor',
      header: 'shared.props.supervisor',
      hint: 'shared.props.supervisor',
      type: GridColumnType.String,
    },
    {
      name: 'email',
      header: 'shared.props.email',
      hint: 'shared.props.email',
      type: GridColumnType.String,
    },
    {
      name: 'phone',
      header: 'shared.props.phone',
      hint: 'shared.props.phone',
      type: GridColumnType.String,
    },
    {
      name: 'location',
      header: 'shared.props.location',
      hint: 'shared.props.location',
      type: GridColumnType.String,
    },
    {
      name: 'level',
      header: 'shared.props.level',
      hint: 'shared.props.level',
      type: GridColumnType.String,
    },
    {
      name: 'grade',
      header: 'shared.props.grade',
      hint: 'shared.props.grade',
      type: GridColumnType.String,
    },
    {
      name: 'role',
      header: 'shared.props.role',
      hint: 'shared.props.role',
      type: GridColumnType.String,
    },
    {
      name: 'competence',
      header: 'shared.props.competence',
      hint: 'shared.props.competence',
      type: GridColumnType.String,
    },
    {
      name: 'position',
      header: 'shared.props.position',
      hint: 'shared.props.position',
      type: GridColumnType.String,
    },
    {
      name: 'utilizationOverPeriod',
      fixedWidth: true,
      header: 'employees.list.columns.utilizationOverPeriod.header',
      hint: 'employees.list.columns.utilizationOverPeriod.hint',
      type: GridColumnType.Percent,
    },
    {
      name: 'actualHoursOverPeriod',
      fixedWidth: true,
      header: 'employees.list.columns.actualHoursOverPeriod.header',
      hint: 'employees.list.columns.actualHoursOverPeriod.hint',
      type: GridColumnType.Work,
      availableTotals: [TotalType.Sum],
    },
    {
      name: 'actualCostOverPeriod',
      fixedWidth: true,
      header: 'employees.list.columns.actualCostOverPeriod.header',
      hint: 'employees.list.columns.actualCostOverPeriod.hint',
      type: GridColumnType.Currency,
      availableTotals: [TotalType.Sum],
    },
    {
      name: 'actualRevenueOverPeriod',
      fixedWidth: true,
      header: 'employees.list.columns.actualRevenueOverPeriod.header',
      hint: 'employees.list.columns.actualRevenueOverPeriod.hint',
      type: GridColumnType.Currency,
      availableTotals: [TotalType.Sum],
    },
    {
      name: 'scheduleDeviation',
      fixedWidth: true,
      header: 'employees.list.columns.scheduleDeviation.header',
      hint: 'employees.list.columns.scheduleDeviation.hint',
      type: GridColumnType.Percent,
    },
    {
      name: 'scheduleHours',
      fixedWidth: true,
      header: 'employees.list.columns.scheduleHours.header',
      hint: 'employees.list.columns.scheduleHours.hint',
      type: GridColumnType.Work,
      availableTotals: [TotalType.Sum],
    },
    {
      name: 'created',
      header: 'shared.columns.created.hint',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'isActive',
      header: 'shared.columns.isActive.hint',
      hint: 'shared.columns.isActive.hint',
      type: GridColumnType.Boolean,
    },
  ],
  dataColumns: [
    {
      column: 'name',
      field: 'user.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'code',
      field: 'user.code',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'location',
      field: 'user.location.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'level',
      field: 'user.level.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'grade',
      field: 'user.grade.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'role',
      field: 'user.role.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'competence',
      field: 'user.competence.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'email',
      field: 'user.email',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'position',
      field: 'user.position',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'phone',
      field: 'user.phone',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'department',
      field: 'user.department.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'resourcePool',
      field: 'user.resourcePool.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'supervisor',
      field: 'user.supervisor.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'legalEntity',
      field: 'user.legalEntity.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isActive',
      field: 'user.isActive',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'created',
      field: 'user.created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'user.created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'createdBy',
      field: 'user.createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modifiedBy',
      field: 'user.modifiedBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'utilizationOverPeriod',
      field: 'extended.utilizationOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualHoursOverPeriod',
      field: 'extended.actualHoursOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualCostOverPeriod',
      field: 'extended.actualCostOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualRevenueOverPeriod',
      field: 'extended.actualRevenueOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'scheduleDeviation',
      field: 'extended.scheduleDeviationOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'scheduleHours',
      field: 'extended.scheduleHoursOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
};
