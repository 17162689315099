import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  inject,
  DestroyRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { TimesheetTemplate } from 'src/app/shared/models/entities/settings/timesheet-template.model';
import { TimeAllocation } from 'src/app/shared/models/entities/base/timesheet.model';
import { TimesheetCardService } from '../../core/timesheet-card.service';
import { TranslateService } from '@ngx-translate/core';
import { Line } from '../../shared/models/line.model';
import { auditTime } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SortService } from 'src/app/shared/components/features/sort/core/sort.service';
import _ from 'lodash';
import { SortDirection } from 'src/app/shared-features/comments/model/sort-direction.enum';
import {
  MetaEntityBaseProperty,
  MetaEntityPropertyType,
} from 'src/app/shared/models/entities/settings/metamodel.model';

@Component({
  selector: 'wp-timesheet-details',
  templateUrl: './timesheet-details.component.html',
  styleUrls: ['./timesheet-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimesheetDetailsComponent implements OnInit {
  @Input() template: TimesheetTemplate;

  entries: {
    allocation: TimeAllocation;
    firstLine: string;
    secondLine: string;
    activityName: string;
    roleName: string;
    projectCostCenterName: string;
    projectTariffName: string;
    schedule: number;
  }[] = [];

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    public timesheetCardService: TimesheetCardService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private sortService: SortService,
  ) {}

  public getCustomFieldValue(entry: any, field: MetaEntityBaseProperty) {
    const dataField = field.name;

    if (field.type === MetaEntityPropertyType.reference) {
      return entry.allocation[dataField]?.name;
    }
    return entry.allocation[dataField];
  }

  // Подготовка списка для отображения в списке комментариев.
  private hasDetails(allocation: TimeAllocation): boolean {
    if (allocation.comments) {
      return true;
    }

    let result = false;
    this.timesheetCardService.allocationCustomFields.forEach((field) => {
      if (allocation[field.name]) {
        result = true;
      }
    });
    return result;
  }

  public trackById = (index: number, row: any): string => row.allocation.id;

  public updateView(lines: Line[]) {
    if (!this.template) {
      return;
    }
    this.entries = [];

    lines.forEach((line) => {
      line.allocations.forEach((allocation) => {
        if (this.hasDetails(allocation)) {
          const entry = {
            allocation,
            firstLine: '',
            secondLine: '',
            activityName: '',
            roleName: '',
            projectCostCenterName: '',
            projectTariffName: '',
            schedule:
              this.timesheetCardService.timesheet.schedule.find(
                (s) => s.date === allocation.date,
              )?.hours ?? 0,
          };

          this.entries.push(entry);

          // Установка строки с клиентом и проектом.
          if (line.task.project) {
            let clientAndProjectString = '';

            if (this.template.showClient) {
              if (!line.task.client) {
                clientAndProjectString = `[${this.translate.instant(
                  'timesheets.card.taskSelector.withoutClient',
                )}]`;
              } else {
                clientAndProjectString = line.task.client.name;
              }

              if (!line.task.isMainTask) {
                clientAndProjectString += ' > ';
              }
            }

            if (!line.task.isMainTask) {
              clientAndProjectString += line.task.project.name;
            }

            entry.firstLine = clientAndProjectString;

            // Установить наименование задачи.
            if (line.task.projectTask) {
              entry.secondLine = line.task.projectTask.name;
            }
          }

          // Установить наименование вида работ.
          if (line.activity) {
            entry.activityName = line.activity.name;
          }

          // Установить наименование вида работ.
          if (line.role) {
            entry.roleName = line.role.name;
          }

          // Set cost center.
          if (line.projectCostCenter) {
            entry.projectCostCenterName = line.projectCostCenter.name;
          }

          /* Set tariff. */
          if (line.projectTariff) {
            entry.projectTariffName = line.projectTariff.name;
          }
        }
      });
    });

    this.entries = _.orderBy(this.entries, 'allocation.date', [
      this.sortService.sortDirection === SortDirection.newest ? 'desc' : 'asc',
    ]);

    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    this.timesheetCardService.data$
      .pipe(auditTime(60), takeUntilDestroyed(this.destroyRef))
      .subscribe((lines) => {
        this.updateView(lines);
      });

    this.sortService.sortDirection$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((direction) => {
        this.entries = _.orderBy(this.entries, 'allocation.date', [
          direction === SortDirection.newest ? 'desc' : 'asc',
        ]);

        this.cdr.markForCheck();
      });
  }
}
