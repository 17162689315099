import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { NavigationService } from 'src/app/core/navigation.service';
import {
  Reference,
  ReferenceEntry,
} from 'src/app/settings-app/reference/model/reference.model';

@Injectable()
export class ReferenceCardService {
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);

  private nameSubject = new BehaviorSubject<string>(null);
  public name$ = this.nameSubject.asObservable();

  private saveTabSubject = new Subject<void>();
  public saveTab$ = this.saveTabSubject.asObservable();

  public readonly: boolean = false;

  private collection = this.data.collection('References');
  private referenceEntriesCollection = this.data.collection('ReferenceEntries');

  constructor(
    @Inject('entityId') private referenceId: string,
    private data: DataService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
  ) {}

  /**
   * Updates Name.
   *
   * @param value name of the reference.
   */
  public updateName(value: string): void {
    this.nameSubject.next(value);
  }

  /** Saves tab. */
  public saveTab(): void {
    this.saveTabSubject.next();
  }

  /**
   * Gets references.
   *
   * @param entityId reference guid.
   * @param query query.
   *
   * @returns Returns references observable.
   */
  public getReferences(entityId: string, query?: any): Observable<Reference> {
    return this.collection.entity(entityId).get<Reference>(query);
  }

  /** Deletes entity. */
  public delete(): void {
    this.messageService
      .confirmLocal('shared2.messages.deleteConfirmation')
      .then(
        () => {
          this.data
            .collection('References')
            .entity(this.referenceId)
            .delete()
            .subscribe({
              next: () => {
                this.notificationService.successLocal(
                  'components.referenceService.messages.deleted',
                );
                this.navigationService.goToSelectedNavItem(true);
              },
              error: (error: Exception) => {
                this.messageService.error(error.message);
              },
            });
        },
        () => null,
      );
  }

  /**
   * Patches reference entry.
   *
   * @param id referenceId.
   * @param value Partial <ReferenceEntry>.
   *
   * @returns Observable of reference entries.
   */
  public patchReferenceEntry(
    id: string,
    value: Partial<ReferenceEntry>,
  ): Observable<ReferenceEntry> {
    return this.referenceEntriesCollection.entity(id).patch(value);
  }

  /**
   * Patches reference.
   *
   * @param id id.
   * @param value Partial <Reference>.
   *
   * @returns Observable of references.
   */
  public patchReference(
    id: string,
    value: Partial<Reference>,
  ): Observable<Reference> {
    return this.collection.entity(id).patch(value);
  }
}
