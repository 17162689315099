import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { BehaviorSubject } from 'rxjs';
import { ActCreationComponent } from 'src/app/acts-of-acceptance/creation/act-creation.component';
import { InvoiceCreationComponent } from 'src/app/billing/invoices/creation/invoice-creation.component';
import { ClientSettingsComponent } from 'src/app/clients/settings/client-settings.component';
import { AppService } from 'src/app/core/app.service';
import { ExpensesRequestCreationComponent } from 'src/app/expenses-requests/creation/expenses-request-creation.component';
import { IssueCreationComponent } from 'src/app/issues/creation/issue-creation.component';
import { ProgramSettingsComponent } from 'src/app/programs/settings/program-settings.component';
import { ProjectSettingsComponent } from 'src/app/projects/settings/project-settings.component';
import { ResourceRequestModalComponent } from 'src/app/resource-requests/shared/resource-request/modal/resource-request-modal.component';
import { Entity } from 'src/app/shared/models/entities/entity.model';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { TimeOffCreationComponent } from 'src/app/time-off-requests/creation/time-off-creation.component';

@Injectable()
export class CreateMenuService {
  public items$ = new BehaviorSubject<CreateMenuItem[]>([]);
  public isReloadState = true;
  public customNavigation: string;
  public routeMode: RouteMode = RouteMode.reset;

  private items: CreateMenuItem[] = [];

  constructor(
    app: AppService,
    translate: TranslateService,
    state: StateService,
    modalService: NgbModal,
  ) {
    // Issue.
    if (app.checkEntityPermission('Issue', PermissionType.Modify)) {
      this.items.push({
        title: translate.instant('shared2.props.issue'),
        name: 'issue',
        handle: () =>
          modalService.open(IssueCreationComponent).result.then(
            (entityId: string) => {
              state.go(
                'issues',
                {
                  entityId,
                  navigation: this.customNavigation ?? 'my.issues',
                  routeMode: this.routeMode,
                },
                { reload: this.isReloadState },
              );
            },
            () => null,
          ),
      });
    }

    // TimeOff Request.
    if (
      app.checkFeature(Feature.timeOff) &&
      app.checkEntityPermission('TimeOffRequest', PermissionType.Modify)
    ) {
      this.items.push({
        title: translate.instant('enums.component.timeOffRequest'),
        name: 'timeOffRequest',
        handle: () =>
          modalService
            .open(TimeOffCreationComponent, {
              size: 'lg',
            })
            .result.then(
              (request: Entity) => {
                state.go(
                  'timeOffRequest',
                  {
                    entityId: request.id,
                    navigation:
                      this.customNavigation ?? 'my.timeOffRequestsAll',
                    routeMode: this.routeMode,
                  },
                  { reload: this.isReloadState },
                );
              },
              () => null,
            ),
      });
    }

    // Expense Request.
    if (
      app.checkFeature(Feature.expenses) &&
      app.checkEntityPermission('ExpenseRequest', PermissionType.Modify)
    ) {
      this.items.push({
        title: translate.instant('enums.component.expenseRequest'),
        name: 'expenseRequest',
        handle: () =>
          modalService.open(ExpensesRequestCreationComponent).result.then(
            (entity: Entity) => {
              state.go(
                'expensesRequest',
                {
                  entityId: entity.id,
                  navigation: this.customNavigation ?? 'my.expensesRequestsAll',
                  routeMode: this.routeMode,
                },
                { reload: this.isReloadState },
              );
            },
            () => null,
          ),
      });
    }

    // Project.
    if (app.checkEntityPermission('Project', PermissionType.Modify)) {
      this.items.push({
        title: translate.instant('enums.component.project'),
        name: 'project',
        handle: () =>
          modalService
            .open(ProjectSettingsComponent, { size: 'lg' })
            .result.then(
              (entity: Entity) => {
                state.go(
                  'project',
                  {
                    entityId: entity.id,
                    navigation: this.customNavigation ?? 'projects.allProjects',
                    routeMode: this.routeMode,
                  },
                  { reload: this.isReloadState },
                );
              },
              () => null,
            ),
      });
    }

    // Client.
    if (app.checkEntityPermission('Organization', PermissionType.Modify)) {
      this.items.push({
        title: translate.instant('enums.component.client'),
        name: 'client',
        handle: () =>
          modalService
            .open(ClientSettingsComponent, { size: 'lg' })
            .result.then(
              (entity: Entity) => {
                state.go(
                  'client',
                  {
                    entityId: entity.id,
                    navigation: this.customNavigation ?? 'projects.allClients',
                    routeMode: this.routeMode,
                  },
                  { reload: this.isReloadState },
                );
              },
              () => null,
            ),
      });
    }

    // Program.
    if (
      app.checkFeature(Feature.programs) &&
      app.checkEntityPermission('Program', PermissionType.Modify)
    ) {
      this.items.push({
        title: translate.instant('enums.component.program'),
        name: 'program',
        handle: () =>
          modalService
            .open(ProgramSettingsComponent, { size: 'lg' })
            .result.then(
              (entity: Entity) => {
                state.go(
                  'program',
                  {
                    entityId: entity.id,
                    navigation: this.customNavigation ?? 'projects.allPrograms',
                    routeMode: this.routeMode,
                  },
                  { reload: this.isReloadState },
                );
              },
              () => null,
            ),
      });
    }

    // Resource request.
    if (
      app.checkFeature(Feature.booking) &&
      app.checkEntityPermission('ResourceRequest', PermissionType.Modify)
    ) {
      this.items.push({
        title: translate.instant('enums.component.resourceRequest'),
        name: 'resourceRequest',
        handle: () =>
          modalService
            .open(ResourceRequestModalComponent, { size: 'xl' })
            .result.then(
              (entity: Entity) => {
                state.go(
                  'resourceRequest',
                  {
                    entityId: entity.id,
                    navigation:
                      this.customNavigation ?? 'resources.allRequests',
                    routeMode: this.routeMode,
                  },
                  { reload: this.isReloadState },
                );
              },
              () => null,
            ),
      });
    }

    // Act of acceptance.
    if (
      app.checkFeature(Feature.finance) &&
      app.checkEntityPermission('ActOfAcceptance', PermissionType.Modify)
    ) {
      this.items.push({
        title: translate.instant('enums.component.actOfAcceptance'),
        name: 'actOfAcceptance',
        handle: () =>
          modalService.open(ActCreationComponent).result.then(
            (entity: Entity) => {
              state.go(
                'actOfAcceptance',
                {
                  entityId: entity.id,
                  navigation:
                    this.customNavigation ?? 'finance.actsOfAcceptance',
                  routeMode: this.routeMode,
                },
                { reload: this.isReloadState },
              );
            },
            () => null,
          ),
      });
    }

    // Invoice.
    if (
      app.checkFeature(Feature.billing) &&
      app.checkEntityPermission('Invoice', PermissionType.Modify)
    ) {
      this.items.push({
        title: translate.instant('enums.component.invoice'),
        name: 'invoice',
        handle: () =>
          modalService.open(InvoiceCreationComponent).result.then(
            (entityId: string) => {
              state.go(
                'invoice',
                {
                  entityId,
                  navigation: this.customNavigation ?? 'billing.invoicesAll',
                  routeMode: this.routeMode,
                },
                { reload: this.isReloadState },
              );
            },
            () => null,
          ),
      });
    }

    this.items$.next(this.items);
  }
}

export interface CreateMenuItem {
  title: string;
  name: string;
  handle: () => void;
}
