import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';

import {
  GetTransitionFormPropertyValue,
  TransitionFormDescription,
} from 'src/app/shared/models/entities/lifecycle/transition-form.model';
import { Exception } from 'src/app/shared/models/exception';
import { MenuAction } from 'src/app/shared/models/inner/menu-action';

import { LifecycleCardService } from 'src/app/settings-app/lifecycle/card/lifecycle-card.service';

@Component({
  selector: 'tmt-transition-form-modal',
  templateUrl: './transition-form-modal.component.html',
  styleUrls: ['./transition-form-modal.component.scss'],
})
export class TransitionFormModalComponent implements OnInit {
  @Input() stateId: string;
  @Input() action: MenuAction;
  @Input() entityId: string;
  @Input() collection: string;
  @Input() transitionId: string;

  public isLoading = false;
  public transitionForm: FormGroup = this.fb.group({});
  public transitionProperties: GetTransitionFormPropertyValue[];
  public requestComment = false;

  constructor(
    public lifecycleCardService: LifecycleCardService,
    private activeModal: NgbActiveModal,
    private dataService: DataService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
  ) {}

  public ngOnInit(): void {
    this.isLoading = true;

    this.dataService
      .collection(this.collection)
      .entity(this.entityId)
      .function('GetTransitionFormDescription')
      .query({ transitionId: this.transitionId })
      .subscribe({
        next: (transitionFormDescription: TransitionFormDescription) => {
          this.transitionProperties =
            transitionFormDescription.transitionFormPropertyValues;
          this.requestComment = transitionFormDescription.requestComment;

          if (this.requestComment) {
            this.transitionForm.addControl(
              'comment',
              this.fb.control(
                null,
                transitionFormDescription.commentIsRequired
                  ? Validators.required
                  : null,
              ),
            );
          }

          this.makeForm(this.transitionProperties);
          this.isLoading = false;
        },
        error: (error: Exception) => {
          if (error.code === Exception.BtEntityNotFoundException.code) {
            return;
          }

          this.notificationService.error(error.message);
        },
      });
  }

  /** Resolves modal with form data. */
  public ok(): void {
    this.transitionForm.markAllAsTouched();

    if (this.transitionForm.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );

      return;
    }

    const data = {
      stateId: this.stateId,
      transitionFormValue: {
        propertyValues: [],
        comment: this.transitionForm.value.comment,
      },
    };

    Object.keys(this.transitionForm.value).forEach((key) => {
      if (key !== 'comment') {
        data.transitionFormValue.propertyValues.push({
          name: key,
          value: this.transitionForm.value[key],
        });
      }
    });

    this.activeModal.close(data);
  }

  /** Closes modal without result. */
  public cancel(): void {
    this.activeModal.dismiss(null);
  }

  private makeForm(transitionForm: GetTransitionFormPropertyValue[]): void {
    transitionForm.forEach((property) => {
      this.transitionForm.addControl(
        property.name,
        this.fb.control(
          property.value,
          property.isRequired ? Validators.required : null,
        ),
      );
    });
  }
}
