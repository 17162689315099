import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ViewContainerRef,
  OnDestroy,
} from '@angular/core';
import { GridCellHostDirective } from '../../grid/grid-cell/grid-cell-host.directive';
import { UntypedFormControl } from '@angular/forms';
import { NumberBoxWrapperComponent } from '../../grid/grid-cell/wrappers/date-box-wrapper/number-box-wrapper.component';
import { SelectBoxWrapperComponent } from '../../grid/grid-cell/wrappers/date-box-wrapper/select-box-wrapper.component';
import { Wrapper } from '../../grid/grid-cell/wrapper.interface';
import { DateBoxWrapperComponent } from '../../grid/grid-cell/wrappers/date-box-wrapper/date-box-wrapper.component';
import { TextBoxWrapperComponent } from '../../grid/grid-cell/wrappers/date-box-wrapper/text-box-wrapper.component';
import { CustomFieldMode } from 'src/app/shared/components/features/custom-fields/custom-fields.component';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import {
  MetaEntityBaseProperty,
  MetaEntityPropertyType,
} from 'src/app/shared/models/entities/settings/metamodel.model';
import { DataService } from 'src/app/core/data.service';

@Component({
  selector: 'wp-custom-field',
  template: '<ng-template wp-grid-cell-host></ng-template>',
})
export class CustomFieldComponent implements OnInit, OnDestroy {
  @Input() control: UntypedFormControl;
  @Input() field: MetaEntityBaseProperty;
  @Input() mode: CustomFieldMode = 'default';
  @ViewChild(GridCellHostDirective, { static: true })
  host: GridCellHostDirective;

  private propagationMode = PropagationMode;

  private componentRef: any;
  private viewContainerRef: ViewContainerRef;

  constructor(private data: DataService) {}

  public ngOnInit(): void {
    this.viewContainerRef = this.host.hostContainer;

    switch (this.field.type) {
      case MetaEntityPropertyType.dateOnly:
        this.renderDateControl();
        break;
      case MetaEntityPropertyType.decimal:
        this.renderNumberControl('decimal');
        break;
      case MetaEntityPropertyType.integer:
        this.renderNumberControl('integer');
        break;
      case MetaEntityPropertyType.reference:
        this.renderSelectControl();
        break;
      case MetaEntityPropertyType.string:
        this.renderStringControl();
        break;
    }

    if (this.field.isOnlyForApi && this.mode === 'default') {
      this.componentRef.instance.readonly = true;
    }
  }

  public ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
    if (this.viewContainerRef) {
      this.viewContainerRef.remove();
      this.viewContainerRef = null;
    }
  }

  private renderNumberControl(type: string) {
    this.componentRef = this.viewContainerRef.createComponent(
      NumberBoxWrapperComponent,
    );
    const instance = <NumberBoxWrapperComponent>this.componentRef.instance;
    instance.control = this.control;
    instance.type = type;
    if (this.field.clrType === 'ProjectTask') {
      instance.propagationMode = this.propagationMode.onExitFromEditing;
    }
  }

  private renderSelectControl() {
    this.componentRef = this.viewContainerRef.createComponent(
      SelectBoxWrapperComponent,
    );
    const instance = <SelectBoxWrapperComponent>this.componentRef.instance;
    instance.control = this.control;
    this.data
      .collection('CustomFields')
      .entity(this.field.customFieldId)
      .get({
        expand: {
          reference: { expand: 'entries' },
        },
      })
      .subscribe((x: any) => {
        instance.values = x.reference.entries;
      });
  }

  private renderStringControl() {
    this.componentRef = this.viewContainerRef.createComponent(
      TextBoxWrapperComponent,
    );
    const instance = <TextBoxWrapperComponent>this.componentRef.instance;
    instance.control = this.control;
    instance.placeholder = this.field.viewConfiguration.placeholder;
    if (this.field.clrType === 'ProjectTask') {
      instance.propagationMode = this.propagationMode.onExitFromEditing;
    }
  }

  private renderDateControl() {
    this.componentRef = this.viewContainerRef.createComponent(
      DateBoxWrapperComponent,
    );
    const instance = <Wrapper>this.componentRef.instance;
    instance.control = this.control;
  }
}
