<wp-loading-indicator [state]="issueCardService.state$ | async" />
<tmt-not-found [state]="issueCardService.state$ | async" [urlMustContain]="entityId" />

@if ((issueCardService.state$ | async) === 'Ready') {
  <tmt-form-header
    [name]="issueCardService.formValue?.name"
    [isEditable]="(issueCardService.issue$ | async)?.editAllowed"
    [saveFn]="saveName"
  />
  <div class="subheader mt-3">
    <div class="subheader__props">
      <div class="subheader__prop"><wp-state-indicator /></div>
      <div class="subheader__prop">
        <label class="subheader__label"> {{ 'shared2.props.code' | translate }}: </label>
        <div class="subheader__value">{{ issueCardService.issue?.code }}</div>
      </div>
      <div class="subheader__prop">
        <label class="subheader__label"> {{ 'shared2.props.created.value' | translate }}: </label>
        <div class="subheader__value">{{ issueCardService.issue?.created | date: 'short' }}</div>
      </div>
      <div class="subheader__prop">
        <label class="subheader__label"> {{ 'shared2.props.createdBy.value' | translate }}: </label>
        <div class="subheader__value" #createdBy>
          <button
            type="button"
            (click)="openCreatedByUserInfo(createdBy)"
            class="btn btn-link btn-link-content"
          >
            {{ issueCardService.issue.createdBy?.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <form class="content form" [formGroup]="issueCardService.issueForm">
    <div class="description">
      <div class="form-group mb-0">
        <wp-multiline-text-box
          formControlName="description"
          placeholder="{{ 'shared2.props.description' | translate }}"
          rows="10"
        />
      </div>
    </div>
    <div class="parameters" ngbAccordion>
      <div ngbAccordionItem [collapsed]="false">
        <div ngbAccordionHeader>
          <button ngbAccordionButton class="text-uppercase">
            {{ 'components.issueCardComponent.groups.parameters' | translate }}
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div class="form-group">
                <label>{{ 'shared2.props.type' | translate }}</label>
                <div class="form-control-plaintext">
                  {{ (issueCardService.issue$ | async).type.name }}
                </div>
              </div>
              @if ((issueCardService.issue$ | async)?.resolution) {
                <div class="form-group">
                  <label>{{ 'components.issueCardComponent.props.resolution' | translate }}</label>
                  <div class="form-control-plaintext">
                    {{ (issueCardService.issue$ | async).resolution.name }}
                  </div>
                </div>
              }
              @if ((issueCardService.issue$ | async)?.resolution) {
                <div class="form-group">
                  <label>{{
                    'components.issueCardComponent.props.resolutionComment' | translate
                  }}</label>
                  <div class="form-control-plaintext">
                    {{ (issueCardService.issue$ | async).resolutionComment }}
                  </div>
                </div>
              }
              <div class="form-group">
                <label>{{ 'components.issueCardComponent.props.priority' | translate }}</label>
                <wp-select-box
                  formControlName="priority"
                  collection="IssuePriorities"
                  placeholder="{{ 'components.issueCardComponent.props.priority' | translate }}"
                  [allowNull]="false"
                />
              </div>
              <div class="form-group">
                <label>{{ 'components.issueCardComponent.props.dueDate' | translate }}</label>
                <wp-date-box formControlName="dueDate" />
              </div>
              <div class="form-group">
                <label>{{ 'components.issueCardComponent.props.initiator' | translate }}</label>
                <wp-user-box
                  placeholder="{{ 'components.issueCardComponent.props.initiator' | translate }}"
                  formControlName="initiator"
                  [allowNull]="false"
                />
              </div>
              <div class="form-group">
                <label>{{ 'shared2.props.assigned' | translate }}</label>
                <wp-user-box
                  placeholder="{{ 'shared2.props.assigned' | translate }}"
                  formControlName="assigned"
                />
              </div>
              <div class="form-group">
                <label>{{ 'components.issueCardComponent.props.followers' | translate }}</label>
                <tmt-multiselect-box
                  formControlName="followers"
                  collection="Users"
                  placeholder="{{ 'components.issueCardComponent.props.followers' | translate }}"
                />
              </div>
              <div class="form-group">
                <label>{{ 'shared2.props.project' | translate }}</label>
                <wp-select-box
                  formControlName="project"
                  collection="Projects"
                  placeholder="{{ 'shared2.props.project' | translate }}"
                />
              </div>
              <div class="form-group mb-4">
                <label>{{ 'components.issueCardComponent.props.projectTask' | translate }}</label>
                <tmt-task-box
                  formControlName="projectTask"
                  [projectId]="issueCardService.issueForm.value.project?.id"
                  placeholder="{{ 'components.issueCardComponent.props.projectTask' | translate }}"
                />
              </div>
              <wp-custom-fields
                [formGroup]="issueCardService.issueForm"
                entityType="Issue"
                columnClasses="col-12"
              />
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="comments">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-underline mt-4">
        <li [ngbNavItem]="'activity'">
          <a ngbNavLink>
            {{ 'shared.comments.tabs.activity' | translate }}
          </a>
          <div *ngbNavContent class="wide-tab-container mt-3">
            <wp-comments-tab
              [entityId]="entityId"
              [collection]="commentedEntityCollectionType.Issues"
            />
          </div>
        </li>
        <li [ngbNavItem]="'attachments'">
          <a ngbNavLink>
            {{ 'shared.comments.tabs.attachments' | translate }}
          </a>
          <div *ngbNavContent class="wide-tab-container mt-3">
            <wp-attachments-tab
              [entityId]="entityId"
              [collection]="commentedEntityCollectionType.Issues"
            />
          </div>
        </li>
        <li [ngbNavItem]="'linkedTasks'">
          <a ngbNavLink>
            {{ 'base.workflow.tab' | translate }}
          </a>
          <div *ngbNavContent class="scroll-tab-container mt-3">
            <tmt-entity-workflow-tasks [entityId]="entityId" />
          </div>
        </li>
        <li [ngbNavItem]="'stateHistory'">
          <a ngbNavLink>
            {{ 'base.stateHistory.tab' | translate }}
          </a>
          <div *ngbNavContent class="scroll-tab-container mt-3">
            <tmt-state-history [entityId]="entityId" />
          </div>
        </li>
        <wp-sort class="ms-auto" />
      </ul>

      <div [ngbNavOutlet]="nav"></div>
    </div>
  </form>
}
