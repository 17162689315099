import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { MetamodelDescriptionComponent } from 'src/app/settings-app/metamodel/metamodel-description/metamodel-description.component';
import { MetamodelDescriptionFilterComponent } from 'src/app/settings-app/metamodel/metamodel-description/filter/metamodel-description-filter.component';

@NgModule({
  declarations: [MetamodelDescriptionFilterComponent],
  imports: [
    CommonModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.metamodel',
          url: '/metamodel',
          component: MetamodelDescriptionComponent,
          params: {
            navigation: 'settings.metamodel',
          },
        },
      ],
    }),
  ],
})
export class MetamodelModule {}
