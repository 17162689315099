<a [href]="cardHref" draggable="false" class="board__task" (click)="clickHandler($event)">
  @for (property of boardService.config.cardStructure; track $index) {
    <div
      class="board__task-property board__task-property--{{
        property.clrType
      }} board__task-property--{{ property.name }}"
      [class.board__task-property--first]="!$index"
    >
      @if (
        card.entity[property.name] ||
        card.entity[property.name] === false ||
        card.entity[property.name] === 0
      ) {
        @switch (property.clrType) {
          @case ('string') {
            @if (property.name === 'name') {
              <ng-container [ngTemplateOutlet]="link" />
            } @else {
              <ng-container
                [ngTemplateOutlet]="string"
                [ngTemplateOutletContext]="{ $implicit: property }"
              />
            }
          }

          @case ('int32') {
            <div
              class="trim"
              [title]="property.displayName + ': ' + card.entity[property.name] ?? 0"
            >
              {{ card.entity[property.name] | number: '1.0-0' }}
            </div>
          }

          @case ('decimal') {
            <div
              class="trim"
              [title]="property.displayName + ': ' + card.entity[property.name] ?? 0"
            >
              {{ card.entity[property.name] | number: '1.2-2' }}
            </div>
          }

          @case ('boolean') {
            <div class="boolean">
              <span class="trim" [title]="property.displayName"> {{ property.displayName }}: </span>

              @if (card.entity[property.name]) {
                <i class="bi bi-check-circle"></i>
              } @else {
                <i class="bi bi-circle"></i>
              }
            </div>
          }

          @case ('dateTime') {
            <div class="trim" [title]="property.displayName + ': ' + card.entity[property.name]">
              {{ card.entity[property.name] | date: 'shortDate' }}
            </div>
          }

          @case ('user') {
            <ng-container
              [ngTemplateOutlet]="user"
              [ngTemplateOutletContext]="{ $implicit: card.entity[property.name] }"
            />
          }

          @default {
            <ng-container
              [ngTemplateOutlet]="guess"
              [ngTemplateOutletContext]="{ $implicit: property }"
            />
          }
        }
      } @else {
        <ng-container
          [ngTemplateOutlet]="empty"
          [ngTemplateOutletContext]="{ $implicit: property }"
        />
      }

      @if (!$index) {
        <ng-container [ngTemplateOutlet]="menu" />
      }
    </div>
  }
</a>

<ng-template #menu>
  @if (card.actions?.length) {
    <button
      class="btn btn-sm without-caret ms-auto board__task-menu"
      (click)="boardService.openMenu($event, card.actions, { item: card, oldIndex: index })"
      [ngbTooltip]="'components.boardMiniCardComponent.props.menu' | translate"
      placement="top"
      container="body"
    >
      <i class="bi bi-three-dots"></i>
    </button>
  }
</ng-template>

<ng-template #link>
  <div class="text-break">
    @if (boardService.config.offCanvasComponent) {
      <a draggable="false" [title]="card.entity.name" class="off-canvas-link">
        {{ card.entity.name }}
      </a>
    } @else {
      <a
        [uiSref]="stateForEntity.state"
        [uiParams]="stateForEntity.params"
        draggable="false"
        [title]="card.entity.name"
      >
        {{ card.entity.name }}
      </a>
    }
  </div>
</ng-template>

<ng-template #user let-user>
  <div class="user__info">
    <img [src]="user.id | avatar: 60" class="avatar-img" draggable="false" />
    <span class="trim" title="{{ user.name }}">{{ user.name }}</span>
  </div>
</ng-template>

<ng-template #string let-property>
  <div class="trim" [title]="property.displayName + ': ' + card.entity[property.name]">
    {{ card.entity[property.name] }}
  </div>
</ng-template>

<ng-template #empty let-property>
  <div [title]="property.displayName">—</div>
</ng-template>

<ng-template #guess let-property>
  @switch (guessTemplateType(property)) {
    @case ('lookupValue') {
      <div class="trim" [title]="property.displayName + ': ' + card.entity[property.name].name">
        {{ card.entity[property.name].name }}
      </div>
    }

    @case ('string') {
      <ng-container
        [ngTemplateOutlet]="string"
        [ngTemplateOutletContext]="{ $implicit: property }"
      />
    }

    @case ('empty') {
      <ng-container
        [ngTemplateOutlet]="empty"
        [ngTemplateOutletContext]="{ $implicit: property }"
      />
    }

    @default {
      <span>Template for {{ property.type }} not implemented!</span>
    }
  }
</ng-template>
