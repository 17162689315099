import { Injectable } from '@angular/core';
import { DateTime, Interval } from 'luxon';
import { Slot } from 'src/app/shared-features/schedule-navigation/models/slot.model';
import {
  ProjectTaskDependency,
  ProjectTaskDependencyType,
} from 'src/app/shared/models/entities/projects/project-task.model';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { ProjectTaskTimelineService } from './project-task-timeline.service';
import { Coordinates, DragContext } from 'src/app/shared/services/drag-drop';
import { ProjectTasksDataService } from 'src/app/projects/card/project-tasks/core/project-tasks-data.service';
import { TimelinePositionService } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/core/timeline-position.service';
import {
  GraphicQuadrant,
  DependencyCoordinates,
  ArrowDirection,
} from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/models/dependency-coordinates.interface';
import { ProjectTaskViewParams } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/models/timeline-entry.interface';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

/** Timeline rendering service. */
@Injectable()
export class TimelineRenderingService {
  /** Standard table row height. */
  public rowHeight = 35;

  /** Timeline canvas for creating dependency. */
  public dynamicCanvas: HTMLCanvasElement;

  /** Timeline canvas for painting dependencies. */
  public dependenciesCanvas: HTMLCanvasElement;

  public hiddenDependenciesLineWidth = 40;

  private lineColor = '#c8c8c8';
  private highlightColor = 'rgba(67, 87, 173, 0.8)';

  public get slotWidth(): number {
    return this.timelinePositionService.slotWidth;
  }

  public get dragContext(): DragContext {
    const canvasPosition = this.dependenciesCanvas.getBoundingClientRect();
    const dragContext: DragContext = {
      leftOffset: canvasPosition.left,
      topOffset: canvasPosition.top,
    };
    return dragContext;
  }

  /** Planning scale. */
  public get planningScale(): PlanningScale {
    return this.projectTaskTimelineService.settings.planningScale;
  }

  /** Current board slots. */
  public get slots(): Slot[] {
    return this.projectTaskTimelineService.slots;
  }

  /** Current board work interval. */
  public get interval(): Interval {
    return this.projectTaskTimelineService.interval;
  }

  constructor(
    public projectTaskTimelineService: ProjectTaskTimelineService,
    public taskDataService: ProjectTasksDataService,
    private timelinePositionService: TimelinePositionService,
    public gridService: GridService,
  ) {}

  /**
   * Returns current table width.
   *
   * @returns table width in px.
   */
  public getDataTableWidth(): number | null {
    if (!this.slots) {
      return null;
    }
    return this.slotWidth * this.slots.length + 1;
  }

  /**
   * Returns current table height.
   *
   * @returns table height in px.
   */
  public getDataTableHeight(): number {
    return this.taskDataService.formArray?.length * this.rowHeight;
  }

  /** Clears create dependency canvas.  */
  public clearCreateDynamicCanvas(): void {
    const ctx = this.dynamicCanvas.getContext('2d');
    ctx.clearRect(0, 0, this.dynamicCanvas.width, this.dynamicCanvas.height);
  }

  /** Highlights the dependencies of selected task. */
  public highlightTaskDependencies(): void {
    const canvas = this.dynamicCanvas as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    /** Set line styles. */
    ctx.strokeStyle = this.highlightColor;
    ctx.lineWidth = 2;
    ctx.fillStyle = this.highlightColor;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const selectedTask = this.gridService.selectedGroupValue;
    if (selectedTask) {
      /** Highlight task own dependencies. */
      selectedTask.dependencies.forEach((dependency) => {
        this.drawDependency(selectedTask.id, dependency, ctx);
      });

      /** Highlight task predecessor dependencies. */
      const predecessorDependencies: {
        id: string;
        dependency: ProjectTaskDependency;
      }[] = [];
      this.taskDataService.tasks.forEach((task) => {
        task.dependencies.forEach((dependency) => {
          if (dependency.predecessorId === selectedTask.id) {
            predecessorDependencies.push({ id: task.id, dependency });
          }
        });
      });
      if (predecessorDependencies) {
        predecessorDependencies.forEach((predecessorDependency) => {
          this.drawDependency(
            predecessorDependency.id,
            predecessorDependency.dependency,
            ctx,
          );
        });
      }
    }
  }

  /** Draws the dependencies of showed tasks. */
  public drawDependencies(): void {
    const canvas = this.dependenciesCanvas as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    /** Set line styles. */
    ctx.strokeStyle = this.lineColor;
    ctx.lineWidth = 2;
    ctx.fillStyle = this.lineColor;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    this.taskDataService.tasks?.forEach((task) => {
      task.dependencies?.forEach((dependency) => {
        this.drawDependency(task.id, dependency, ctx);
      });
    });
  }

  /**
   * Draws a dependency line between two tasks on the canvas.
   * @param dependentTaskId - The ID of the dependent task.
   * @param dependency - The dependency object containing information about the relationship.
   * @param canvasContext - The 2D rendering context of the canvas.
   */
  private drawDependency(
    dependentTaskId: string,
    dependency: ProjectTaskDependency,
    canvasContext: CanvasRenderingContext2D,
  ): void {
    // Get view parameters for both tasks
    const dependentTaskViewParams = this.getTaskViewParams(dependentTaskId);
    const predecessorTaskViewParams = this.getTaskViewParams(
      dependency.predecessorId,
    );
    if (!predecessorTaskViewParams && !dependentTaskViewParams) return;

    /**
     * Helper function to get the coordinates of a task's start or end point.
     * @param params - The view parameters of the task.
     * @param isStart - Whether to get the start (true) or end (false) point of the task.
     * @returns The coordinates of the point, or null if params are not available.
     */
    const getTaskPointCoordinates = (
      params: ProjectTaskViewParams | null,
      isStart: boolean,
    ): { x: number; y: number } | null => {
      if (!params) return null;
      const x = isStart
        ? params.taskLeftOffset
        : params.taskLeftOffset + params.taskWidth;
      const y = this.rowHeight * params.taskIndex + this.rowHeight / 2;
      return { x, y };
    };

    // Determine start and end points based on dependency type.
    const start = getTaskPointCoordinates(
      predecessorTaskViewParams,
      dependency.type === ProjectTaskDependencyType.startToStart ||
        dependency.type === ProjectTaskDependencyType.startToFinish,
    );
    const end = getTaskPointCoordinates(
      dependentTaskViewParams,
      dependency.type === ProjectTaskDependencyType.startToStart ||
        dependency.type === ProjectTaskDependencyType.finishToStart,
    );

    if (!start && !end) return;

    // If both start and end points are available, draw the dependency line.
    if (start && end) {
      this.drawDependencyLine(
        { startX: start.x, startY: start.y, endX: end.x, endY: end.y },
        dependency.type,
        canvasContext,
      );
      return;
    }

    // Handle cases where one of the tasks is not visible.
    let startX, endX, startY, endY;
    const point = start || end;
    const isStart = !!start;

    if (point) {
      startY = endY = point.y;
      const offset = this.hiddenDependenciesLineWidth;

      // Determine start and end X coordinates based on dependency type.
      switch (dependency.type) {
        case ProjectTaskDependencyType.finishToStart:
          [startX, endX] = isStart
            ? [point.x, point.x + offset]
            : [point.x - offset, point.x];
          break;
        case ProjectTaskDependencyType.startToStart:
          [startX, endX] = isStart
            ? [point.x, point.x - offset]
            : [point.x - offset, point.x];
          break;
        case ProjectTaskDependencyType.startToFinish:
          [startX, endX] = isStart
            ? [point.x, point.x - offset]
            : [point.x + offset, point.x];
          break;
        case ProjectTaskDependencyType.finishToFinish:
          [startX, endX] = isStart
            ? [point.x, point.x + offset]
            : [point.x + offset, point.x];
          break;
      }
    }

    // Draw a straight line for the visible part of the dependency.
    this.drawStraightLine(
      {
        startX,
        startY,
        endX,
        endY,
      },
      canvasContext,
    );
  }

  /**
   * Returns task view params.
   *
   * @param taskId target task id.
   * @returns task timeline view params.
   */
  private getTaskViewParams(taskId: string): ProjectTaskViewParams {
    const taskIndex = this.taskDataService.formArray.value.findIndex(
      (task) => task.id === taskId,
    );
    if (taskIndex < 0) {
      return null;
    }

    const taskData = this.taskDataService.tasks.find(
      (task) => task.id === taskId,
    );

    /** Params for task width. */
    const startDate = DateTime.fromISO(taskData.startDate);
    const endDate = DateTime.fromISO(taskData.endDate);

    const isLeadTask = this.taskDataService.checkIsLeadTask(taskId);
    const isCommonMilestoneTask = taskData.isMilestone && !isLeadTask;
    const isLeadMilestoneTask = taskData.isMilestone && isLeadTask;

    let taskWidth = this.timelinePositionService.getWidth(
      startDate,
      endDate,
      taskData.isMilestone,
      isLeadTask,
    );

    const positionDate = isCommonMilestoneTask ? endDate : startDate;
    let taskLeftOffset = this.timelinePositionService.getPosition(positionDate);

    // Align common milestone task with right border of last day.
    if (isCommonMilestoneTask) {
      taskLeftOffset +=
        this.timelinePositionService.getDayWidth(positionDate) -
        this.timelinePositionService.halfOfMilestoneSize;
    }

    // Align milestone summary task with small native with milestone view width.
    if (
      isLeadMilestoneTask &&
      taskWidth - this.timelinePositionService.halfOfMilestoneSize <
        this.timelinePositionService.halfOfMilestoneSize
    ) {
      const baseTaskWidth = this.timelinePositionService.getWidth(
        startDate,
        endDate,
      );
      const taskParamsCorrectionValue =
        this.timelinePositionService.halfOfMilestoneSize - baseTaskWidth;
      taskLeftOffset -= taskParamsCorrectionValue;
      taskWidth += taskParamsCorrectionValue;
    }

    return { taskIndex, taskWidth, taskLeftOffset, isLeadTask };
  }

  /**
   * Finds quadrant of end point in relation to start point.
   *
   * @param deltaX point delta x position.
   * @param deltaY point delta y position.
   * @returns graphic quadrant number.
   */
  private findQuadrant(deltaX: number, deltaY: number): GraphicQuadrant {
    if (deltaX >= 0 && deltaY >= 0) {
      return GraphicQuadrant.second;
    }
    if (deltaX < 0 && deltaY >= 0) {
      return GraphicQuadrant.first;
    }
    if (deltaX < 0 && deltaY < 0) {
      return GraphicQuadrant.fourth;
    }
    if (deltaX >= 0 && deltaY < 0) {
      return GraphicQuadrant.third;
    }
  }

  /**
   * Draws straight dependency line on the canvas element.
   *
   * @param coordinates line points coordinates.
   * @param ctx canvas context.
   */
  private drawStraightLine(
    { startX, startY, endX, endY }: DependencyCoordinates,
    ctx: CanvasRenderingContext2D,
  ): void {
    ctx.beginPath();
    ctx.moveTo(startX, startY);
    ctx.lineTo(endX, endY);
    ctx.stroke();

    if (startY === endY) {
      const arrowDirection =
        startX < endX ? ArrowDirection.right : ArrowDirection.left;
      this.addArrow(arrowDirection, { x: endX, y: endY }, ctx);
    }
  }

  /**
   * Adds arrow to line.
   *
   * @param direction arrow direction.
   * @param coordinates point coordinates.
   * @param ctx canvas context for painting.
   */
  private addArrow(
    direction: ArrowDirection,
    { x, y }: Coordinates,
    ctx: CanvasRenderingContext2D,
  ): void {
    const size = this.rowHeight / 6;
    const offset = size * Math.tan(Math.PI / 4);
    const sign = direction === ArrowDirection.right ? -1 : 1;

    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x + sign * size, y - offset);
    ctx.lineTo(x + sign * size, y + offset);
    ctx.fill();
    ctx.closePath();
  }

  /**
   * Draws a dependency line on the canvas based on the given coordinates, dependency type, and context.
   * @param coordinates The start and end coordinates of the dependency line.
   * @param dependencyType The type of dependency (e.g., finish-to-start, start-to-start).
   * @param ctx The canvas rendering context.
   */
  private drawDependencyLine(
    coordinates: DependencyCoordinates,
    dependencyType: ProjectTaskDependencyType,
    ctx: CanvasRenderingContext2D,
  ): void {
    const deltaX = coordinates.startX - coordinates.endX;
    const deltaY = coordinates.startY - coordinates.endY;
    const arcRadius = this.rowHeight / 4;

    const quadrant = this.findQuadrant(deltaX, deltaY);

    ctx.beginPath();
    ctx.moveTo(coordinates.startX, coordinates.startY);
    const drawQuadrantDependencyLine = {
      [GraphicQuadrant.first]: this.drawFirstQuadrantDependencyLine,
      [GraphicQuadrant.second]: this.drawSecondQuadrantDependencyLine,
      [GraphicQuadrant.third]: this.drawThirdQuadrantDependencyLine,
      [GraphicQuadrant.fourth]: this.drawFourthQuadrantDependencyLine,
    };
    drawQuadrantDependencyLine[quadrant].call(
      this,
      coordinates,
      dependencyType,
      ctx,
      arcRadius,
      deltaX,
    );
    ctx.lineTo(coordinates.endX, coordinates.endY);
    ctx.stroke();
    ctx.closePath();

    const arrowDirection =
      dependencyType === ProjectTaskDependencyType.finishToFinish ||
      dependencyType === ProjectTaskDependencyType.startToFinish
        ? ArrowDirection.left
        : ArrowDirection.right;
    this.addArrow(
      arrowDirection,
      { x: coordinates.endX, y: coordinates.endY },
      ctx,
    );
  }

  /**
   * Draws a dependency line in the first quadrant.
   * @param coordinates The start and end coordinates of the dependency line.
   * @param dependencyType The type of dependency.
   * @param ctx The canvas rendering context.
   * @param arcRadius The radius of the arc used in drawing curves.
   * @param deltaX The difference between start and end X coordinates.
   */
  private drawFirstQuadrantDependencyLine(
    coordinates: DependencyCoordinates,
    dependencyType: ProjectTaskDependencyType,
    ctx: CanvasRenderingContext2D,
    arcRadius: number,
    deltaX: number,
  ) {
    switch (dependencyType) {
      case ProjectTaskDependencyType.finishToStart: {
        if (Math.abs(deltaX) <= 2 * arcRadius) {
          ctx.arc(
            coordinates.startX,
            coordinates.startY - arcRadius,
            arcRadius,
            Math.PI * 0.5,
            -Math.PI * 0.5,
            true,
          );
          ctx.arc(
            coordinates.startX,
            coordinates.startY - 3 * arcRadius,
            arcRadius,
            Math.PI * 0.5,
            Math.PI,
          );
          ctx.arc(
            coordinates.startX,
            coordinates.endY + arcRadius,
            arcRadius,
            -Math.PI,
            -Math.PI * 0.5,
          );
        } else {
          ctx.arc(
            coordinates.startX,
            coordinates.startY - arcRadius,
            arcRadius,
            Math.PI * 0.5,
            0,
            true,
          );
          ctx.arc(
            coordinates.startX + 2 * arcRadius,
            coordinates.endY + arcRadius,
            arcRadius,
            -Math.PI,
            -Math.PI * 0.5,
          );
        }
        break;
      }
      case ProjectTaskDependencyType.startToStart: {
        ctx.arc(
          coordinates.startX,
          coordinates.startY - arcRadius,
          arcRadius,
          Math.PI * 0.5,
          -Math.PI,
        );
        ctx.arc(
          coordinates.startX,
          coordinates.endY + arcRadius,
          arcRadius,
          -Math.PI,
          -Math.PI * 0.5,
        );
        break;
      }
      case ProjectTaskDependencyType.finishToFinish: {
        ctx.arc(
          coordinates.endX + arcRadius,
          coordinates.startY - arcRadius,
          arcRadius,
          Math.PI * 0.5,
          0,
          true,
        );
        ctx.arc(
          coordinates.endX + arcRadius,
          coordinates.endY + arcRadius,
          arcRadius,
          0,
          -Math.PI * 0.5,
          true,
        );
        break;
      }
      case ProjectTaskDependencyType.startToFinish: {
        ctx.arc(
          coordinates.startX,
          coordinates.startY - arcRadius,
          arcRadius,
          Math.PI * 0.5,
          -Math.PI * 0.5,
        );
        ctx.arc(
          coordinates.endX + arcRadius,
          coordinates.startY - 3 * arcRadius,
          arcRadius,
          Math.PI * 0.5,
          0,
          true,
        );
        ctx.arc(
          coordinates.endX + arcRadius,
          coordinates.endY + arcRadius,
          arcRadius,
          0,
          -Math.PI * 0.5,
          true,
        );
        break;
      }
    }
  }

  /**
   * Draws a dependency line in the second quadrant.
   * @param coordinates The start and end coordinates of the dependency line.
   * @param dependencyType The type of dependency.
   * @param ctx The canvas rendering context.
   * @param arcRadius The radius of the arc used in drawing curves.
   * @param deltaX The difference between start and end X coordinates.
   */
  private drawSecondQuadrantDependencyLine(
    coordinates: DependencyCoordinates,
    dependencyType: ProjectTaskDependencyType,
    ctx: CanvasRenderingContext2D,
    arcRadius: number,
    deltaX: number,
  ) {
    switch (dependencyType) {
      case ProjectTaskDependencyType.finishToStart: {
        ctx.arc(
          coordinates.startX,
          coordinates.startY - arcRadius,
          arcRadius,
          Math.PI * 0.5,
          -Math.PI * 0.5,
          true,
        );
        ctx.arc(
          coordinates.endX - arcRadius,
          coordinates.startY - 3 * arcRadius,
          arcRadius,
          Math.PI * 0.5,
          Math.PI,
        );
        ctx.arc(
          coordinates.endX - arcRadius,
          coordinates.endY + arcRadius,
          arcRadius,
          Math.PI,
          -Math.PI * 0.5,
        );
        break;
      }
      case ProjectTaskDependencyType.startToStart: {
        ctx.arc(
          coordinates.endX - arcRadius,
          coordinates.startY - arcRadius,
          arcRadius,
          Math.PI * 0.5,
          -Math.PI,
        );
        ctx.arc(
          coordinates.endX - arcRadius,
          coordinates.endY + arcRadius,
          arcRadius,
          -Math.PI,
          -Math.PI * 0.5,
        );
        break;
      }
      case ProjectTaskDependencyType.finishToFinish: {
        ctx.arc(
          coordinates.startX + arcRadius,
          coordinates.startY - arcRadius,
          arcRadius,
          Math.PI * 0.5,
          0,
          true,
        );
        ctx.arc(
          coordinates.startX + arcRadius,
          coordinates.endY + arcRadius,
          arcRadius,
          0,
          -Math.PI * 0.5,
          true,
        );
        break;
      }
      case ProjectTaskDependencyType.startToFinish: {
        if (Math.abs(deltaX) <= 2 * arcRadius) {
          ctx.arc(
            coordinates.startX,
            coordinates.startY - arcRadius,
            arcRadius,
            Math.PI * 0.5,
            -Math.PI * 0.5,
          );
          ctx.arc(
            coordinates.endX + arcRadius,
            coordinates.startY - 3 * arcRadius,
            arcRadius,
            Math.PI * 0.5,
            0,
            true,
          );
          ctx.arc(
            coordinates.endX + arcRadius,
            coordinates.endY + arcRadius,
            arcRadius,
            0,
            -Math.PI * 0.5,
            true,
          );
        } else {
          ctx.arc(
            coordinates.startX,
            coordinates.startY - arcRadius,
            arcRadius,
            Math.PI * 0.5,
            Math.PI,
          );
          ctx.arc(
            coordinates.startX - 2 * arcRadius,
            coordinates.endY + arcRadius,
            arcRadius,
            0,
            -Math.PI * 0.5,
            true,
          );
        }
        break;
      }
    }
  }

  /**
   * Draws a dependency line in the third quadrant.
   * @param coordinates The start and end coordinates of the dependency line.
   * @param dependencyType The type of dependency.
   * @param ctx The canvas rendering context.
   * @param arcRadius The radius of the arc used in drawing curves.
   * @param deltaX The difference between start and end X coordinates.
   */
  private drawThirdQuadrantDependencyLine(
    coordinates: DependencyCoordinates,
    dependencyType: ProjectTaskDependencyType,
    ctx: CanvasRenderingContext2D,
    arcRadius: number,
    deltaX: number,
  ) {
    switch (dependencyType) {
      case ProjectTaskDependencyType.finishToStart: {
        ctx.arc(
          coordinates.startX,
          coordinates.startY + arcRadius,
          arcRadius,
          -Math.PI * 0.5,
          Math.PI * 0.5,
        );
        ctx.arc(
          coordinates.endX - arcRadius,
          coordinates.startY + 3 * arcRadius,
          arcRadius,
          -Math.PI * 0.5,
          -Math.PI,
          true,
        );
        ctx.arc(
          coordinates.endX - arcRadius,
          coordinates.endY - arcRadius,
          arcRadius,
          -Math.PI,
          Math.PI * 0.5,
          true,
        );
        break;
      }
      case ProjectTaskDependencyType.startToStart: {
        ctx.arc(
          coordinates.endX - arcRadius,
          coordinates.startY + arcRadius,
          arcRadius,
          -Math.PI * 0.5,
          -Math.PI,
          true,
        );
        ctx.arc(
          coordinates.endX - arcRadius,
          coordinates.endY - arcRadius,
          arcRadius,
          -Math.PI,
          Math.PI * 0.5,
          true,
        );
        break;
      }
      case ProjectTaskDependencyType.finishToFinish: {
        ctx.arc(
          coordinates.startX + arcRadius,
          coordinates.startY + arcRadius,
          arcRadius,
          -Math.PI * 0.5,
          0,
        );
        ctx.arc(
          coordinates.startX + arcRadius,
          coordinates.endY - arcRadius,
          arcRadius,
          0,
          Math.PI * 0.5,
        );
        break;
      }
      case ProjectTaskDependencyType.startToFinish: {
        if (Math.abs(deltaX) <= 2 * arcRadius) {
          ctx.arc(
            coordinates.startX,
            coordinates.startY + arcRadius,
            arcRadius,
            -Math.PI * 0.5,
            Math.PI * 0.5,
            true,
          );
          ctx.arc(
            coordinates.startX + arcRadius,
            coordinates.startY + 3 * arcRadius,
            arcRadius,
            -Math.PI * 0.5,
            0,
          );
          ctx.arc(
            coordinates.endX + arcRadius,
            coordinates.endY - arcRadius,
            arcRadius,
            0,
            Math.PI * 0.5,
          );
        } else {
          ctx.arc(
            coordinates.startX,
            coordinates.startY + arcRadius,
            arcRadius,
            -Math.PI * 0.5,
            Math.PI,
            true,
          );
          ctx.arc(
            coordinates.startX - 2 * arcRadius,
            coordinates.endY - arcRadius,
            arcRadius,
            0,
            Math.PI * 0.5,
          );
        }
        break;
      }
    }
  }

  /**
   * Draws a dependency line in the fourth quadrant.
   * @param coordinates The start and end coordinates of the dependency line.
   * @param dependencyType The type of dependency.
   * @param ctx The canvas rendering context.
   * @param arcRadius The radius of the arc used in drawing curves.
   * @param deltaX The difference between start and end X coordinates.
   */
  private drawFourthQuadrantDependencyLine(
    coordinates: DependencyCoordinates,
    dependencyType: ProjectTaskDependencyType,
    ctx: CanvasRenderingContext2D,
    arcRadius: number,
    deltaX: number,
  ) {
    switch (dependencyType) {
      case ProjectTaskDependencyType.finishToStart: {
        /** Correction of drawing occasion with small day width. */
        if (Math.abs(deltaX) <= 2 * arcRadius) {
          ctx.arc(
            coordinates.startX,
            coordinates.startY + arcRadius,
            arcRadius,
            -Math.PI * 0.5,
            Math.PI * 0.5,
          );
          ctx.arc(
            coordinates.startX,
            coordinates.startY + 3 * arcRadius,
            arcRadius,
            -Math.PI * 0.5,
            -Math.PI,
            true,
          );
          ctx.arc(
            coordinates.startX,
            coordinates.endY - arcRadius,
            arcRadius,
            Math.PI,
            Math.PI * 0.5,
            true,
          );
        } else {
          ctx.arc(
            coordinates.startX,
            coordinates.startY + arcRadius,
            arcRadius,
            -Math.PI * 0.5,
            0,
          );
          ctx.arc(
            coordinates.startX + 2 * arcRadius,
            coordinates.endY - arcRadius,
            arcRadius,
            -Math.PI,
            Math.PI * 0.5,
            true,
          );
        }
        break;
      }
      case ProjectTaskDependencyType.startToStart: {
        ctx.arc(
          coordinates.startX,
          coordinates.startY + arcRadius,
          arcRadius,
          -Math.PI * 0.5,
          Math.PI,
          true,
        );
        ctx.arc(
          coordinates.startX,
          coordinates.endY - arcRadius,
          arcRadius,
          -Math.PI,
          Math.PI * 0.5,
          true,
        );
        break;
      }
      case ProjectTaskDependencyType.finishToFinish: {
        ctx.arc(
          coordinates.endX + arcRadius,
          coordinates.startY + arcRadius,
          arcRadius,
          -Math.PI * 0.5,
          0,
        );
        ctx.arc(
          coordinates.endX + arcRadius,
          coordinates.endY - arcRadius,
          arcRadius,
          0,
          Math.PI * 0.5,
        );
        break;
      }
      case ProjectTaskDependencyType.startToFinish: {
        ctx.arc(
          coordinates.startX,
          coordinates.startY + arcRadius,
          arcRadius,
          -Math.PI * 0.5,
          Math.PI * 0.5,
          true,
        );
        ctx.arc(
          coordinates.endX + arcRadius,
          coordinates.startY + 3 * arcRadius,
          arcRadius,
          -Math.PI * 0.5,
          0,
        );
        ctx.arc(
          coordinates.endX + arcRadius,
          coordinates.endY - arcRadius,
          arcRadius,
          0,
          Math.PI * 0.5,
        );
        break;
      }
    }
  }
}
