<tmt-form-header [name]="referenceCardService.name$ | async"></tmt-form-header>
<tmt-not-found [state]="referenceCardService.state$ | async"></tmt-not-found>
<wp-loading-indicator [state]="referenceCardService.state$ | async"></wp-loading-indicator>

@if ((referenceCardService.state$ | async) === 'Ready') {
  <ul class="nav nav-tabs mt-3">
    @for (tab of tabs; track $index) {
      <li class="nav-item">
        <a
          class="nav-link"
          [class.active]="stateService?.current?.name === tab.state"
          [uiSref]="tab.state"
          [uiParams]="{ routeMode: 'keep' }"
        >
          {{ tab.header | translate }}
        </a>
      </li>
    }
  </ul>
  <ui-view></ui-view>
}
