import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { StateService } from '@uirouter/angular';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { TabLink } from 'src/app/shared/models/navigation/navigation';
import { NavigationService } from 'src/app/core/navigation.service';
import _ from 'lodash';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Reference } from 'src/app/settings-app/reference/model/reference.model';
import { ReferenceCardService } from 'src/app/settings-app/reference/card/reference-card.service';

@Component({
  selector: 'tmt-reference-card',
  templateUrl: './reference-card.component.html',
  providers: [ReferenceCardService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferenceCardComponent implements OnInit {
  @Input() private entityId: string;

  public tabs: TabLink[] = [];

  private destroyRef = inject(DestroyRef);

  constructor(
    public stateService: StateService,
    public referenceCardService: ReferenceCardService,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
    private actionPanelService: ActionPanelService,
  ) {}

  public ngOnInit(): void {
    this.tabs.push({
      header: 'shared2.groups.main',
      state: 'settings.reference.main',
    });

    this.referenceCardService
      .getReferences(this.entityId, {
        select: ['id', 'name'],
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (reference: Reference) => {
          this.referenceCardService.updateName(reference.name);
          // this.referenceService.readonly = !reference.editAllowed; //TODO: when backend ready

          this.tabs.push({
            header: 'components.referenceCardComponent.groups.entries',
            state: 'settings.reference.referenceEntry',
          });

          this.setAdditionalButtons();

          if (
            this.tabs.length > 0 &&
            !this.tabs.find((t) => t.state === this.stateService.current.name)
          ) {
            this.stateService.go(this.tabs[0].state, this.stateService.params);
          }

          this.referenceCardService.state$.next(CardState.Ready);

          this.navigationService.addRouteSegment({
            id: reference.id,
            title: reference.name,
          });
        },
        error: (error: Exception) => {
          this.referenceCardService.state$.next(CardState.Ready);
          this.notificationService.error(error.message);
        },
      });
  }

  private setAdditionalButtons(): void {
    this.actionPanelService.setAdditional([
      {
        title: 'shared2.actions.delete',
        hint: 'shared2.actions.delete',
        name: 'delete',
        isBusy: false,
        isVisible: false,
        handler: () => this.referenceCardService.delete(),
      },
    ]);
  }
}
