import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  DestroyRef,
  inject,
} from '@angular/core';
import { TimeAllocation } from 'src/app/shared/models/entities/base/timesheet.model';
import { AppService } from 'src/app/core/app.service';
import { TimeInputType } from 'src/app/shared/models/enums/time-input-type';
import { TranslateService } from '@ngx-translate/core';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { TransitionService } from '@uirouter/core';
import { ChromeService } from 'src/app/core/chrome.service';
import { AllocationInfoService } from './allocation-info.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { TimesheetCardService } from '../../core/timesheet-card.service';
import { round } from 'lodash';
import { StopwatchService } from 'src/app/core/stopwatch.service';
import { NotificationService } from 'src/app/core/notification.service';
import { StopwatchState } from 'src/app/shared/models/entities/base/stopwatch.model';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { DateTime } from 'luxon';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Сведения о ячейке таймшита. */
@Component({
  selector: 'wp-allocation-info',
  templateUrl: './allocation-info.component.html',
  styleUrls: ['./allocation-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllocationInfoComponent implements OnInit {
  public containerId: string;
  public isShown = false;

  public allocation: TimeAllocation;
  private lineId: string;

  public readonly: boolean;
  public showStartButton: boolean;
  public showStopButton: boolean;

  public swExecuted: boolean;
  public isPause: boolean;

  private width = 275;

  public element = null;

  buttonTitles: string[];
  buttonHints: string[];
  public containerStyles: Dictionary<string> = {};

  public form: UntypedFormGroup = this.fb.group({
    duration: null,
    comments: '',
  });
  dayScheduleDuration: number;

  private destroyRef = inject(DestroyRef);

  constructor(
    private stopwatchService: StopwatchService,
    public blockUI: BlockUIService,
    public timesheetCardService: TimesheetCardService,
    private changeDetector: ChangeDetectorRef,
    transitionService: TransitionService,
    private fb: UntypedFormBuilder,
    private app: AppService,
    private translate: TranslateService,
    public service: AllocationInfoService,
    private chrome: ChromeService,
    private customFieldService: CustomFieldService,
    private notification: NotificationService,
  ) {
    transitionService.onSuccess({}, this.service.close);
    this.customFieldService.enrichFormGroup(this.form, 'TimeAllocation');
  }

  // TODO: change the position update to Renderer2.
  private updateContainer(): void {
    const element = document.getElementById(this.containerId);

    if (!element) {
      return;
    }

    const rect = element.getBoundingClientRect();
    this.containerStyles['left'] =
      rect.left + rect.width / 2 - this.width + 30 + 'px';
    this.containerStyles['top'] = rect.bottom + 13 + 'px';
    this.containerStyles['width'] = this.width + 'px';

    // Если ячейка левее фиксированной таблицы - скрыть контейнер.
    const fixTableRect = document
      .querySelector('[name=left]')
      .getBoundingClientRect();
    if (fixTableRect.right >= rect.right) {
      this.close();
    }

    this.changeDetector.detectChanges();
  }

  public close() {
    this.isShown = false;
    this.changeDetector.markForCheck();
  }

  public updateStopwatchState() {
    if (!this.allocation) {
      return;
    }

    // Решаем показывать ли блок секундомера.
    this.showStopButton =
      this.stopwatchService.stopwatch?.timeSheetLineId === this.lineId &&
      this.stopwatchService.stopwatch.date === this.allocation.date;

    // Показываем кнопку запуска секундомера только на текущий день.
    this.showStartButton =
      this.app.session.useStopwatch &&
      DateTime.fromISO(this.allocation.date).equals(
        DateTime.now().startOf('day').startOf('day'),
      ) &&
      !this.stopwatchService.stopwatch;

    this.isPause =
      this.stopwatchService.stopwatch?.state === StopwatchState.Paused;

    this.changeDetector.markForCheck();
  }

  // Установить время.
  public setDuration(option: number) {
    const isPercent =
      this.app.session.timeInputType === TimeInputType.SchedulePercent;
    let duration = 0;

    switch (option) {
      case 1:
        duration = isPercent ? 5 : 0.25;
        break;
      case 2:
        duration = isPercent ? 10 : 0.5;
        break;
      case 3:
        duration = isPercent ? 25 : 1;
        break;
      case 4:
        duration = isPercent ? 50 : 1.5;
        break;
      case 5:
        duration = isPercent ? 75 : 2;
        break;
      case 6:
        duration = isPercent ? 100 : 4;
        break;
    }

    if (isPercent) {
      duration = round((this.dayScheduleDuration * duration) / 100, 4);
    }

    this.service.patchAllocation({ duration });
  }

  /*КОМАНДЫ СЕКУНДОМЕРА*/

  /** Запуск счетчика времени. */
  public start() {
    this.swExecuted = true;

    this.stopwatchService.start(this.lineId).then(
      () => {
        this.swExecuted = false;
        this.updateStopwatchState();
        this.changeDetector.markForCheck();
      },
      (message: string) => {
        this.swExecuted = false;
        this.notification.warning(message);
      },
    );
  }

  /** Приостановка счетчика времени. */
  public pause() {
    this.swExecuted = true;
    this.stopwatchService.pause().then(
      () => {
        this.swExecuted = false;
        this.updateStopwatchState();
        this.changeDetector.markForCheck();
      },
      (message: string) => {
        this.swExecuted = false;
        this.notification.warning(message);
      },
    );
  }

  /** Возобновление счетчика времени. */
  public resume() {
    this.swExecuted = true;

    this.stopwatchService.resume().then(
      () => {
        this.swExecuted = false;
        this.updateStopwatchState();
        this.changeDetector.markForCheck();
      },
      (message: string) => {
        this.swExecuted = false;
        this.notification.warning(message);
      },
    );
  }

  /** Завершение счетчика времени. */
  public stop(type: any) {
    this.swExecuted = true;
    this.blockUI.start();

    this.stopwatchService.stop(type).then(
      () => {
        this.blockUI.stop();
        this.swExecuted = false;
        this.updateStopwatchState();
        this.changeDetector.markForCheck();
      },
      (message: string) => {
        this.blockUI.stop();
        this.swExecuted = false;
        this.notification.warning(message);
      },
    );
  }

  ngOnInit(): void {
    // Локализация кнопочек.
    switch (this.app.session.timeInputType) {
      case TimeInputType.HoursAndMinutes:
        this.buttonTitles = ['0:15', '0:30', '1:00', '1:30', '2:00', '4:00'];
        break;

      case TimeInputType.Decimal: {
        const m = this.translate.instant(
          'timesheets.card.entryInfo.hotKeys.minutes',
        );
        const h = this.translate.instant(
          'timesheets.card.entryInfo.hotKeys.hours',
        );
        this.buttonTitles = [
          '15' + m,
          '30' + m,
          '1' + h,
          '1.5' + h,
          '2' + h,
          '4' + h,
        ];
        break;
      }
      case TimeInputType.SchedulePercent:
        this.buttonTitles = ['5%', '10%', '25%', '50%', '75%', '100%'];
        break;
    }

    switch (this.app.session.timeInputType) {
      case TimeInputType.SchedulePercent:
        this.buttonHints = this.buttonTitles;
        break;
      default:
        this.buttonHints = [
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint0_25'),
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint0_5'),
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint1'),
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint1_5'),
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint2'),
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint4'),
        ];
    }

    this.service.open$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((param) => {
        this.containerId = param.containerId;
        this.isShown = true;
        this.allocation = param.allocation;
        this.lineId = param.lineId;
        this.form.reset();
        this.form.patchValue(param.allocation, { emitEvent: false });
        this.readonly = param.readonly;
        this.dayScheduleDuration = param.dayScheduleDuration;

        this.readonly ? this.form.disable() : this.form.enable();

        this.updateContainer();
        this.updateStopwatchState();
      });

    this.service.close$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.close();
      });

    this.chrome.mainAreaSize$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.isShown) {
          this.updateContainer();
        }
      });

    this.chrome.scroll$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.isShown) {
          this.updateContainer();
        }
      });

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const patch = {
          comments: this.form.value.comments,
        } as any;
        this.customFieldService.assignValues(
          patch,
          this.form.value,
          'TimeAllocation',
          true,
        );
        this.service.patchAllocation(patch);
        this.changeDetector.markForCheck();
      });

    this.service.redraw$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.updateContainer();
      });

    this.stopwatchService.stopwatch$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.updateStopwatchState();
      });
  }
}
