<wp-loading-indicator [state]="state$ | async" />
<tmt-not-found [state]="state$ | async" />
<wp-data-not-saved-indicator [form]="referenceForm" class="mt-3" />

@if ((state$ | async) === 'Ready') {
  <form [formGroup]="referenceForm" class="form">
    <div class="form-group" tmtIndicator>
      <label>{{ 'shared2.props.name' | translate }}</label>
      <wp-text-box formControlName="name" placeholder="{{ 'shared2.props.name' | translate }}" />
    </div>

    <div class="form-group">
      <label>{{ 'shared2.props.description' | translate }}</label>
      <wp-multiline-text-box
        formControlName="description"
        placeholder="{{ 'shared2.props.description' | translate }}"
      />
    </div>

    <div class="form-check" title="{{ 'shared2.props.isActive.hint' | translate }}">
      <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
      <label class="form-check-label" for="isActive">
        {{ 'shared2.props.isActive.value' | translate }}
      </label>
    </div>

    @if (!readonly) {
      <div class="actions">
        <button
          type="button"
          class="btn btn-primary"
          wpButtonSpinner
          [isBusy]="actionService.action('save').isBusy"
          (click)="save()"
        >
          {{ 'shared.actions.save' | translate }}
        </button>
      </div>
    }
  </form>
}
