import { Component, Input } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { GridOptions } from 'src/app/shared/components/features/grid/grid-options.model';

import { GridService } from 'src/app/shared/components/features/grid/core/grid.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import {
  GridColumnType,
  GridStringControlColumn,
} from 'src/app/shared/models/inner/grid-column.interface';
import { Guid } from 'src/app/shared/helpers/guid';
import { CustomFieldCardService } from 'src/app/settings-app/custom-fields/card/custom-field-card.service';
import { Constants } from 'src/app/shared/globals/constants';
import { MetaEntityPropertyType } from 'src/app/shared/models/entities/settings/metamodel.model';

@Component({
  selector: 'wp-field-configuration',
  templateUrl: './field-configuration.component.html',
  providers: [GridService],
})
export class FieldConfigurationComponent {
  @Input() formGroup: UntypedFormGroup;
  @Input() readonly: boolean;
  @Input() fieldType: MetaEntityPropertyType;
  @Input() fieldEntityType: string;
  @Input() public formColumns: NamedEntity[];

  public readonly customFieldType = MetaEntityPropertyType;

  public get lookupValues(): UntypedFormArray {
    return this.formGroup.controls['lookupValues'] as UntypedFormArray;
  }

  public gridOptions: GridOptions = {
    css: 'wp-nested-table',
    sorting: false,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          const defaultValue = this.formGroup.value.defaultValue;
          if (defaultValue && defaultValue.id === formGroup.value.id) {
            this.formGroup.get('defaultValue').setValue(null);
          }

          this.lookupValues.removeAt(index);
          this.lookupValues.markAsDirty();
        },
      },
    ],
    view: {
      name: 'strings',
      columns: [
        <GridStringControlColumn>{
          name: 'name',
          header: 'settings.customFields.card.columns.lookupValue.header',
          hint: 'settings.customFields.card.columns.lookupValue.hint',
          type: GridColumnType.StringControl,
          placeholder: 'settings.customFields.card.columns.lookupValue.header',
          width: '70%',
        },
        <GridStringControlColumn>{
          name: 'code',
          header: 'shared.columns.code',
          hint: 'shared.columns.code',
          type: GridColumnType.StringControl,
          placeholder: 'shared.columns.code',
          width: '30%',
        },
        {
          name: 'isActive',
          header: 'shared.columns.isActive.title',
          hint: 'shared.columns.isActive.title',
          type: GridColumnType.BooleanControl,
          width: '100px',
        },
      ],
    },
  };

  constructor(
    public cardService: CustomFieldCardService,
    private fb: UntypedFormBuilder,
    private gridService: GridService,
  ) {
    this.cardService.detectChanges$.subscribe(() => {
      this.gridService.detectChanges();
    });
  }

  public addLookupValue() {
    const group = this.fb.group({
      id: [Guid.generate()],
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      code: [''],
      isActive: [true],
    });
    this.lookupValues.push(group);
    this.lookupValues.markAsDirty();
    this.gridService.selectGroup(group);
  }
}
