import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { BehaviorSubject, Subscription, filter, merge, takeUntil } from 'rxjs';
import { ChromeService } from 'src/app/core/chrome.service';
import {
  Grid2Options,
  SelectionType,
} from 'src/app/shared-features/grid2/models/grid-options.model';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { ListService } from 'src/app/shared/services/list.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { ReferenceEntry } from 'src/app/settings-app/reference/model/reference.model';
import { ReferenceEntryLineComponent } from 'src/app/settings-app/reference/card/reference-entity/reference-entry-line/reference-entry-line.component';
import { ReferenceEntryToolbarComponent } from 'src/app/settings-app/reference/card/reference-entity/reference-entry-toolbar/reference-entry-toolbar.component';
import { REFERENCE_ENTRY_LIST } from 'src/app/settings-app/reference/card/reference-entity/model/reference-entry.list';
import { DefaultFilterService } from 'src/app/settings-app/shared/default-filter/default-filter.service';
import { ReferenceCardService } from 'src/app/settings-app/reference/card/reference-card.service';

@Component({
  selector: 'tmt-reference-entry',
  templateUrl: './reference-entry.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: FilterService, useClass: DefaultFilterService },
    { provide: VIEW_NAME, useValue: 'default' },
    { provide: LIST, useValue: REFERENCE_ENTRY_LIST },
    EntityListService,
    ListService,
    GridService,
    SavingQueueService,
  ],
})
export class ReferenceEntryComponent implements OnInit, OnDestroy {
  public formArray = this.fb.array([]);
  public gridOptions: Grid2Options;
  public isGridLoading$ = new BehaviorSubject<boolean>(false);

  private referenceEntitiesCollection =
    this.data.collection('ReferenceEntries');
  private currentPage = 0;
  private pageSize = 50;
  private loadedAll = false;
  private isStopSaving = false;
  private scrollSubscription: Subscription | null;
  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public entityId,
    public gridService: GridService,
    public referenceCardService: ReferenceCardService,
    public savingQueueService: SavingQueueService,
    private data: DataService,
    private notificationService: NotificationService,
    private modal: NgbModal,
    private actionService: ActionPanelService,
    private fb: UntypedFormBuilder,
    private injector: Injector,
    private chromeService: ChromeService,
    private listService: ListService,
    private filterService: FilterService,
    private cdr: ChangeDetectorRef,
    private blockUI: BlockUIService,
  ) {}

  public ngOnInit(): void {
    this.actionService.action('save')?.hide();
    this.actionService.setHasAutosave(true);

    this.prepareGridOptions();
    this.loadReferenceEntries();
    this.enableInfinityScroll();

    merge(
      this.filterService.values$,
      this.savingQueueService.error$,
      this.actionService.reload$,
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.reload();
      });
  }

  public ngOnDestroy(): void {
    this.scrollSubscription?.unsubscribe();
  }

  /**
   * Deletes reference entity.
   *
   * @param id Reference id.
   */
  public deleteReferenceEntry(id: string): void {
    this.blockUI.start();
    this.referenceEntitiesCollection
      .entity(id)
      .delete()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          const index = this.formArray.controls.findIndex(
            (control) => control.value.id === id,
          );
          this.formArray.controls.splice(index, 1);
          const groupForSelection =
            this.formArray.controls[index] ??
            this.formArray.controls[index - 1] ??
            null;

          this.gridService.selectGroup(groupForSelection as UntypedFormGroup);

          this.gridService.detectChanges();
          this.notificationService.successLocal(
            'components.referenceEntryComponent.messages.deleted',
          );
          this.blockUI.stop();
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.blockUI.stop();
        },
      });
  }

  /**
   * Edits reference entries.
   *
   * @param existingEntry existing reference entry.
   */
  public openReferenceEntryModal(existingEntry?: ReferenceEntry): void {
    this.savingQueueService.save().then(() => {
      const ref = this.modal.open(ReferenceEntryLineComponent, {
        injector: this.injector,
      });
      ref.componentInstance.entityId = this.entityId;

      if (existingEntry) {
        ref.componentInstance.referenceEntry = existingEntry;
      }

      ref.result
        .then((referenceEntry) => {
          this.isStopSaving = true;
          if (existingEntry) {
            const foundFormGroup = this.formArray.controls.find(
              (control) => control.getRawValue().id === referenceEntry.id,
            );
            foundFormGroup.patchValue(referenceEntry);
            this.gridService.selectGroup(foundFormGroup as UntypedFormGroup);
          } else {
            const group = this.getReferenceEntryFormGroup(referenceEntry);
            group.patchValue(referenceEntry);
            this.formArray.insert(0, group);
            this.gridService.selectGroup(group);
          }
          this.isStopSaving = false;
        })
        .catch(() => null);
    });
  }

  /** Enables references lazy-loading on scroll event. */
  private enableInfinityScroll(): void {
    this.scrollSubscription = this.chromeService.setInfinityScroll(() =>
      this.loadReferenceEntries(),
    );
  }

  private getReferenceEntryFormGroup(
    referenceEntry?: ReferenceEntry,
  ): UntypedFormGroup {
    const formStructure = {
      id: referenceEntry?.id ?? null,
      name: referenceEntry?.name ?? null,
      code: referenceEntry?.code ?? null,
      description: referenceEntry?.description ?? null,
      isActive: referenceEntry?.isActive ?? null,
    };
    const group = this.fb.group(formStructure);
    group.valueChanges
      .pipe(
        filter(() => !this.isStopSaving),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        if (group.invalid) {
          this.notificationService.warningLocal(
            'shared2.messages.entityNotSaved',
          );
          return;
        }

        const entryValue = group.getRawValue();

        const entry: Partial<ReferenceEntry> = {
          name: entryValue.name,
          code: entryValue?.code,
          description: entryValue?.description,
          isActive: entryValue?.isActive,
        };

        this.savingQueueService.addToQueue(entryValue.id, () =>
          this.referenceCardService.patchReferenceEntry(entryValue.id, entry),
        );
      });

    return group;
  }

  private reload(): void {
    if (!this.scrollSubscription) {
      this.enableInfinityScroll();
    }
    this.currentPage = 0;
    this.pageSize = 50;
    this.loadedAll = false;
    this.formArray.clear();
    this.loadReferenceEntries();
  }

  private loadReferenceEntries(): void {
    if (this.isGridLoading$.getValue()) {
      return;
    }

    this.isGridLoading$.next(true);

    const query = {
      select: ['id', 'code', 'name', 'description', 'isActive'],
      filter: this.filterService.getODataFilter(),
      top: this.pageSize,
      skip: this.pageSize * this.currentPage,
    };
    this.referenceEntitiesCollection
      .query(query)
      .pipe(takeUntil(this.actionService.reload$))
      .subscribe({
        next: (referenceEntities: ReferenceEntry[]) => {
          this.loadedAll = referenceEntities.length < this.pageSize;
          this.currentPage++;

          referenceEntities.forEach((entity) => {
            const group = this.getReferenceEntryFormGroup(entity);
            this.formArray.push(group);
          });

          if (this.referenceCardService.readonly) {
            this.formArray.disable({ emitEvent: false });
          }

          this.cdr.markForCheck();
          this.isGridLoading$.next(false);
          if (this.loadedAll) {
            this.scrollSubscription?.unsubscribe();
            this.scrollSubscription = null;
          }
        },
        error: (error: Exception) => {
          this.isGridLoading$.next(false);
          this.notificationService.error(error.message);
        },
      });
  }

  private prepareGridOptions(): void {
    this.gridOptions = {
      toolbar: ReferenceEntryToolbarComponent,
      selectionType: SelectionType.row,
      resizableColumns: true,
      commands: [
        {
          name: 'create',
          handlerFn: () => {
            this.openReferenceEntryModal();
          },
          allowedFn: () => !this.referenceCardService.readonly,
        },
        {
          name: 'edit',
          allowedFn: (group) => group && !this.referenceCardService.readonly,
          handlerFn: () => {
            this.openReferenceEntryModal(this.gridService.selectedGroupValue);
          },
        },
        {
          name: 'delete',
          allowedFn: (group) => !!group && true,
          handlerFn: (id) => {
            this.deleteReferenceEntry(id);
          },
        },
      ],
      rowCommands: [
        {
          name: 'edit',
          label: 'shared.actions.edit',
          handlerFn: (group) => {
            this.openReferenceEntryModal(group.getRawValue());
          },
          allowedFn: () => !this.referenceCardService.readonly,
        },
        {
          name: 'delete',
          label: 'shared.actions.delete',
          handlerFn: (group) => {
            this.deleteReferenceEntry(group.getRawValue().id);
          },
          allowedFn: () => !this.referenceCardService.readonly,
        },
      ],
      view: this.listService.getGridView(),
    };
  }
}
