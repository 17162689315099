import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import {
  GridColumnType,
  GridComponentColumn,
  GridNavigationColumn,
} from '../models/inner/grid-column.interface';
import { SourceProjectVersionReadonlyCellComponent } from 'src/app/projects/project-versions/shared/source-project-version-cell/source-project-version-readonly-cell.component';

export const PROJECT_VERSION_LIST: List = {
  version: 3,
  name: 'versions',
  dataCollection: 'ProjectVersions',
  clrType: 'ProjectVersion',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'sourceVersion',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 150,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 125,
        },
        {
          column: 'createdBy',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'modified',
          visibleByDefault: true,
          width: 125,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'masterBaseline',
          visibleByDefault: true,
          width: 80,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      state: 'projectVersion',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
      stateParams: {
        fromRow: {
          entityId: 'id',
          projectId: 'mainProjectId',
        },
      },
      availableTotals: [TotalType.Count],
    },
    <GridComponentColumn>{
      name: 'sourceVersion',
      header: 'projects.projectVersions.list.columns.sourceVersion',
      hint: 'projects.projectVersions.list.columns.sourceVersion',
      type: GridColumnType.Component,
      component: SourceProjectVersionReadonlyCellComponent,
    },
    {
      name: 'state',
      header: 'projects.projectVersions.list.columns.state',
      hint: 'projects.projectVersions.list.columns.state',
      type: GridColumnType.State,
      fixedWidth: true,
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.DateTime,
      fixedWidth: true,
    },
    {
      name: 'createdBy',
      header: 'shared.columns.createdBy.title',
      hint: 'shared.columns.createdBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'modified',
      header: 'shared.columns.modified.title',
      hint: 'shared.columns.modified.hint',
      type: GridColumnType.DateTime,
      fixedWidth: true,
    },
    {
      name: 'modifiedBy',
      header: 'shared.columns.modifiedBy.title',
      hint: 'shared.columns.modifiedBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'masterBaseline',
      header: 'projects.projectVersions.list.columns.masterBaseline',
      hint: 'projects.projectVersions.list.columns.masterBaseline',
      type: GridColumnType.Boolean,
      fixedWidth: true,
    },
  ],
  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'mainProjectId',
      field: 'mainProjectId',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'sourceVersion',
      field: 'sourceVersion.name',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'state',
      field: ['state.name', 'state.code', 'state.style'],
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'createdBy',
      field: 'createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modified',
      field: 'modified',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modifiedBy',
      field: 'modifiedBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'masterBaseline',
      field: 'masterBaseline',
      loadingStrategy: LoadingStrategy.Always,
    },
  ],
};
