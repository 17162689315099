<p *ngIf="readonly && title" class="trim text-readonly" title="{{ title }}">
  {{ titleShort }}
</p>

<p *ngIf="readonly && !title" class="trim text-body-secondary text-hidden text-readonly">
  {{ 'shared.valueNotSelected' | translate }}
</p>

<div
  *ngIf="!readonly"
  class="input-group"
  (clickOutside)="cancel()"
  [excludeBeforeClick]="true"
  [attachOutsideOnClick]="true"
  wpIntersectable
  (onIntersect)="cancel()"
  #predecessorBox
>
  <input
    #input
    class="form-control input--disabled"
    value="{{ titleShort }}"
    title="{{ title }}"
    type="text"
    readonly
    placeholder="{{ placeholder }}"
    (click)="onInputClick()"
  />

  <span
    *ngIf="dependencies.length"
    class="input-clear"
    title="{{ 'shared.hintClearControlButton' | translate }}"
    (click)="clearValues()"
  >
    &times;
  </span>

  <button type="button" class="btn btn-default btn--arrow input-group-text" (click)="openList()">
    <i class="bi bi-chevron-down" aria-hidden="true"></i>
  </button>

  <div class="select-expand-container" #expandingArea [hidden]="!listOpened">
    <div class="fixed-expanding-area" *ngIf="listOpened" #expandingArea>
      <!--       <wp-select-box
        [values]="dependencyTypes"
        [formControl]="dependencyTypeControl"
        [allowNull]="false"
      /> -->
      <div *ngIf="allowedTaskIds.length" class="task__search">
        <input
          #inputSearch
          class="form-control ellipsis"
          type="text"
          maxlength="255"
          placeholder="{{ 'shared.filter.defaultPlaceholder' | translate }}"
          [formControl]="searchControl"
        />

        <span class="input-clear" (click)="clearSearch()" [hidden]="!searchControl.value">
          &times;
        </span>
      </div>

      <div class="task__container expanding-area-vertical-scroll">
        <div
          *ngFor="let task of filteredTasks"
          [title]="task.structNumber + ' ' + task.name"
          class="task__row"
          (click)="itemCLickHandler($event, task)"
        >
          <div class="task__checkbox">
            <input type="checkbox" [checked]="task.selected" [id]="task.id" />
          </div>
          <label class="task__number trim" [for]="task.id">
            <span>{{ task.structNumber }}</span>
          </label>
          <label class="task__name trim" [for]="task.id">
            <span>{{ task.name }}</span>
          </label>
        </div>
      </div>
      <div *ngIf="!filteredTasks.length" class="center-block task__empty">
        <p class="text-body-secondary m-0">{{ 'shared.noOptions' | translate }}</p>
      </div>
    </div>
  </div>
</div>
