import { Navigation } from '../models/navigation/navigation';
import { AppService } from '../../core/app.service';
import { PermissionType } from '../models/inner/permission-type.enum';
import { Feature } from '../models/enums/feature.enum';
import { TIMESHEET_LIST } from '../lists/timesheet.list';
import { PROJECT_LIST } from 'src/app/shared/lists/project.list';
import { CLIENT_LIST } from 'src/app/shared/lists/client.list';
import { PROGRAM_LIST } from 'src/app/shared/lists/program.list';
import { TIME_OFF_LIST } from 'src/app/shared/lists/time-off.list';
import { EXPENSES_LIST } from 'src/app/shared/lists/expenses.list';
import { ISSUE_LIST } from 'src/app/issues/models/issue.list';

export const MY_APP_NAVIGATION: Navigation = {
  name: 'my',

  applicationTitle: 'shared.apps.my',
  groups: [
    {
      name: 'inbox',
      header: 'my.navigation.inbox.header',
      items: [
        {
          name: 'my.inboxTimesheets',
          state: 'timesheets',
          header: 'my.navigation.inbox.timesheets.header',
          hint: 'my.navigation.inbox.timesheets.hint',
          stateParams: {
            view: 'with-my-task',
          },
          indicator: {
            hint: 'my.navigation.inbox.timesheets.indicator',
            list: TIMESHEET_LIST,
            viewName: 'with-my-task',
          },
        },

        {
          name: 'my.inboxTimeOff',
          state: 'timeOffRequests',
          header: 'my.navigation.inbox.timeOff.header',
          hint: 'my.navigation.inbox.timeOff.hint',
          stateParams: {
            view: 'with-my-task',
          },
          indicator: {
            hint: 'my.navigation.inbox.timeOff.indicator',
            list: TIME_OFF_LIST,
            viewName: 'with-my-task',
          },
        },

        {
          name: 'my.inboxExpenseRequests',
          state: 'expensesRequests',
          header: 'my.navigation.inbox.expenseRequests.header',
          hint: 'my.navigation.inbox.expenseRequests.hint',
          stateParams: {
            view: 'with-my-task',
          },
          indicator: {
            hint: 'my.navigation.inbox.expenseRequests.indicator',
            list: EXPENSES_LIST,
            viewName: 'with-my-task',
          },
        },

        {
          name: 'my.inboxAll',
          state: 'workflowTasks',
          header: 'my.navigation.inbox.all.header',
          hint: 'my.navigation.inbox.all.hint',
          stateParams: {
            view: 'my-all',
          },
          isVisible: false,
        },
      ],
    },

    {
      name: 'sent',
      header: 'my.navigation.sent.header',
      items: [
        {
          name: 'my.sentTimesheets',
          state: 'timesheets',
          header: 'my.navigation.sent.timesheets.header',
          hint: 'my.navigation.sent.timesheets.hint',
          stateParams: {
            view: 'with-my-workflow',
          },
          indicator: {
            hint: 'my.navigation.sent.timesheets.indicator',
            list: TIMESHEET_LIST,
            viewName: 'with-my-workflow',
          },
        },

        {
          name: 'my.sentTimeOff',
          state: 'timeOffRequests',
          header: 'my.navigation.sent.timeOff.header',
          hint: 'my.navigation.sent.timeOff.hint',
          stateParams: {
            view: 'with-my-workflow',
          },
          indicator: {
            hint: 'my.navigation.sent.timeOff.indicator',
            list: TIME_OFF_LIST,
            viewName: 'with-my-workflow',
          },
        },

        {
          name: 'my.sentExpenseRequests',
          state: 'expensesRequests',
          header: 'my.navigation.sent.expenseRequests.header',
          hint: 'my.navigation.sent.expenseRequests.hint',
          stateParams: {
            view: 'with-my-workflow',
          },
          indicator: {
            hint: 'my.navigation.sent.expenseRequests.indicator',
            list: EXPENSES_LIST,
            viewName: 'with-my-workflow',
          },
        },

        {
          name: 'my.workflow',
          state: 'workflow-instances',
          header: 'my.navigation.sent.all.header',
          hint: 'my.navigation.sent.all.hint',
          isVisible: false,
          stateParams: {
            view: 'my',
          },
        },
      ],
    },

    {
      name: 'issues',
      header: 'shared2.groups.my.navigation.issues.header',
      items: [
        {
          name: 'my.myIssues',
          state: 'issues',
          header: 'shared2.groups.my.navigation.issues.my.header',
          hint: 'shared2.groups.my.navigation.issues.my.hint',
          stateParams: {
            view: 'my',
          },
          indicator: {
            hint: 'shared2.groups.my.navigation.issues.my.indicator',
            list: ISSUE_LIST,
            viewName: 'my',
          },
        },
        {
          name: 'my.assignedIssues',
          state: 'issues',
          header: 'shared2.groups.my.navigation.issues.assigned.header',
          hint: 'shared2.groups.my.navigation.issues.assigned.hint',
          stateParams: {
            view: 'assigned',
          },
          indicator: {
            hint: 'shared2.groups.my.navigation.issues.assigned.indicator',
            list: ISSUE_LIST,
            viewName: 'assigned',
          },
        },
        {
          name: 'my.allIssues',
          state: 'issues',
          header: 'shared2.groups.my.navigation.issues.all.header',
          hint: 'shared2.groups.my.navigation.issues.all.hint',
          stateParams: {
            view: 'all',
          },
        },
      ],
    },
    {
      name: 'tracking',
      header: 'my.navigation.tracking.header',
      items: [
        {
          name: 'my.currentTimesheet',
          state: 'currentTimesheet',
          header: 'my.navigation.tracking.currentTimesheet.header',
          hint: 'my.navigation.tracking.currentTimesheet.hint',
          allowedFn: (app: AppService): boolean =>
            app.checkPermission('TimeSheet', 'My', PermissionType.Read) &&
            app.checkFeature(Feature.timesheets),
        },
        {
          name: 'my.timesheetsExpired',
          state: 'timesheets',
          header: 'my.navigation.tracking.timesheetsExpired.header',
          hint: 'my.navigation.tracking.timesheetsExpired.hint',
          stateParams: {
            view: 'my-expired',
          },
          indicator: {
            hint: 'my.navigation.tracking.timesheetsExpired.indicator',
            list: TIMESHEET_LIST,
            viewName: 'my-expired',
          },
          allowedFn: (app: AppService): boolean =>
            app.checkPermission('TimeSheet', 'My', PermissionType.Read) &&
            app.checkFeature(Feature.timesheets),
        },

        {
          name: 'my.timesheetsAll',
          state: 'timesheets',
          header: 'my.navigation.tracking.allTimesheets.header',
          hint: 'my.navigation.tracking.allTimesheets.hint',
          stateParams: {
            view: 'my-all',
          },
          isVisible: true,
          allowedFn: (app: AppService): boolean =>
            app.checkPermission('TimeSheet', 'My', PermissionType.Read) &&
            app.checkFeature(Feature.timesheets),
        },
        {
          name: 'my.timeOffRequestsAll',
          state: 'timeOffRequests',
          header: 'my.navigation.tracking.timeOff.header',
          hint: 'my.navigation.tracking.timeOff.hint',
          stateParams: {
            view: 'my-all',
          },
          allowedFn: (app: AppService): boolean =>
            app.checkPermission('TimeOffRequest', 'My', PermissionType.Read) &&
            app.checkFeature(Feature.timeOff),
        },
        {
          name: 'my.expensesRequestsAll',
          state: 'expensesRequests',
          header: 'my.navigation.tracking.expenses.header',
          hint: 'my.navigation.tracking.expenses.hint',
          stateParams: {
            view: 'my-all',
          },
          allowedFn: (app: AppService): boolean =>
            app.checkPermission('ExpenseRequest', 'My', PermissionType.Read) &&
            app.checkFeature(Feature.expenses),
        },
      ],
    },

    {
      header: 'my.navigation.projects.header',
      name: 'projects',
      items: [
        {
          name: 'my.projects',
          state: 'projects',
          isVisible: false,
          header: 'my.navigation.projects.myProjects.header',
          hint: 'my.navigation.projects.myProjects.hint',
          stateParams: {
            view: 'my',
          },

          indicator: {
            hint: 'my.navigation.projects.myProjects.header',
            viewName: 'my',
            list: PROJECT_LIST,
          },
        },
        {
          name: 'my.clients',
          state: 'clients',
          isVisible: false,
          header: 'my.navigation.projects.myClients.header',
          hint: 'my.navigation.projects.myClients.hint',
          stateParams: {
            view: 'my',
          },

          indicator: {
            hint: 'my.navigation.projects.myClients.header',
            viewName: 'my',
            list: CLIENT_LIST,
          },
        },
        {
          name: 'my.programs',
          state: 'programs',
          isVisible: false,
          header: 'my.navigation.projects.myPrograms.header',
          hint: 'my.navigation.projects.myPrograms.hint',
          stateParams: {
            view: 'my',
          },

          indicator: {
            hint: 'my.navigation.projects.myPrograms.header',
            viewName: 'my',
            list: PROGRAM_LIST,
          },
        },
      ],
    },

    {
      name: 'work',
      header: 'my.navigation.work.header',
      items: [
        {
          name: 'my.profile',
          state: 'employee',
          stateParams: {
            entityId: 'my',
          },
          header: 'my.navigation.work.profile.header',
          hint: 'my.navigation.work.profile.hint',
        },
        {
          name: 'my.certificates',
          state: 'certificates',
          stateParams: {
            view: 'my',
          },

          header: 'my.navigation.work.certificates.header',
          hint: 'my.navigation.work.certificates.hint',
        },
      ],
    },
  ],
};
