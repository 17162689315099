import { Inject, Injectable, Optional, Type } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LocalStorageService } from 'ngx-webstorage';

import { Subject } from 'rxjs';
import _ from 'lodash';

import { FilterDetails } from './filter-details.interface';
import { FILTER, LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { List } from 'src/app/shared/models/inner/list';
import { DateService } from 'src/app/core/date.service';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { AppService } from 'src/app/core/app.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { EntityFilter } from 'src/app/core/navigation.service';
import {
  MetaEntityBaseProperty,
  MetaEntityPropertyType,
} from 'src/app/shared/models/entities/settings/metamodel.model';

/** Базовый сервис фильтров. */
@Injectable()
export class FilterService {
  /** Custom handlers for filter counter. */
  public customCriteriaCount: Record<string, () => number> = {
    text: () => 0,
    period: () => (this.hasPeriodSelector || !this.values.period ? 0 : 1),
    states: () => (this.stateConditionExist() ? 1 : 0),
  };
  /** Минимальное число символов, введенных пользователем, для выполнения поиска. */
  public minStringLengthForFilter = 2;

  /** Имя хранилища (local storage) для сохранения фильтра. */
  public storageName: string;

  /** Плейсхолдер в основном контроле. */
  public placeholder: string;

  /** Компонента, реализующая выпадающую секцию с детализацией фильтра. */
  public component: Type<FilterDetails>;

  /** Detail area filter container styles. */
  public detailAreaStyles: Record<string, string>;

  /** Значения всех свойств фильтра. */
  protected _values: any;
  public get values() {
    if (!this.storageName) {
      this.storageName = this.list
        ? `filter_${this.list.name}_${this.viewName}`
        : null;
    }

    if (!this._values && !this.filter && this.storageName) {
      this._values = this.localStorageService.retrieve(this.storageName);
    }

    if (!this._values) {
      this._values = this.getDefaultValues();
    }

    return this._values;
  }

  public set values(values: any) {
    this._values = values;
  }

  /** Признак наличия детальной (выпадающей) области. */
  public hasDetails = false;

  /** Признак наличия кнопки выбора представления области. */
  public hasViewSelector = true;

  /** Indicates if the period selector is available. */
  public hasPeriodSelector = false;
  /** Placeholder for external period selector. */
  public periodSelectorPlaceholder = '';

  public hasAllowInactive = true;

  public views: { code: string; local: string }[];

  private allowInactiveSubject: Subject<any> = new Subject<boolean>();
  public allowInactive$ = this.allowInactiveSubject.asObservable();

  private valuesSubject: Subject<any> = new Subject<any>();
  public values$ = this.valuesSubject.asObservable();

  private resetValuesSubject = new Subject<any | null>();
  public resetValues$ = this.resetValuesSubject.asObservable();

  constructor(
    protected app: AppService,
    protected dateService: DateService,
    private localStorageService: LocalStorageService,
    private customFieldService: CustomFieldService,
    @Optional() @Inject(LIST) protected list: List,
    @Optional() @Inject(VIEW_NAME) protected viewName: string,
    @Optional() @Inject(FILTER) protected filter: EntityFilter,
  ) {}

  public get clrType(): string {
    return this.list?.clrType;
  }

  public changeValues(values: any): void {
    if (!this.filter && this.storageName) {
      this.localStorageService.store(this.storageName, values);
    }

    this.values = values;
    this.valuesSubject.next(values);
  }

  protected getDefaultValues(): any {
    return {
      text: '',
    };
  }

  /** Сбрасывает фильтр. */
  public resetValues(): void {
    const defaultValues = this.getDefaultValues();
    this.changeValues(defaultValues);
    this.resetValuesSubject.next(null);
  }

  /**
   * Runs callback from config if there is special handler for `Form` keys.
   * Otherwise, counter is just incremented if other keys has value.
   *
   * @returns number of criteria selected.
   * */
  public getCriteriaCount(): number {
    let count = 0;
    const criteriaKeys = Object.keys(this.values);

    Object.keys(this.customCriteriaCount).forEach((k) => {
      count += this.customCriteriaCount[k](); // Custom callback from config
      _.remove(criteriaKeys, (key) => key === k);
    });

    for (const key of criteriaKeys) {
      if (!this.values[key] && this.values[key] !== 0) {
        continue;
      }

      if (Array.isArray(this.values[key])) {
        count += this.values[key].length ? 1 : 0;
        continue;
      }

      count++;
    }

    return count;
  }

  /**
   * Criteria counter handler for arrays.
   * Returns `1` if form has values and values length is not same with original array.
   *
   * @param key form property key.
   * @param length length of original array.
   * @returns `1` or `0`.
   */
  public arrayConditionExist(key: string, length: number): number {
    if (!Array.isArray(this.values[key])) {
      return 0;
    }

    return this.values[key].length && this.values[key].length !== length
      ? 1
      : 0;
  }

  /**
   * Checks if there is a state condition.
   * Only works with `StateControlItem`-like interface!
   *
   * @returns `true` if condition exists, otherwise `false`.
   *
   * TODO: make better?
   */
  public stateConditionExist(): boolean {
    let allKeysIsTrue = true;
    let allKeysIsFalse = true;

    for (const name in this.values.states) {
      if (this.values.states[name].selected) {
        allKeysIsFalse = false;
      } else {
        allKeysIsTrue = false;
      }
    }

    return !allKeysIsTrue && !allKeysIsFalse;
  }

  /**
   * Returns query's filter part. By default includes filters for custom field of the entity.
   *
   * @returns OData object.
   *
   * // TODO Make a config for form values !
   */
  public getODataFilter(): any {
    const result = [];

    if (!this.clrType) {
      return result;
    }

    const customFields = this.customFieldService.getList(this.clrType);

    if (customFields.length) {
      customFields.forEach((field) => {
        const value = _.get(this.values, field.name);

        if (value) {
          result.push({
            [this.list.customFieldPrefixForDataField
              ? _.camelCase(this.clrType)
              : 'and']: {
              [this.getFilterFieldKey(field)]: this.getFilterOperator(
                field,
                value,
              ),
            },
          });
        }
      });
    }

    return result;
  }

  /**
   * Gets filter part by keys.
   *
   * @param keys property names.
   * @param value property values.
   * @returns OData with `or` array.
   */
  public getTextFilter(keys: string[], value: string): any {
    return {
      or: keys.map((key) => ({ [`tolower(${key})`]: { contains: value } })),
    };
  }

  public getDatePeriodUrlParams(): Dictionary<string> {
    return null;
  }

  public setAllowingInactive(allowInactive: boolean): void {
    this.allowInactiveSubject.next(allowInactive);
  }

  public enrichFilterForm(form: UntypedFormGroup): void {
    if (!this.list) {
      return;
    }

    this.customFieldService.enrichFormGroup(form, this.clrType);
  }

  protected getClearText(text: string): string {
    return text.toLowerCase();
  }

  private getFilterOperator(field: MetaEntityBaseProperty, value: any): any {
    switch (field.type) {
      case MetaEntityPropertyType.dateOnly:
        return { eq: { type: 'raw', value } };
      case MetaEntityPropertyType.decimal:
        return value;
      case MetaEntityPropertyType.integer:
        return value;
      case MetaEntityPropertyType.reference:
        return { type: 'guid', value: value.id };
      case MetaEntityPropertyType.string:
        return { contains: this.getClearText(value) };
    }
  }

  private getFilterFieldKey(field: MetaEntityBaseProperty): string {
    switch (field.type) {
      case MetaEntityPropertyType.reference:
        return field.name + 'Id';
      case MetaEntityPropertyType.string:
        return `tolower(${field.name})`;
      default:
        return field.name;
    }
  }
}
