import { Inject, Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import {
  EntityFilter,
  NavigationService,
} from 'src/app/core/navigation.service';
import { NotificationService } from 'src/app/core/notification.service';
import { ActOfAcceptance } from 'src/app/shared/models/entities/projects/act-of-acceptance.model';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { StateService } from '@uirouter/angular';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { takeUntil } from 'rxjs/operators';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';

@Injectable()
export class ActCardService {
  public collection = this.data.collection('ActsOfAcceptance');
  public state$ = new ReplaySubject<CardState>();

  private actSubject = new ReplaySubject<ActOfAcceptance>();
  public act$ = this.actSubject.asObservable();

  private act: ActOfAcceptance;
  private appName: string;

  private actQuery = {
    select: [
      'id',
      'name',
      'date',
      'dateOfAcceptance',
      'description',
      'number',
      'editAllowed',
    ],
    expand: [
      {
        lines: {
          expand: {
            projectTask: {
              select: [
                'id',
                'created',
                'name',
                'indent',
                'leadTaskId',
                'structNumber',
              ],
            },
          },
        },
      },
      {
        project: {
          select: ['id', 'name'],
          expand: {
            organization: { select: ['id', 'name'] },
            currency: { select: ['id', 'name', 'alpha3Code'] },
          },
        },
      },
      { state: { select: ['id', 'name', 'code', 'style'] } },
      { createdBy: { select: ['id', 'name'] } },
    ],
  };

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    @Inject('entityId') public actId: string,
    private data: DataService,
    public autosave: SavingQueueService,
    private notification: NotificationService,
    private navigation: NavigationService,
    private actionService: ActionPanelService,
    private message: MessageService,
    private state: StateService,
    private customFieldService: CustomFieldService,
    private lifecycleService: LifecycleService,
  ) {
    lifecycleService.reload$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.reload();
    });
    this.appName = navigation.getAppName();
  }

  /**
   * Reloads Card.
   * */
  public reload() {
    this.autosave.save().then(
      () => {
        this.load();
        this.lifecycleService.reloadLifecycle();
      },
      () => null,
    );
  }

  /**
   * Loads Act.
   * */
  public load() {
    this.state$.next(CardState.Loading);

    this.actionService.resetLifecycle();

    this.customFieldService.enrichQuery(this.actQuery, 'ActOfAcceptance');

    this.collection
      .entity(this.actId)
      .get<ActOfAcceptance>(this.actQuery)
      .subscribe({
        next: (act: ActOfAcceptance) => {
          this.act = act;
          this.navigation.addRouteSegment({
            id: act.id,
            title: act.name,
          });
          this.actSubject.next(act);
          this.state$.next(CardState.Ready);
        },
        error: (error: Exception) => {
          this.state$.next(CardState.Error);
          if (error.code === Exception.BtEntityNotFoundException.code) {
            return;
          }
          this.notification.error(error.message);
        },
      });
  }

  /**
   * Deletes Act.
   * */
  public deleteAct() {
    this.message.confirmLocal('acts.card.messages.deleteConfirmation').then(
      () => {
        this.collection
          .entity(this.actId)
          .delete()
          .subscribe({
            next: () => {
              this.notification.successLocal('shared.messages.deleted');
              this.navigation.updateIndicators();
              this.navigation.goToSelectedNavItem();
            },
            error: (error: Exception) => {
              this.message.error(error.message).then(this.load, this.load);
            },
          });
      },
      () => null,
    );
  }

  /**
   * Navigates to Act Accounting entries.
   * */
  public goToAccountingEntry() {
    this.state.go(`accountingEntries`, {
      navigation: this.navigation.selectedNavigationItem?.name,
      routeMode: RouteMode.continue,
      filter: JSON.stringify(<EntityFilter>{
        name: this.act.name,
        filter: [{ documentId: { type: 'guid', value: this.act.id } }],
      }),
    });
  }

  /**
   * Disposes service active resources.
   * */
  public dispose() {
    this.destroyed$.next();
  }
}
