import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ReferenceCardService } from 'src/app/settings-app/reference/card/reference-card.service';
import { AbstractToolbar } from 'src/app/shared-features/grid2/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';

@Component({
  selector: 'tmt-reference-entry-toolbar',
  templateUrl: './reference-entry-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferenceEntryToolbarComponent
  extends AbstractToolbar
  implements OnInit
{
  constructor(
    gridService: GridService,
    cdr: ChangeDetectorRef,
    public referenceCardService: ReferenceCardService,
  ) {
    super(gridService, cdr);
  }
}
