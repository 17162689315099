import { List, LoadingStrategy } from '../models/inner/list';
import {
  GridColumnType,
  GridNavigationColumn,
} from '../models/inner/grid-column.interface';

export const CERTIFICATES_LIST: List = {
  version: 3,
  name: 'certificates',
  clrType: 'Certificate',
  views: [
    {
      name: 'all',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'owner',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'state',
          width: 150,
          visibleByDefault: true,
        },
        {
          column: 'effectiveDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'expiryDate',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modified',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 1,
        },
      ],
    },

    {
      name: 'my',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'owner',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'state',
          width: 150,
          visibleByDefault: true,
        },
        {
          column: 'effectiveDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'expiryDate',
          width: 1,
          visibleByDefault: true,
        },
        {
          column: 'created',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modified',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 1,
        },
      ],

      contextFilter: [{ ownerId: { type: 'guid', value: '#user' } }],
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      state: 'certificate',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
    },
    {
      name: 'owner',
      type: GridColumnType.String,
      header: 'team.certificates.list.columns.owner.header',
      hint: 'team.certificates.list.columns.owner.hint',
    },
    {
      name: 'state',
      header: 'shared.columns.state',
      hint: 'shared.columns.state',
      type: GridColumnType.State,
      fixedWidth: true,
    },
    {
      name: 'effectiveDate',
      type: GridColumnType.Date,
      header: 'team.certificates.list.columns.effectiveDate.header',
      hint: 'team.certificates.list.columns.effectiveDate.hint',
    },
    {
      name: 'expiryDate',
      type: GridColumnType.Date,
      header: 'team.certificates.list.columns.expiryDate.header',
      hint: 'team.certificates.list.columns.expiryDate.hint',
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'createdBy',
      header: 'shared.columns.createdBy.title',
      hint: 'shared.columns.createdBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'modified',
      header: 'shared.columns.modified.title',
      hint: 'shared.columns.modified.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'modifiedBy',
      header: 'shared.columns.modifiedBy.title',
      hint: 'shared.columns.modifiedBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'isActive',
      header: 'shared.columns.isActive.title',
      hint: 'shared.columns.isActive.hint',
      type: GridColumnType.Boolean,
    },
  ],
  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'owner',
      field: 'owner.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'createdBy',
      field: 'createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    {
      column: 'modified',
      field: 'modified',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modifiedBy',
      field: 'modifiedBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'state',
      field: [
        'state.name',
        'state.code',
        'state.style',
        'state.isEntityProtected',
      ],
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'effectiveDate',
      field: 'effectiveDate',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'expiryDate',
      field: 'expiryDate',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],

  dataCollection: 'Certificates',
};
