import {
  DestroyRef,
  inject,
  Inject,
  Injectable,
  Optional,
} from '@angular/core';
import {
  ENTITY_COLLECTION,
  LIST,
  MASS_OPERATION_PARAMETERS,
} from 'src/app/shared/tokens';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { Observable, Subject, firstValueFrom, merge, of } from 'rxjs';
import { debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { EntityTypeLifecycleInfo } from 'src/app/shared/models/entities/lifecycle/entity-type-lifecycle-info.model';
import { MenuAction } from 'src/app/shared/models/inner/menu-action';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { uniq } from 'lodash';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';
import { NavigationService } from 'src/app/core/navigation.service';
import _ from 'lodash';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { MassOperationComponent } from 'src/app/shared/components/mass-operation/mass-operation.component';
import { List } from 'src/app/shared/models/inner/list';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class LifecycleListService {
  private destroyRef = inject(DestroyRef);
  private lcInfoRequestingCancelled$ = new Subject<void>();
  private readonly filterDebounce = 100;

  constructor(
    @Optional()
    @Inject(ENTITY_COLLECTION)
    private collection: string,
    @Inject(MASS_OPERATION_PARAMETERS)
    private massOperationParameters: MassOperationParameters,
    @Inject(LIST) private list: List,
    private actionPanelService: ActionPanelService,
    private data: DataService,
    private gridService: GridService,
    private listService: EntityListService,
    private filterService: FilterService,
    private modalService: NgbModal,
    private navigationService: NavigationService,
  ) {
    this.gridService.selectedGroups$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((groups) => {
        if (groups.length) {
          this.selectedChangedHandler();
        } else {
          this.clearActionPanel();
        }
      });

    merge(filterService.values$, filterService.allowInactive$)
      .pipe(
        debounceTime(this.filterDebounce),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.selectedChangedHandler();
      });

    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.gridService.clearSelectedGroups();
        this.clearActionPanel();
      });
  }

  /**
   * Set actions to action panel
   *
   * @param lifecycleInfo EntityTypeLifecycleInfo
   * @private
   */
  private setActions(lifecycleInfo: EntityTypeLifecycleInfo) {
    this.clearActionPanel();
    const actions: MenuAction[] = lifecycleInfo.transitions.map(
      (transition) => ({
        name: transition.id,
        hint: transition.name,
        title: transition.name,
        isVisible: true,
        isBusy: false,
        lifecycle: {
          actionType: 'setState',
          stateId: transition.nextStateId,
        },
        handler: () => this.setState(transition),
      }),
    );

    this.actionPanelService.set([
      ...this.actionPanelService.actions,
      {
        name: 'setState',
        hint: 'shared.lifecycle',
        title: 'shared.lifecycle',
        isVisible: true,
        isBusy: false,
        isDropDown: true,
        actions,
      },
    ]);
  }

  /**
   * Open modal for setting state for selected entities.
   *
   * @param transition Transition.
   * @private
   */
  private async setState(transition: any) {
    const items: any[] = this.gridService.selectedGroupsValue;
    let ids = this.gridService.selectedGroupsValue.map((g) => g.id);

    if (this.gridService.isAllSelected) {
      ids = await firstValueFrom(
        this.data
          .collection(this.list.dataCollection)
          .query<any[]>({
            ...this.massOperationParameters.queryData,
            filter: [
              ...this.filterService.getODataFilter(),
              ...this.listService.contextFilter,
            ],
          })
          .pipe(
            tap((entities) => entities.forEach((el) => items.push(el))),
            map((entities) =>
              uniq(
                entities.map((entity) =>
                  this.massOperationParameters.entityPropertyName
                    ? entity[this.massOperationParameters.entityPropertyName].id
                    : entity.id,
                ),
              ),
            ),
          ),
      );
    }

    const ref = this.modalService.open(MassOperationComponent);
    const instance = ref.componentInstance as MassOperationComponent;

    instance.massOperationType = 'changeState';
    instance.changeStateParams = {
      transitionForm: transition.transitionForm,
      nextStateId: transition.nextStateId,
      hasTransitionForm: transition.hasTransitionForm,
    };
    instance.entityIds = ids;
    instance.items = _.uniqBy(items, 'id');
    instance.collection = this.collection ?? this.list.dataCollection;
    instance.state = this.massOperationParameters.state;
    instance.entityPropertyName =
      this.massOperationParameters.entityPropertyName;

    ref.result.then(
      () => {
        this.gridService.selectGroup(null);
        this.listService.reload();
        this.navigationService.updateIndicators();
      },
      () => null,
    );
  }

  /**
   * Clear all actions.
   *
   * @private
   */
  private clearActionPanel() {
    this.actionPanelService.set(
      this.actionPanelService.actions.filter(
        (action) => action.name !== 'setState',
      ),
    );
  }

  /**
   * Get state id for selected entities. Null if selected entity have different states.
   *
   * @private
   */
  private getStateId(): Observable<string> {
    if (this.gridService.isAllSelected) {
      return this.data
        .collection(this.list.dataCollection)
        .query<any[]>({
          ...this.massOperationParameters.queryData,
          filter: [
            ...this.filterService.getODataFilter(),
            ...this.listService.contextFilter,
          ],
        })
        .pipe(
          map((entities) =>
            uniq(
              entities.map((entity) =>
                this.massOperationParameters.entityPropertyName
                  ? entity[this.massOperationParameters.entityPropertyName]
                      .stateId
                  : entity.stateId,
              ),
            ),
          ),
          map((stateIds) => (stateIds.length !== 1 ? null : stateIds[0])),
        );
    } else {
      const stateIds = uniq(
        this.gridService.selectedGroups$
          .getValue()
          .map((row) => row.getRawValue().state.id),
      );

      return of(stateIds.length !== 1 ? null : stateIds[0]);
    }
  }

  /**
   * Selection change  handler. Triggers on any select action.
   *
   * @private
   */
  private selectedChangedHandler() {
    this.lcInfoRequestingCancelled$.next();

    this.getStateId()
      .pipe(
        switchMap((stateId) => {
          if (stateId) {
            return this.data
              .collection(this.collection ?? this.list.dataCollection)
              .function('GetEntityTypeLifecycleInfo')
              .get<EntityTypeLifecycleInfo>({ stateId }, null);
          } else {
            return of(null);
          }
        }),
        takeUntil(this.lcInfoRequestingCancelled$),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((lcInfo) => {
        if (lcInfo && this.gridService.selectedGroupsValue.length) {
          this.setActions(lcInfo);
        } else {
          this.clearActionPanel();
        }
      });
  }
}
