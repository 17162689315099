import {
  ChangeDetectorRef,
  DestroyRef,
  inject,
  Inject,
  Injectable,
  Optional,
  ViewRef,
} from '@angular/core';
import { ListService } from 'src/app/shared/services/list.service';
import {
  FILTER,
  MASS_OPERATION_PARAMETERS,
  LIST,
  VIEW_NAME,
  ENTITY_COLLECTION,
} from 'src/app/shared/tokens';
import { DataColumn, List } from 'src/app/shared/models/inner/list';
import { UserViewColumn } from 'src/app/shared/models/inner/user-view';
import { Order } from 'src/app/shared/models/inner/order';
import { firstValueFrom, map, Subject, Subscription, tap } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { GridColumn } from 'src/app/shared/models/inner/grid-column.interface';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { Exception } from 'src/app/shared/models/exception';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { ChromeService } from 'src/app/core/chrome.service';
import { TotalType } from 'src/app/shared/models/inner/total-type';
import { LogService } from 'src/app/core/log.service';
import { AppService } from 'src/app/core/app.service';
import {
  EntityFilter,
  NavigationService,
} from 'src/app/core/navigation.service';
import { GridService } from 'src/app/shared-features/grid2/core/grid.service';
import { Command } from 'src/app/shared-features/grid2/models/grid-options.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';
import * as _ from 'lodash';
import { MassOperationComponent } from 'src/app/shared/components/mass-operation/mass-operation.component';

/** Entity list management service. */
@Injectable()
export class EntityListService {
  public pageSize = 50;
  public contextFilter: any[] = [];
  public rowCommands: Command[];
  public formArray: UntypedFormArray = this.fb.array([]);

  public totalsSubject = new Subject<Dictionary<string>>();
  public totals$ = this.totalsSubject.asObservable();
  public pageLoaded$ = new Subject<any[]>();

  private loading = false;
  private loadedAll = false;
  private currentPage = 0;

  private requestPageListener: Subscription;
  private requestTotalListener: Subscription;
  private scrollListener: Subscription;

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject(VIEW_NAME) protected viewName: string,
    @Optional() @Inject(FILTER) protected entityFilter: EntityFilter,
    @Inject(LIST) private list: List,
    @Optional()
    @Inject(MASS_OPERATION_PARAMETERS)
    private massOperationParameters: MassOperationParameters,
    @Optional()
    @Inject(ENTITY_COLLECTION)
    private collection: string,
    private chrome: ChromeService,
    private fb: UntypedFormBuilder,
    private gridService: GridService,
    private listService: ListService,
    private filterService: FilterService,
    private data: DataService,
    private log: LogService,
    private messageService: MessageService,
    private notification: NotificationService,
    private app: AppService,
    private navigationService: NavigationService,
    private actionPanelService: ActionPanelService,
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
  ) {
    this.applyFilter();

    filterService.values$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
    let order = this.listService.getUserView().order;
    if (!order) {
      order = <Order>{};
    }
    this.gridService.order$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((o: Order) => this.orderChanged(o));
    this.gridService.setOrder(order);
    this.gridService.setReadonlyState(true);
  }

  /** Applies filter for current view. */
  public applyFilter(): void {
    let filter = this.list.views.find(
      (v) => v.name === this.viewName,
    )?.contextFilter;

    if (this.entityFilter) {
      this.contextFilter = this.contextFilter.concat(this.entityFilter.filter);
    }

    if (!filter) {
      return;
    }

    // Apply tokens.
    filter = JSON.parse(
      JSON.stringify(filter).replaceAll('#user', this.app.session.user.id),
    );

    this.contextFilter = this.contextFilter.concat(filter);
  }

  /**
   * Updates the order of the user view and reloads the list.
   *
   * @param order The new order to be applied.
   */
  public orderChanged(order: Order): void {
    const userView = this.listService.getUserView();
    userView.order = order;
    this.listService.saveUserView(userView);
    this.reload();
  }

  /**
   * Reloads the list by clearing the form array, resetting the totals,
   * and reloading the first page of data.
   */
  public reload(): void {
    this.log.debug(`Reload list.`);
    this.formArray.clear();
    this.totalsSubject.next(null);
    this.loadedAll = false;
    this.currentPage = 0;
    this.loadPage();
    this.loadTotals();
  }

  /**
   * Initiates the deletion process for selected entities.
   *
   * This method determines whether to delete a single entity or multiple entities
   * based on the number of selected groups in the grid.
   */
  public delete: () => void = () => {
    if (this.gridService.selectedGroupsValue.length === 1) {
      this.deleteSingle();
    } else {
      this.deleteMany();
    }
  };

  /**
   * Deletes the selected entity from the collection.
   * Prompts the user for confirmation before deletion.
   * On successful deletion, reloads the list and updates navigation indicators.
   */
  private deleteSingle: () => void = () => {
    this.messageService.confirmLocal('shared.deleteConfirmation').then(
      () => {
        this.data
          .collection(this.collection ?? this.list.dataCollection)
          .entity(this.gridService.selectedGroupValue.id)
          .delete()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: () => {
              this.notification.successLocal('shared.deleteCompleted');
              this.reload();
              this.navigationService.updateIndicators();
            },
            error: (error: Exception) => {
              this.notification.error(error.message);
            },
          });
      },
      () => null,
    );
  };

  /**
   * Handles the deletion of multiple entities.
   *
   * This method performs the following steps:
   * 1. Retrieves the delete action button from the action panel service.
   * 2. Filters and maps the selected groups to get the IDs of non-protected entities.
   * 3. Applies additional filtering based on entity state if necessary.
   * 4. If all items are selected, fetches all entity IDs matching the current filter.
   * 5. Opens a modal dialog for mass deletion confirmation.
   * 6. If confirmed, resets selection, reloads the list, and updates navigation indicators.
   *
   * @returns A promise that is resolved when the deletion process is complete.
   */
  private deleteMany = async (): Promise<void> => {
    const actionButton = this.actionPanelService.action('delete');

    const items: any[] = this.gridService.selectedGroupsValue.filter(
      (row) => !row.state?.isEntityProtected,
    );
    let ids = items.map((r) => r.id);

    const filter = this.filterService.getODataFilter();
    if (this.gridService.selectedGroupsValue.some((g) => g.state)) {
      const nestedEntityName = this.massOperationParameters?.entityPropertyName;
      const filterByState = {};
      if (nestedEntityName) {
        filterByState[nestedEntityName] = {
          state: { isEntityProtected: false },
        };
      } else {
        filterByState['state'] = { isEntityProtected: false };
      }

      filter.push(filterByState);
    }

    const query = {
      select: ['id'],
    };

    if (this.gridService.isAllSelected) {
      actionButton.isBusy = true;
      ids = await firstValueFrom(
        this.data
          .collection(this.list.dataCollection)
          .query<any[]>({
            ...this.massOperationParameters.queryData,
            ...query,
            filter: [...filter, ...this.contextFilter],
          })
          .pipe(
            tap((entities) => entities.forEach((el) => items.push(el))),
            map((entities) =>
              _.uniq(
                entities.map((entity) =>
                  this.massOperationParameters.entityPropertyName
                    ? entity[this.massOperationParameters.entityPropertyName].id
                    : entity.id,
                ),
              ),
            ),
          ),
      );
      actionButton.isBusy = false;
    }

    const ref = this.modalService.open(MassOperationComponent);
    const instance = ref.componentInstance as MassOperationComponent;

    instance.massOperationType = 'delete';
    instance.entityIds = ids;
    instance.items = _.uniqBy(items, 'id');
    instance.collection = this.collection ?? this.list.dataCollection;
    instance.state = this.massOperationParameters.state;
    instance.entityPropertyName =
      this.massOperationParameters.entityPropertyName;

    ref.result.then(
      () => {
        this.gridService.selectGroup(null);
        this.reload();
        this.navigationService.updateIndicators();
      },
      () => null,
    );
  };

  /** Loads a next page of data for the entity list. */
  public loadPage(): void {
    if (this.loadedAll || this.loading) {
      return;
    }

    this.loading = true;
    this.gridService.setLoadingState(true);

    if (this.requestPageListener) {
      this.requestPageListener.unsubscribe();
    }

    this.log.debug(`Load page ${this.currentPage}.`);
    const dataQuery: any = this.listService.getODataQuery(
      this.currentPage,
      this.pageSize,
      this.contextFilter,
    );

    if ((this.cdr as ViewRef).destroyed) {
      return;
    }

    this.requestPageListener = this.data
      .collection(this.list.dataCollection)
      .query<object[]>(dataQuery, this.filterService.getDatePeriodUrlParams())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data: object[]) => {
          this.loadedAll = data.length < this.pageSize;
          if (this.loadedAll) {
            this.scrollListener?.unsubscribe();
          }
          this.pageLoaded$.next(data);
          this.currentPage++;
          this.loading = false;
          this.gridService.setLoadingState(false);
          data.forEach((row: any) => {
            this.formArray.push(this.listService.getFormGroupForRow(row));
          });
          this.gridService.detectChanges();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.gridService.setLoadingState(false);
          this.loading = false;
        },
      });
  }

  /**
   * Loads the totals for the entity list based on the current filters and context.
   * This method aggregates the data according to the user view columns that have totals enabled.
   */
  public loadTotals(): void {
    const transform: any = {
      filter: this.filterService.getODataFilter(),
      aggregate: <any>{},
    };

    const userView = this.listService.getUserView();

    const keys: Dictionary<string> = {};

    userView.columns.forEach((viewColumn: UserViewColumn, index: number) => {
      if (viewColumn.total) {
        const column = this.list.columns.find(
          (c: GridColumn) => c.name === viewColumn.column,
        );
        const dataColumn = this.list.dataColumns.find(
          (c: DataColumn) => c.column === column.name,
        );

        let dataField = Array.isArray(dataColumn.field)
          ? dataColumn.field[0]
          : dataColumn.field;
        dataField = dataField.replace('.', '/');

        if (viewColumn.total === TotalType.Count) {
          dataField = 'id';
        }

        const asKey = 'f' + index;
        keys[asKey] = column.name;
        transform.aggregate[dataField] = {
          with: viewColumn.total,
          as: asKey,
        };
      }
    });

    if (Object.keys(transform.aggregate).length === 0) {
      return;
    }

    if (this.contextFilter) {
      if (transform.filter) {
        transform.filter.push(this.contextFilter);
      } else {
        transform.filter = [this.contextFilter];
      }
    }

    const dataQuery: any = { transform };

    if (this.requestTotalListener) {
      this.requestTotalListener.unsubscribe();
    }

    let urlParams: Dictionary<string> =
      this.filterService.getDatePeriodUrlParams();
    if (!urlParams) {
      urlParams = {};
    }

    if ((this.cdr as ViewRef).destroyed) {
      return;
    }

    this.requestTotalListener = this.data
      .collection(this.list.dataCollection)
      .query<Dictionary<string>[]>(dataQuery, urlParams)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (totals) => {
          let totalsResponse: Dictionary<string> = null;

          if (totals.length > 0) {
            totalsResponse = {};
            for (const key of Object.keys(keys)) {
              totalsResponse[keys[key]] = totals[0][key];
            }
          }

          this.totalsSubject.next(totalsResponse);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.totalsSubject.next(null);
        },
      });
  }

  /** Enables lazy-loading on scroll event. */
  public enableInfinityScroll(): void {
    this.scrollListener = this.chrome.setInfinityScroll(() => this.loadPage());
  }

  /** Unsubscribes from the scroll listener. */
  public dispose(): void {
    this.scrollListener?.unsubscribe();
  }
}
