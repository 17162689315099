import { DateTime } from 'luxon';
import {
  ProjectTask,
  ProjectTaskDependencyType,
} from 'src/app/shared/models/entities/projects/project-task.model';

/** Uses for check circular dependency in the project tasks and task dependency chain search. */
export class TimelineGraph {
  /** All graph nodes and outgoing edges. Each node determines start or finish one of task. Each edge determines dependency. */
  private graph = new Map<string, GraphNodeInfo>();

  /** Represents  opposite dependency between first/last child nodes and its parent task nodes in strict tasks structure. */
  private coupledNodes = new Map<string, string>();

  private mainTaskId: string;
  private tasks: ProjectTask[];

  constructor(tasks: ProjectTask[]) {
    this.mainTaskId = tasks.find((t) => !t.leadTaskId).id;
    this.tasks = structuredClone(tasks);

    this.initGraph();

    this.initCoupledNodes();
  }

  /**
   * Checks if a path exists between two nodes in the graph.
   *
   * @param beginNode - Beginning node.
   * @param endNode - Ending node.
   * @param mustIncludedNodes - An array of nodes that must be included in the path.
   * @returns True if a path exists, false otherwise.
   */
  public checkIfPathExists(
    beginNode: GraphNode,
    endNode: GraphNode,
    mustIncludedNodes?: GraphNode[],
  ): boolean {
    if (beginNode.id === endNode.id) return true;

    // Prevent dependencies with main task
    if ([beginNode, endNode].some((n) => n.id === this.mainTaskId)) {
      return true;
    }

    const beginNodeKey = this.getGraphKey(beginNode);
    const endNodeKey = this.getGraphKey(endNode);

    let pathExist = false;
    // Map is used to mark the current node as visited
    const visited = new Map();
    const stack = [];

    // Push the current node to the stack which stores the result
    stack.push(beginNodeKey);

    // Traverse through the nodes until the stack is empty
    while (stack.length > 0 && (!pathExist || mustIncludedNodes)) {
      const node = stack.pop();

      // Mark the task as visited
      visited.set(node, true);

      // Recur for all the nodes adjacent (by dependency) to this tasks
      this.graph.get(node).dependencies.forEach((dep) => {
        // Break the cycle if circularity already found
        if (pathExist && !mustIncludedNodes) {
          return;
        }
        // Path exist check
        if (dep.node === endNodeKey) {
          pathExist = true;
          return;
        }
        // If the node are not visited
        if (!visited.get(dep.node)) {
          // Push the current node to the stack which stores the result
          stack.push(dep.node);
        }
      });
      //Add coupled node if exists
      const coupledNode = this.coupledNodes.get(node);
      if (coupledNode) {
        if (coupledNode === endNodeKey) {
          pathExist = true;
        } else {
          stack.push(coupledNode);
        }
      }
    }

    return (
      pathExist &&
      (!mustIncludedNodes ||
        mustIncludedNodes.every((node) => visited.has(this.getGraphKey(node))))
    );
  }

  //TODO: for new algorithm sandbox
  public updateTask(taskId: string, fieldsToUpdate: Partial<ProjectTask>) {
    const updatedTasks = { projectTask: this.tasks };
    const targetTask = this.tasks.find((t) => t.id === taskId);

    if (fieldsToUpdate.endDate) {
      const targetTaskEndDate = DateTime.fromISO(targetTask.endDate);
      const targetTaskNewEndDate = DateTime.fromISO(fieldsToUpdate.endDate);
      const offset = targetTaskNewEndDate.diff(targetTaskEndDate, 'days').days;
      this.moveNode('finish-' + taskId, offset, false);
    }
    if (fieldsToUpdate.startDate) {
      const targetTaskStartDate = DateTime.fromISO(targetTask.startDate);
      const targetTaskNewStartDate = DateTime.fromISO(fieldsToUpdate.startDate);
      const offset = targetTaskNewStartDate.diff(
        targetTaskStartDate,
        'days',
      ).days;
      this.moveNode('start-' + taskId, offset, !!fieldsToUpdate.duration);
    }

    return updatedTasks;
  }

  private moveNode(nodeName: string, offset: number, preserveDuration = true) {
    if (!offset) return;

    const nodeInfo = this.graph.get(nodeName);
    if (nodeInfo.nodeType === 'finish' && preserveDuration) {
      this.moveNode('start-' + nodeInfo.task.id, offset, true);
      return;
    }

    const targetNodeDateField =
      nodeInfo.nodeType === 'finish' ? 'endDate' : 'startDate';
    const nodeTaskDate = DateTime.fromISO(nodeInfo.task[targetNodeDateField]);
    // Move target node date with offset

    nodeInfo.task[targetNodeDateField] = nodeTaskDate
      .plus({ days: offset })
      .toISODate();

    if (preserveDuration && nodeInfo.nodeType === 'start') {
      this.moveNode('finish-' + nodeInfo.task.id, offset, false);
    }

    nodeInfo.dependencies
      .filter((d) => this.graph.get(d.node).task.id !== nodeInfo.task.id)
      .forEach((d) => {
        const dependentNode = this.graph.get(d.node);
        const dateField =
          dependentNode.nodeType === 'finish' ? 'endDate' : 'startDate';

        const dependentNodeTaskDate = DateTime.fromISO(
          dependentNode.task[dateField],
        );
        let diff = dependentNodeTaskDate.diff(
          DateTime.fromISO(nodeInfo.task[targetNodeDateField]),
          'days',
        ).days;
        const isDependencyViolation = diff < d.min;
        if (isDependencyViolation) {
          diff = d.min - diff;
          d.offset = d.min;
          this.moveNode(d.node, diff, !d.sticky);
        } else {
          d.offset = diff;
        }
      });
  }

  private initGraph(): void {
    const getTask = (id: string) => this.tasks.find((t) => t.id === id);
    this.tasks.forEach((task) => {
      const startNode: GraphNode = { id: task.id, type: 'start' };
      const finishNode: GraphNode = { id: task.id, type: 'finish' };

      const startNodeDeps = [];
      const finishNodeDeps = [];

      const childrenTasks = this.tasks.filter((t) => t.leadTaskId === task.id);
      //SS dependency to each child task
      if (childrenTasks.length) {
        childrenTasks.forEach((t) => {
          const childTask = getTask(t.id);
          const childTaskDate = DateTime.fromISO(t.startDate);
          const predecessorDate = DateTime.fromISO(task.startDate);
          const dep = {
            node: 'start-' + t.id,
            min: 0,
            predecessorDate,
            offset: childTaskDate.diff(predecessorDate, 'days').days,
            dependentTask: childTask,
            sticky: true,
          };
          startNodeDeps.push(dep);
        });
      }

      //FF dependency to lead task
      if (task.leadTaskId) {
        const leadTask = getTask(task.leadTaskId);
        const leadTaskDate = DateTime.fromISO(leadTask.endDate);
        const predecessorDate = DateTime.fromISO(task.endDate);
        const dep = {
          node: 'finish-' + task.leadTaskId,
          min: 0,
          predecessorDate,
          offset: leadTaskDate.diff(predecessorDate, 'days').days,
          dependentTask: leadTask,
          sticky: true,
        };
        finishNodeDeps.push(dep);
      }

      //FS dependency to self finish date
      startNodeDeps.unshift({
        node: 'finish-' + task.id,
        min: 0,
        predecessorDate: DateTime.fromISO(task.startDate),
        offset: DateTime.fromISO(task.endDate).diff(
          DateTime.fromISO(task.startDate),
          'days',
        ).days,
        dependentTask: task,
      });

      const dependentTasks = this.tasks.filter((t) =>
        t.dependencies?.some((d) => d.predecessorId === task.id),
      );
      dependentTasks.forEach((dependentTask) => {
        const predecessorDate = DateTime.fromISO(task.endDate);
        dependentTask.dependencies.forEach((dep) => {
          if (dep.predecessorId !== task.id) return;

          switch (dep.type) {
            case ProjectTaskDependencyType.finishToStart: {
              const graphDep = {
                node: 'start-' + dependentTask.id,
                min: 1,
                predecessorDate,
                offset: DateTime.fromISO(dependentTask.startDate).diff(
                  predecessorDate,
                  'days',
                ).days,
                dependentTask,
              };
              finishNodeDeps.push(graphDep);
              break;
            }
            case ProjectTaskDependencyType.startToStart: {
              const graphDep = {
                node: 'start-' + dependentTask.id,
                min: 0,
                predecessorDate,
                offset: DateTime.fromISO(dependentTask.startDate).diff(
                  predecessorDate,
                  'days',
                ).days,
                dependentTask,
              };
              startNodeDeps.push(graphDep);
              break;
            }

            case ProjectTaskDependencyType.finishToFinish: {
              const graphDep = {
                node: 'finish-' + dependentTask.id,
                min: 0,
                predecessorDate,
                offset: DateTime.fromISO(dependentTask.endDate).diff(
                  predecessorDate,
                  'days',
                ).days,
                dependentTask,
              };
              finishNodeDeps.push(graphDep);
              break;
            }
            case ProjectTaskDependencyType.startToFinish: {
              const graphDep = {
                node: 'finish-' + dependentTask.id,
                min: 0,
                predecessorDate,
                offset: DateTime.fromISO(dependentTask.endDate).diff(
                  predecessorDate,
                  'days',
                ).days,
                dependentTask,
              };
              startNodeDeps.push(graphDep);
              break;
            }
          }
        });
      });

      const startNodeInfo = {
        task,
        nodeType: 'start' as GraphNodeType,
        dependencies: startNodeDeps,
      };
      const finishNodeInfo = {
        task,
        nodeType: 'finish' as GraphNodeType,
        dependencies: finishNodeDeps,
      };

      this.graph.set(this.getGraphKey(startNode), startNodeInfo);
      this.graph.set(this.getGraphKey(finishNode), finishNodeInfo);
    });
  }

  /**
   * Initializes the coupled nodes based on the given tasks.
   *
   * This method iterates through the tasks to identify lead tasks and their children. It then finds the earliest and latest
   * start and finish dates among the children tasks and attempts to find a path between the earliest start and latest finish
   * dates. If a path is found, it sets the coupled nodes for the lead task's start and finish nodes to the earliest start
   * and latest finish nodes respectively.
   *
   * @param tasks - The array of ProjectTask objects to process.
   */
  private initCoupledNodes() {
    const handledTaskId = new Set<string>();
    this.tasks.forEach((task) => {
      if (handledTaskId.has(task.id)) return;

      if (task.leadTaskId && task.leadTaskId !== this.mainTaskId) {
        const childrenTasks = this.tasks.filter(
          (t) => t.leadTaskId === task.leadTaskId,
        );
        const sortedDates = childrenTasks
          .flatMap((t) => [t.startDate, t.endDate])
          .sort();
        const earlierTasks = childrenTasks.filter(
          (t) => t.startDate === sortedDates[0],
        );
        const laterTasks = childrenTasks.filter(
          (t) => t.startDate === sortedDates[sortedDates.length - 1],
        );

        //Search connected earliest and latest nodes
        let earliestNode: GraphNode;
        let latestNode: GraphNode;
        const childrenTaskNodes = childrenTasks.flatMap((t) => [
          {
            id: t.id,
            type: 'finish',
          },
        ]);
        laterTasks.forEach((laterTask) => {
          earlierTasks.forEach((earlierTask) => {
            const earlierNode = {
              id: earlierTask.id,
              type: 'start',
            } as GraphNode;
            const laterNode = {
              id: laterTask.id,
              type: 'finish',
            } as GraphNode;
            if (
              this.checkIfPathExists(
                earlierNode,
                laterNode,
                childrenTaskNodes as GraphNode[],
              )
            ) {
              earliestNode = earlierNode;
              latestNode = laterNode;
            }
          });
        });

        if (earliestNode && latestNode) {
          const [leadStartNode, leadFinishNode] = this.getNodeNamePair(
            task.leadTaskId,
          );
          this.coupledNodes.set(this.getGraphKey(earliestNode), leadStartNode);
          this.coupledNodes.set(leadFinishNode, this.getGraphKey(latestNode));
        }

        childrenTasks.forEach((t) => handledTaskId.add(t.id));
      }
    });
  }

  /**
   * Generates a pair of node names for a given task ID, one for the start and one for the finish.
   *
   * @param taskId - The ID of the task for which to generate the node names.
   * @returns An array containing two strings: 'start-' + taskId and 'finish-' + taskId.
   */
  private getNodeNamePair(taskId: string): [string, string] {
    return ['start-' + taskId, 'finish-' + taskId];
  }

  /**
   * Generates a unique key for a given GraphNode.
   *
   * This method combines the type and id of a GraphNode to create a unique string identifier.
   *
   * @param node - The GraphNode for which to generate the key.
   * @returns A string representing the unique key for the given GraphNode.
   */
  private getGraphKey(node: GraphNode): string {
    return `${node.type}-${node.id}`;
  }
}

export interface GraphNode {
  id: string;
  type: GraphNodeType;
}

export interface GraphNodeInfo {
  nodeType: GraphNodeType;
  task: ProjectTask;
  dependencies: GraphDependency[];
}

export interface GraphDependency {
  node: string; //GraphNode.type + GraphNode.id
  min: number;
  offset: number;
  //Determines sticky dependency between boundary children and lead task. It means that parent task must fit to their children tasks.
  sticky?: boolean;
}

export type GraphNodeType = 'start' | 'finish';
