import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import {
  GridNavigationColumn,
  GridColumnType,
} from '../models/inner/grid-column.interface';
import { NavigationType } from 'src/app/shared-features/grid2/models/grid-column.interface';

const USER_LIST_VIEW_COLUMNS = [
  {
    column: 'name',
    width: 2,
    visibleByDefault: true,
    totalByDefault: TotalType.Count,
  },
  {
    column: 'code',
    width: 1,
    visibleByDefault: false,
  },
  {
    column: 'department',
    width: 2,
    visibleByDefault: true,
  },
  {
    column: 'resourcePool',
    width: 2,
    visibleByDefault: false,
  },
  {
    column: 'legalEntity',
    width: 1,
    visibleByDefault: false,
  },
  {
    column: 'supervisor',
    width: 2,
    visibleByDefault: false,
  },
  {
    column: 'email',
    width: 1,
    visibleByDefault: true,
  },
  {
    column: 'phone',
    width: 1,
    visibleByDefault: false,
  },
  {
    column: 'level',
    width: 1,
    visibleByDefault: true,
  },
  {
    column: 'role',
    width: 1,
    visibleByDefault: false,
  },
  {
    column: 'competence',
    width: 1,
    visibleByDefault: false,
  },
  {
    column: 'location',
    width: 1,
    visibleByDefault: true,
  },
  {
    column: 'position',
    width: 1,
    visibleByDefault: true,
  },
  {
    column: 'timeSheetTemplate',
    width: 1,
    visibleByDefault: false,
  },
  {
    column: 'costNormalizationRule',
    width: 1,
    visibleByDefault: false,
  },
  {
    column: 'firstWorkDay',
    width: 110,
    visibleByDefault: false,
  },
  {
    column: 'lastWorkDay',
    width: 110,
    visibleByDefault: false,
  },
  {
    column: 'created',
    width: 150,
    visibleByDefault: false,
  },
  {
    column: 'modified',
    width: 150,
    visibleByDefault: false,
  },
  {
    column: 'isActive',
    width: 135,
    visibleByDefault: true,
  },
  {
    column: 'authProvider',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'createdBy',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'modifiedBy',
    visibleByDefault: false,
    width: 1,
  },
];

export const USER_LIST: List = {
  version: 8,
  name: 'users',
  clrType: 'User',
  views: [
    {
      name: 'default',
      columns: USER_LIST_VIEW_COLUMNS,
      order: { column: 'name', reverse: false },
    },
    {
      name: 'inGroup',
      columns: USER_LIST_VIEW_COLUMNS,
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    {
      name: 'name',
      type: GridColumnType.ResourceNavigation,
      availableTotals: [TotalType.Count],
      state: 'settings.user',
      header: 'settings.users.list.columns.name',
      hint: 'settings.users.list.columns.name',
      navigationType: NavigationType.User,
    } as GridNavigationColumn,

    {
      name: 'code',
      header: 'shared.columns.code',
      hint: 'shared.columns.code',
      type: GridColumnType.String,
    },
    {
      name: 'department',
      header: 'shared2.props.department',
      hint: 'shared2.props.department',
      type: GridColumnType.String,
    },
    {
      name: 'resourcePool',
      header: 'shared2.props.resourcePool',
      hint: 'shared2.props.resourcePool',
      type: GridColumnType.String,
    },
    {
      name: 'legalEntity',
      header: 'shared2.props.legalEntity',
      hint: 'shared2.props.legalEntity',
      type: GridColumnType.String,
    },
    {
      name: 'costNormalizationRule',
      header: 'settings.users.list.columns.costNormalizationRule',
      hint: 'settings.users.list.columns.costNormalizationRule',
      type: GridColumnType.String,
    },
    {
      name: 'supervisor',
      header: 'shared2.props.supervisor',
      hint: 'shared2.props.supervisor',
      type: GridColumnType.String,
    },
    {
      name: 'level',
      header: 'shared2.props.level',
      hint: 'shared2.props.level',
      type: GridColumnType.String,
    },
    {
      name: 'role',
      header: 'shared2.props.role',
      hint: 'shared2.props.role',
      type: GridColumnType.String,
    },
    {
      name: 'competence',
      header: 'shared2.props.competence',
      hint: 'shared2.props.competence',
      type: GridColumnType.String,
    },
    {
      name: 'location',
      header: 'shared2.props.location',
      hint: 'shared2.props.location',
      type: GridColumnType.String,
    },
    {
      name: 'email',
      header: 'settings.users.list.columns.email',
      hint: 'settings.users.list.columns.email',
      type: GridColumnType.String,
    },
    {
      name: 'phone',
      header: 'settings.users.list.columns.phone',
      hint: 'settings.users.list.columns.phone',
      type: GridColumnType.String,
    },
    {
      name: 'position',
      header: 'settings.users.list.columns.position',
      hint: 'settings.users.list.columns.position',
      type: GridColumnType.String,
    },
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      fixedWidth: true,
      type: GridColumnType.DateTime,
    },
    {
      name: 'modified',
      header: 'shared.columns.modified.title',
      hint: 'shared.columns.modified.hint',
      fixedWidth: true,
      type: GridColumnType.DateTime,
    },
    {
      name: 'firstWorkDay',
      fixedWidth: true,
      header: 'settings.users.list.columns.firstWorkDay',
      hint: 'settings.users.list.columns.firstWorkDay',
      type: GridColumnType.Date,
    },
    {
      name: 'lastWorkDay',
      fixedWidth: true,
      header: 'settings.users.list.columns.lastWorkDay',
      hint: 'settings.users.list.columns.lastWorkDay',
      type: GridColumnType.Date,
    },
    {
      name: 'timeSheetTemplate',
      header: 'settings.users.list.columns.timeSheetTemplate',
      hint: 'settings.users.list.columns.timeSheetTemplate',
      type: GridColumnType.String,
    },

    {
      name: 'isActive',
      fixedWidth: true,
      type: GridColumnType.Boolean,
      header: 'settings.users.list.columns.isActive',
      hint: 'settings.users.list.columns.isActive',
    },
    {
      name: 'authProvider',
      header: 'settings.users.list.columns.authProvider',
      hint: 'settings.users.list.columns.authProvider',
      type: GridColumnType.String,
    },
    {
      name: 'createdBy',
      header: 'shared.columns.createdBy.title',
      hint: 'shared.columns.createdBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'modifiedBy',
      header: 'shared.columns.modifiedBy.title',
      hint: 'shared.columns.modifiedBy.hint',
      type: GridColumnType.String,
    },
  ],
  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'code',
      field: 'code',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modified',
      field: 'modified',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'position',
      field: 'position',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'location',
      field: 'location.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'level',
      field: 'level.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'role',
      field: 'role.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'competence',
      field: 'competence.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'phone',
      field: 'phone',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'email',
      field: 'email',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'supervisor',
      field: 'supervisor.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'costNormalizationRule',
      field: 'costNormalizationRule.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'resourcePool',
      field: 'resourcePool.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'legalEntity',
      field: 'legalEntity.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'department',
      field: 'department.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'lastWorkDay',
      field: 'lastWorkDay',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'firstWorkDay',
      field: 'firstWorkDay',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'timeSheetTemplate',
      field: 'timeSheetTemplate.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'authProvider',
      field: 'authProvider.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'createdBy',
      field: 'createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modifiedBy',
      field: 'modifiedBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
  dataCollection: 'Users',
};
