import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { CrossIdEntity } from 'src/app/shared/models/entities/cross-id-entity.interface';
import { EntityState } from 'src/app/shared/models/entities/entity.model';

/** Project task. */
export class ProjectTask extends NamedEntity implements CrossIdEntity {
  crossId: string;

  /** Project id. */
  projectId?: string;

  /** Project version id. */
  versionId?: string;

  /** Branch order number (0, 1 ...). */
  number: number;

  /** Structure number (1.1.1, 1.1.2 ...) */
  structNumber: string;

  /** Allow time to be charged to a task. */
  allowTimeEntry: boolean;

  leadTaskId: string;

  /** Work duration. Count of work days in the task. */
  duration?: number;

  /** Percent of complication of the task. */
  percentComplete?: number;

  /** Task start date. */
  startDate: string;

  /** Task end date. */
  endDate: string;

  /** Task assignments. */
  projectTaskAssignments: any[];
  /** Field for indirect task assignments update.
   *  Should to rewrite to projectTaskAssignments at once. */
  updatedAssignments?: any[];

  /** Task dependencies. */
  dependencies: ProjectTaskDependency[];

  /** Description. */
  description: string;

  /** Actual labor costs. */
  actualHours?: number;

  /** Actual cost. */
  actualCost?: number;

  /** Planned labor costs (according to resource plan). */
  plannedHours?: number;

  /** Planned cost (according to resource plan). */
  plannedCost?: number;

  /** Estimated labor costs (according to resource plan). */
  estimatedHours?: number;

  /** Estimated cost (according to resource plan). */
  estimatedCost?: number;

  /** Collapse/expand task group flag in UI. */
  isExpanded?: boolean;

  /** Indent the task to the left in the UI. */
  indent: number;

  /** Client auxiliary properties. */
  assignmentsWereChanged?: boolean;

  /** Task planning type. */
  type: ProjectTaskType;

  /** Fixed amount of work. */
  effortDriven: boolean;

  /** Entity state after undo/redo. */
  entityState?: EntityState;

  /** Determines is task milestone. */
  isMilestone?: boolean;

  /** Master version task dates. */
  plannedStartDate?: string;
  plannedEndDate?: string;

  /** Customizable properties. */
  stringValue1?: string;
  stringValue2?: string;
  stringValue3?: string;
  stringValue4?: string;
  stringValue5?: string;

  decimalValue1?: number;
  decimalValue2?: number;
  decimalValue3?: number;
  decimalValue4?: number;
  decimalValue5?: number;

  dateValue1?: string;
  dateValue2?: string;
  dateValue3?: string;
  dateValue4?: string;
  dateValue5?: string;

  lookupValue1?: NamedEntity;
  lookupValue2?: NamedEntity;
  lookupValue3?: NamedEntity;
  lookupValue4?: NamedEntity;
  lookupValue5?: NamedEntity;

  integerValue1?: number;
  integerValue2?: number;
  integerValue3?: number;
  integerValue4?: number;
  integerValue5?: number;
}

/** Dependency between tasks. */
export class ProjectTaskDependency {
  predecessorId: string;
  type: ProjectTaskDependencyType;
}

/** Type of dependency between tasks. */
export enum ProjectTaskDependencyType {
  finishToStart = 'FinishToStart',
  finishToFinish = 'FinishToFinish',
  startToStart = 'StartToStart',
  startToFinish = 'StartToFinish',
}

export const AVAILABLE_DEPENDENCY_TYPES = [
  ProjectTaskDependencyType.finishToStart,
  ProjectTaskDependencyType.finishToFinish,
  ProjectTaskDependencyType.startToStart,
  ProjectTaskDependencyType.startToFinish,
];

export const projectTaskWarnings = [
  'dependentTasksWereChanged',
  'startDateLimitByPredecessor',
  'startDateShiftByNonWorkingDay',
  'endDateEarlierThanStartDate',
  'endDateShiftByNonWorkingDay',
];

/** Task planning types. */
export enum ProjectTaskType {
  fixedUnits = 'FixedUnits',
  fixedDuration = 'FixedDuration',
  fixedWork = 'FixedWork',
}

export const PROJECT_TASK_TYPES = [
  ProjectTaskType.fixedDuration,
  ProjectTaskType.fixedWork,
  // ProjectTaskType.fixedUnits,
];
