import { DateTime } from 'luxon';
import {
  ProjectTask,
  ProjectTaskDependencyType,
} from 'src/app/shared/models/entities/projects/project-task.model';

/** Uses for check circular dependency in the project tasks and task dependency chain search. */
export class TimelineGraph {
  /** All graph nodes and incoming edges. Each node determines start or finish one of task. Each edge determines dependency. */
  private graph = new Map<string, GraphDependency[]>();

  /** Represents  opposite dependency between first/last child nodes and its parent task nodes in strict tasks structure. */
  private coupledNodes = new Map<string, string>();

  private mainTaskId: string;

  constructor(tasks: ProjectTask[]) {
    const getTask = (id: string) => tasks.find((t) => t.id === id);
    this.mainTaskId = tasks.find((t) => !t.leadTaskId).id;
    tasks.forEach((task) => {
      const startNode: GraphNode = { id: task.id, type: 'start' };
      const finishNode: GraphNode = { id: task.id, type: 'finish' };

      const startNodeDeps = [];

      //SS dependency from lead task
      if (task.leadTaskId) {
        const predecessorTask = getTask(task.leadTaskId);
        const taskDate = DateTime.fromISO(task.startDate);
        const predecessorDate = DateTime.fromISO(predecessorTask.startDate);
        const dep = {
          node: 'start-' + task.leadTaskId,
          min: 0,
          predecessorDate,
          offset: taskDate.diff(predecessorDate, 'days').days,
        };
        startNodeDeps.push(dep);
      }

      //FF dependencies from children tasks
      const finishNodeDeps = tasks
        .filter((t) => t.leadTaskId === task.id)
        .map((t) => {
          const taskDate = DateTime.fromISO(t.endDate);
          const predecessorDate = DateTime.fromISO(t.endDate);
          const dep = {
            node: 'finish-' + t.id,
            min: 0,
            predecessorDate,
            offset: taskDate.diff(predecessorDate, 'days').days,
          };
          return dep;
        });

      //FS dependency from self start date
      finishNodeDeps.unshift({
        node: 'start-' + task.id,
        min: 0,
        predecessorDate: DateTime.fromISO(task.startDate),
        offset: DateTime.fromISO(task.endDate).diff(
          DateTime.fromISO(task.startDate),
          'days',
        ).days,
      });

      task.dependencies?.forEach((dep) => {
        switch (dep.type) {
          case ProjectTaskDependencyType.finishToStart: {
            const predecessorTask = getTask(dep.predecessorId);
            const predecessorDate = DateTime.fromISO(predecessorTask.endDate);
            const graphDep = {
              node: 'finish-' + dep.predecessorId,
              min: 1,
              predecessorDate,
              offset: DateTime.fromISO(task.startDate).diff(
                predecessorDate,
                'days',
              ).days,
            };
            startNodeDeps.push(graphDep);
            break;
          }
          case ProjectTaskDependencyType.startToStart: {
            const predecessorTask = getTask(dep.predecessorId);
            const predecessorDate = DateTime.fromISO(predecessorTask.startDate);
            const graphDep = {
              node: 'start-' + dep.predecessorId,
              min: 0,
              predecessorDate,
              offset: DateTime.fromISO(task.startDate).diff(
                predecessorDate,
                'days',
              ).days,
            };
            startNodeDeps.push(graphDep);
            break;
          }

          case ProjectTaskDependencyType.finishToFinish: {
            const predecessorTask = getTask(dep.predecessorId);
            const predecessorDate = DateTime.fromISO(predecessorTask.endDate);
            const graphDep = {
              node: 'finish-' + dep.predecessorId,
              min: 0,
              predecessorDate,
              offset: DateTime.fromISO(task.endDate).diff(
                predecessorDate,
                'days',
              ).days,
            };
            startNodeDeps.push(graphDep);
            break;
          }
          case ProjectTaskDependencyType.startToFinish: {
            const predecessorTask = getTask(dep.predecessorId);
            const predecessorDate = DateTime.fromISO(predecessorTask.startDate);
            const graphDep = {
              node: 'start-' + dep.predecessorId,
              min: 0,
              predecessorDate,
              offset: DateTime.fromISO(task.endDate).diff(
                predecessorDate,
                'days',
              ).days,
            };
            startNodeDeps.push(graphDep);
            break;
          }
        }
      });

      this.graph.set(this.getGraphKey(startNode), startNodeDeps);
      this.graph.set(this.getGraphKey(finishNode), finishNodeDeps);
    });

    this.initCoupledNodes(tasks);
  }

  /**
   * Checks if a path exists between two nodes in the graph.
   *
   * @param beginNode - Beginning node.
   * @param endNode - Ending node.
   * @param mustIncludedNodes - An array of nodes that must be included in the path.
   * @returns True if a path exists, false otherwise.
   */
  public checkIfPathExists(
    beginNode: GraphNode,
    endNode: GraphNode,
    mustIncludedNodes?: GraphNode[],
  ): boolean {
    if (beginNode.id === endNode.id) return true;

    // Prevent dependencies with main task
    if ([beginNode, endNode].some((n) => n.id === this.mainTaskId)) {
      return true;
    }

    const beginNodeKey = this.getGraphKey(beginNode);
    const endNodeKey = this.getGraphKey(endNode);

    let pathExist = false;
    // Map is used to mark the current node as visited
    const visited = new Map();
    const stack = [];

    // Push the current node to the stack which stores the result
    stack.push(endNodeKey);

    // Traverse through the nodes until the stack is empty
    while (stack.length > 0 && (!pathExist || mustIncludedNodes)) {
      const node = stack.pop();

      // Mark the task as visited
      visited.set(node, true);

      // Recur for all the nodes adjacent (by dependency) to this tasks
      this.graph.get(node).forEach((dep) => {
        // Break the cycle if circularity already found
        if (pathExist && !mustIncludedNodes) {
          return;
        }
        // Path exist check
        if (dep.node === beginNodeKey) {
          pathExist = true;
          return;
        }
        // If the node are not visited
        if (!visited.get(dep.node)) {
          // Push the current node to the stack which stores the result
          stack.push(dep.node);
        }
      });
      //Add coupled node if exists
      const coupledNode = this.coupledNodes.get(node);
      if (coupledNode) {
        if (coupledNode === beginNodeKey) {
          pathExist = true;
        } else {
          stack.push(coupledNode);
        }
      }
    }

    return (
      pathExist &&
      (!mustIncludedNodes ||
        mustIncludedNodes.every((node) => visited.has(this.getGraphKey(node))))
    );
  }

  /**
   * Initializes the coupled nodes based on the given tasks.
   *
   * This method iterates through the tasks to identify lead tasks and their children. It then finds the earliest and latest
   * start and finish dates among the children tasks and attempts to find a path between the earliest start and latest finish
   * dates. If a path is found, it sets the coupled nodes for the lead task's start and finish nodes to the earliest start
   * and latest finish nodes respectively.
   *
   * @param tasks - The array of ProjectTask objects to process.
   */
  private initCoupledNodes(tasks: ProjectTask[]) {
    const handledTaskId = new Set<string>();
    tasks.forEach((task) => {
      if (handledTaskId.has(task.id)) return;
      if (task.leadTaskId && task.leadTaskId !== this.mainTaskId) {
        const childrenTasks = tasks.filter(
          (t) => t.leadTaskId === task.leadTaskId,
        );
        const sortedDates = childrenTasks
          .flatMap((t) => [t.startDate, t.endDate])
          .sort();
        const earlierTasks = childrenTasks.filter(
          (t) => t.startDate === sortedDates[0],
        );
        const laterTasks = childrenTasks.filter(
          (t) => t.startDate === sortedDates[sortedDates.length - 1],
        );

        //Search connected earliest and latest nodes
        let earliestNode: GraphNode;
        let latestNode: GraphNode;
        const childrenTaskNodes = childrenTasks.flatMap((t) => [
          {
            id: t.id,
            type: 'finish',
          },
        ]);
        laterTasks.forEach((laterTask) => {
          earlierTasks.forEach((earlierTask) => {
            const earlierNode = {
              id: earlierTask.id,
              type: 'start',
            } as GraphNode;
            const laterNode = {
              id: laterTask.id,
              type: 'finish',
            } as GraphNode;
            if (
              this.checkIfPathExists(
                earlierNode,
                laterNode,
                childrenTaskNodes as GraphNode[],
              )
            ) {
              earliestNode = earlierNode;
              latestNode = laterNode;
            }
          });
        });

        if (earliestNode && latestNode) {
          const [leadStartNode, leadFinishNode] = this.getNodeNamePair(
            task.leadTaskId,
          );
          this.coupledNodes.set(leadStartNode, this.getGraphKey(earliestNode));
          this.coupledNodes.set(this.getGraphKey(latestNode), leadFinishNode);
        }

        childrenTasks.forEach((t) => handledTaskId.add(t.id));
      }
    });
  }

  /**
   * Generates a pair of node names for a given task ID, one for the start and one for the finish.
   *
   * @param taskId - The ID of the task for which to generate the node names.
   * @returns An array containing two strings: 'start-' + taskId and 'finish-' + taskId.
   */
  private getNodeNamePair(taskId: string): [string, string] {
    return ['start-' + taskId, 'finish-' + taskId];
  }

  /**
   * Generates a unique key for a given GraphNode.
   *
   * This method combines the type and id of a GraphNode to create a unique string identifier.
   *
   * @param node - The GraphNode for which to generate the key.
   * @returns A string representing the unique key for the given GraphNode.
   */
  private getGraphKey(node: GraphNode): string {
    return `${node.type}-${node.id}`;
  }
}

export interface GraphNode {
  id: string;
  type: 'start' | 'finish';
}

export interface GraphDependency {
  node: string; //GraphNode.type + GraphNode.id
  min: number;
  predecessorDate: DateTime;
  offset: number;
}
