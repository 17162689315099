import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, switchMap } from 'rxjs';

import {
  DragAndDropData,
  DragAndDropOptions,
} from 'src/app/shared/directives/drag-and-drop/drag-and-drop.model';
import { DragDropService } from 'src/app/shared/services/drag-drop';

import {
  BoardCardView,
  BoardColumn,
  BoardEvent,
} from 'src/app/boards/models/board.interface';
import { BoardService } from 'src/app/boards/services/board.service';
import { DragAndDropDirective } from 'src/app/shared/directives/drag-and-drop/drag-and-drop.directive';

@Component({
  selector: 'tmt-board-track',
  templateUrl: './board-track.component.html',
  styleUrl: './board-track.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardTrackComponent implements OnInit {
  @ViewChild(DragAndDropDirective) private dndDirective: DragAndDropDirective;

  @Input() cards: BoardCardView[] = [];
  @Input() column: Partial<BoardColumn>;

  public isShowAlert = false;
  public ddOptions: DragAndDropOptions;
  public dragDisabled: boolean;
  public dropDisabled: boolean;

  private destroyRef = inject(DestroyRef);

  constructor(
    public boardService: BoardService,
    private dragDropService: DragDropService,
    private cdr: ChangeDetectorRef,
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.ddOptions = {
      group: {
        name: this.column.id,
        put: this.boardService.columnsDependencies[this.column.id],
        key: 'cards',
      },
      draggableClass: 'draggable',
      listDirection: 'vertical',
      listGap: 8,
    };

    this.dragDropService.onItemUpdate$
      .pipe(
        filter((v) => !!v && v.fromGroupName === this.column.id),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((data: DragAndDropData<BoardCardView>) => {
        this.boardService.updateCard(data.item.id, data);
      });

    this.boardService.event$
      .pipe(
        filter(
          (event) =>
            event.target === 'track' &&
            (event.id === this.column.id || !event.id),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event) => this.handleEvent(event));
  }

  private handleEvent(event: BoardEvent): void {
    switch (event.action) {
      case 'rollBackFrom':
      case 'moveThroughCardMenu':
      case 'rollBackTo':
        this.runAnimation(event);
        break;
      case 'disableDrag':
        this.dragDisabled = !!event.data;
        this.cdr.markForCheck();
        break;
      case 'updated':
        this.cdr.markForCheck();
        break;
      case 'restrictTransition':
        if (this.isShowAlert && !event.data) {
          // NOTE: In case when user dragged card before the alert was shown
          this.dndDirective.restoreTransform(null, null, false);
        }

        this.isShowAlert = event.data;
        this.dropDisabled = event.data;
        this.dragDisabled = event.data;
        this.cdr.markForCheck();
        break;
    }
  }

  private runAnimation(
    event: BoardEvent<DragAndDropData<BoardCardView>>,
  ): void {
    if (event.action === 'rollBackFrom') {
      const element = this.el.nativeElement
        .querySelectorAll(`.${this.ddOptions.draggableClass}`)
        .item(event.data.newIndex) as HTMLElement;

      this.dragDropService.data.clone.element =
        this.dndDirective.makeClone(element);

      this.dndDirective
        .destroyElement(element, event.data.newIndex)
        .pipe(
          switchMap(() => this.dndDirective.restoreTransform(0, null, true)),
        )
        .subscribe(() => {
          this.boardService.event$.next({
            target: 'track',
            id: null,
            action: 'disableDrag',
            data: false,
          });
          this.cdr.markForCheck();
        });
    }

    if (event.action === 'moveThroughCardMenu') {
      const element = this.el.nativeElement
        .querySelectorAll(`.${this.ddOptions.draggableClass}`)
        .item(event.data.oldIndex) as HTMLElement;

      this.dragDropService.data = {
        item: event.data.item,
        oldIndex: event.data.oldIndex,
        newIndex: event.data.newIndex,
        fromGroupName: event.id,
        toGroupName: event.data.toGroupName ?? event.id,
        fromGroupKey: 'cards',
        clone: {
          element: this.dndDirective.makeClone(element),
          offsetX: 0,
          offsetY: 0,
          lastDragX: 0,
          lastDragY: 0,
          oldPosition: {
            x:
              element.getBoundingClientRect().x -
              this.el.nativeElement.getBoundingClientRect().left,
            y:
              element.getBoundingClientRect().y -
              this.el.nativeElement.getBoundingClientRect().top,
          },
        },
      };

      this.renderer.setStyle(
        this.dragDropService.data.clone.element,
        'z-index',
        '0',
      );

      this.dndDirective.makePlaceholder();
      this.dndDirective
        .destroyElement(element, event.data.oldIndex)
        .subscribe(() => {
          this.dndDirective.restoreTransform(
            Math.min(event.data.oldIndex, event.data.newIndex),
            null,
            event.data.newIndex > event.data.oldIndex,
          );
          this.dragDropService.setOnDrop();
          this.cdr.markForCheck();
        });
    }

    if (event.action === 'rollBackTo') {
      this.dragDropService.setOnEnd();
    }
  }
}
