import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Grid2Module } from '../../shared-features/grid2/grid2.module';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { ReferenceListComponent } from 'src/app/settings-app/reference/list/reference-list.component';
import { REFERENCE_LIST } from 'src/app/settings-app/reference/reference.list';
import { ReferenceCardComponent } from 'src/app/settings-app/reference/card/reference-card.component';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { MainReferenceComponent } from 'src/app/settings-app/reference/card/main-reference/main-reference.component';
import { ReferenceCreationModalComponent } from 'src/app/settings-app/reference/creation-modal/reference-creation-modal.component';
import { ReferenceEntryToolbarComponent } from 'src/app/settings-app/reference/card/reference-entity/reference-entry-toolbar/reference-entry-toolbar.component';
import { ReferenceEntryComponent } from 'src/app/settings-app/reference/card/reference-entity/reference-entry.component';
import { ReferenceEntryLineComponent } from 'src/app/settings-app/reference/card/reference-entity/reference-entry-line/reference-entry-line.component';

@NgModule({
  declarations: [
    ReferenceListComponent,
    ReferenceCardComponent,
    MainReferenceComponent,
    ReferenceEntryComponent,
    ReferenceCreationModalComponent,
    ReferenceEntryToolbarComponent,
    ReferenceEntryLineComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    Grid2Module,
    BaseSharedModule,
    CommentsModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'settings.references',
          url: '/references',
          component: ReferenceListComponent,
          params: {
            navigation: 'settings.references',
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: REFERENCE_LIST },
          ],
        },
        {
          name: 'settings.reference',
          params: {
            navigation: 'settings.references',
          },
          url: '/references/{entityId:guid}',
          redirectTo: 'settings.reference.main',
          component: ReferenceCardComponent,
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
        {
          name: 'settings.reference.main',
          url: '/main',
          component: MainReferenceComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'settings.reference.referenceEntry',
          url: '/lines',
          component: ReferenceEntryComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class ReferencesModule {}
