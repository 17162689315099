import { DestroyRef, Injectable, inject } from '@angular/core';
import _ from 'lodash';
import { DateTime } from 'luxon';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  GraphNode,
  TimelineGraph,
} from 'src/app/projects/card/project-tasks/shared/models/timeline-graph.model';
import {
  ProjectTask,
  ProjectTaskDependencyType,
} from 'src/app/shared/models/entities/projects/project-task.model';
import { ProjectTasksDataService } from './project-tasks-data.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable()
export class ProjectTaskDependenciesService {
  private allowedLeftMarkerTaskIdsSubject = new BehaviorSubject<
    string[] | null
  >(null);
  public allowedLeftMarkerTaskIds$ =
    this.allowedLeftMarkerTaskIdsSubject.asObservable();
  private allowedRightMarkerTaskIdsSubject = new BehaviorSubject<
    string[] | null
  >(null);
  public allowedRightMarkerTaskIds$ =
    this.allowedRightMarkerTaskIdsSubject.asObservable();

  private isCreatingDependencySubject = new BehaviorSubject<boolean>(false);
  public isCreatingDependency$ =
    this.isCreatingDependencySubject.asObservable();

  private subscriptions: Subscription[] = [];
  private dependencyGraph: TimelineGraph;

  private get defaultAllowedNodes(): GraphNode[] {
    if (!this.dataService.tasks?.length) return [];
    return [
      ...this.dataService.tasks.map(
        (task) => ({ id: task.id, type: 'start' }) as GraphNode,
      ),
      ...this.dataService.tasks.map(
        (task) => ({ id: task.id, type: 'finish' }) as GraphNode,
      ),
    ];
  }

  private destroyRef = inject(DestroyRef);

  constructor(private dataService: ProjectTasksDataService) {
    this.dataService.syncDependencies$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.syncDependencyGraph());
  }

  /**
   * Sets the list of allowed task nodes for dependency markers.
   *
   * @param allowedTaskNodes - The list of allowed task nodes.
   */
  public setAllowedMarkerTaskIds(allowedTaskNodes: GraphNode[]) {
    this.allowedLeftMarkerTaskIdsSubject.next(
      allowedTaskNodes.filter((m) => m.type === 'start').map((m) => m.id),
    );
    this.allowedRightMarkerTaskIdsSubject.next(
      allowedTaskNodes.filter((m) => m.type === 'finish').map((m) => m.id),
    );
  }

  /** Sets default taskIds list of allowed dependency markers. */
  public setDefaultAllowedMarkerTaskIds() {
    this.allowedLeftMarkerTaskIdsSubject.next(
      this.defaultAllowedNodes
        .filter((m) => m.type === 'start')
        .map((m) => m.id),
    );
    this.allowedRightMarkerTaskIdsSubject.next(
      this.defaultAllowedNodes
        .filter((m) => m.type === 'finish')
        .map((m) => m.id),
    );
  }

  /** Turns on/turn off creating dependency property. */
  public setIsCreatingDependency(isCreating: boolean) {
    this.isCreatingDependencySubject.next(isCreating);
  }

  /**
   * Retrieves the list of allowed predecessor task nodes based on the specified dependency type and task ID.
   *
   * @param taskId - The ID of the task for which to find allowed predecessor task nodes.
   * @param dependencyType - The type of dependency to consider for filtering allowed predecessor task nodes.
   * @param isRemoveExistingPredecessorTasks - Optional flag indicating whether to remove existing predecessor tasks from the allowed list. Defaults to true.
   * @param targetTasks - Optional list of target tasks to consider for allowed predecessor task nodes. If not provided, the default allowed nodes are used.
   * @returns An array of GraphNode objects representing the allowed predecessor task nodes.
   */
  public getAllowedPredecessorTaskNodes(
    taskId: string,
    dependencyType: ProjectTaskDependencyType,
    isRemoveExistingPredecessorTasks = true,
    targetTasks?: ProjectTask[],
  ): GraphNode[] {
    let allowedNodes: GraphNode[];
    let dependentNode: GraphNode;

    // Determine the source of allowed nodes based on the presence of targetTasks
    if (targetTasks) {
      allowedNodes = [
        ...targetTasks.map((t) => ({ id: t.id, type: 'start' }) as GraphNode),
        ...targetTasks.map((t) => ({ id: t.id, type: 'finish' }) as GraphNode),
      ];
    } else {
      allowedNodes = this.defaultAllowedNodes;
    }

    // Filter allowed nodes based on the dependency type and set the dependent node
    switch (dependencyType) {
      case ProjectTaskDependencyType.finishToStart:
        allowedNodes = allowedNodes.filter((n) => n.type === 'finish');
        dependentNode = { id: taskId, type: 'start' };
        break;
      case ProjectTaskDependencyType.finishToFinish:
        allowedNodes = allowedNodes.filter((n) => n.type === 'finish');
        dependentNode = { id: taskId, type: 'finish' };
        break;
      case ProjectTaskDependencyType.startToFinish:
        allowedNodes = allowedNodes.filter((n) => n.type === 'start');
        dependentNode = { id: taskId, type: 'finish' };
        break;
      case ProjectTaskDependencyType.startToStart:
        allowedNodes = allowedNodes.filter((n) => n.type === 'start');
        dependentNode = { id: taskId, type: 'start' };
        break;
      default:
        return [];
    }

    // Optionally remove existing predecessor tasks from the allowed nodes list
    if (isRemoveExistingPredecessorTasks) {
      const dependentTask = targetTasks
        ? targetTasks.find((task) => task.id === dependentNode.id)
        : this.dataService.getTask(dependentNode.id);
      allowedNodes = allowedNodes.filter(
        (n) =>
          !dependentTask.dependencies?.some((d) => d.predecessorId === n.id),
      );
    }

    allowedNodes = this.removeAllCascadeParentAndChildrenNodes(
      dependentNode,
      allowedNodes,
    );

    // Remove marker ids which provides circular dependency
    allowedNodes = this.removeCircularDependencyMarkers(
      dependentNode,
      allowedNodes,
      false,
      targetTasks,
    );

    return allowedNodes;
  }

  /**
   * Filters the dependency markers based on the predecessor node.
   *
   * @param predecessorNode - The predecessor node to filter the markers.
   */
  public filterDependencyMarkers(predecessorNode: GraphNode): void {
    let allowedNodes = this.removeDependentTaskMarkers(
      predecessorNode,
      this.defaultAllowedNodes,
    );
    //Remove existing predecessor task nodes
    allowedNodes = allowedNodes.filter(
      (node) =>
        !this.dataService
          .getTask(predecessorNode.id)
          .dependencies?.some((d) => d.predecessorId === node.id),
    );
    allowedNodes = this.removeAllCascadeParentAndChildrenNodes(
      predecessorNode,
      allowedNodes,
    );
    allowedNodes = this.removeCircularDependencyMarkers(
      predecessorNode,
      allowedNodes,
    );
    this.setAllowedMarkerTaskIds(allowedNodes);
  }

  private removeDependentTaskMarkers(
    predecessorNode: GraphNode,
    taskNodes: GraphNode[],
  ) {
    const dependentTasks = this.dataService.tasks
      .filter((t) =>
        t.dependencies.some((d) => d.predecessorId === predecessorNode.id),
      )
      .map((t) => t.id);
    return taskNodes.filter((n) => !dependentTasks.includes(n.id));
  }

  /**
   *  Determines if task is allowed to increase its level considering existing
   *  dependencies and returns corresponding boolean value. If any existing
   *  dependency within branch became circular, increase is not allowed.
   *
   * @param taskId - task under test.
   * @param tasks - project tasks after increase.
   * @param graph - dependency graph after increase.
   */
  public checkIfIncreaseAllowed(
    taskId: string,
    tasks: ProjectTask[],
    graph: TimelineGraph,
  ): boolean {
    const task = tasks.find((t) => t.id === taskId);
    const childTasks = this.getAllChildTasks(task.id, tasks);
    const branchTasks = [task, ...childTasks];
    const anyDependencyIsCircular = branchTasks.some((bt) =>
      bt.dependencies.some((d) => {
        const beginNodeType =
          d.type === ProjectTaskDependencyType.startToFinish ||
          d.type === ProjectTaskDependencyType.startToStart
            ? 'start'
            : 'finish';
        const endNodeType =
          d.type === ProjectTaskDependencyType.startToStart ||
          d.type === ProjectTaskDependencyType.startToFinish
            ? 'start'
            : 'finish';
        const beginNode: GraphNode = { id: bt.id, type: beginNodeType };
        const endNode: GraphNode = { id: d.predecessorId, type: endNodeType };

        return graph.checkIfPathExists(beginNode, endNode);
      }),
    );
    return !anyDependencyIsCircular;
  }

  /**
   *  Determines if task is allowed to decrease its level considering existing
   *  dependencies and returns corresponding boolean value. If any existing
   *  dependency within branch became circular, decrease is not allowed.
   *
   * @param taskId - task under test.
   * @param tasks - project tasks after decrease.
   * @param graph - dependency graph after decrease.
   */
  public checkIfDecreaseAllowed(
    taskId: string,
    tasks: ProjectTask[],
    graph: TimelineGraph,
  ): boolean {
    const task = tasks.find((t) => t.id === taskId);
    const leadTask = tasks.find((t) => t.id === task.leadTaskId);
    const childTasks = this.getAllChildTasks(leadTask.id, tasks);
    const branchTasks = [leadTask, ...childTasks];
    const anyDependencyIsCircular = branchTasks.some((bt) =>
      bt.dependencies.some((d) => {
        const beginNodeType =
          d.type === ProjectTaskDependencyType.startToFinish ||
          d.type === ProjectTaskDependencyType.startToStart
            ? 'start'
            : 'finish';
        const endNodeType =
          d.type === ProjectTaskDependencyType.startToStart ||
          d.type === ProjectTaskDependencyType.startToFinish
            ? 'start'
            : 'finish';
        const beginNode: GraphNode = { id: bt.id, type: beginNodeType };
        const endNode: GraphNode = { id: d.predecessorId, type: endNodeType };

        return graph.checkIfPathExists(beginNode, endNode);
      }),
    );
    return !anyDependencyIsCircular;
  }

  /** Checks has task dependent tasks.
   *
   * @param taskId task id for checking
   * @returns availability of the dependent tasks for the project task
   */
  public checkHasDependentTasks(taskId: string): boolean {
    return !!this.dataService.tasks.find((task) =>
      task.dependencies.find(
        (dependency) => dependency.predecessorId === taskId,
      ),
    );
  }

  /**
   * Returns date limited by task own dependencies and it summary tasks dependencies.
   *
   * @param task target task
   * @param exceptionTaskIds Exclude dependencies of predecessor tasks of this array.
   * @returns min allowed date for target task
   */
  public findMinStartAllowedDate(
    task: ProjectTask,
    exceptionTaskIds?: string[],
  ): DateTime {
    /** Return min start allowed date by dependency chain. */
    const findDateByDependencies = (handleTask: ProjectTask): string => {
      if (handleTask.dependencies.length) {
        const predecessorTasks = this.dataService.tasks.filter((t) =>
          handleTask.dependencies
            .map((dependency) => dependency.predecessorId)
            .filter((predecessorId) => {
              if (exceptionTaskIds?.length) {
                return !exceptionTaskIds.includes(predecessorId);
              } else {
                return true;
              }
            })
            .includes(t.id),
        );

        return _.max([
          ...predecessorTasks.map((predecessorTask) => predecessorTask.endDate),
        ]);
      } else {
        return null;
      }
    };

    /** Returns min start allowed date by dependency chain of summary tasks. */
    const findDateBySummaryTask = (handleTask: ProjectTask): string | null => {
      if (!handleTask.leadTaskId) {
        return null;
      }

      const leadTask = this.dataService.tasks.find(
        (t) => t.id === handleTask.leadTaskId,
      );
      if (exceptionTaskIds?.includes(leadTask?.id)) {
        return null;
      }

      if (!leadTask.dependencies.length) {
        return findDateBySummaryTask(leadTask);
      }

      const predecessorTasks = this.dataService.tasks.filter((t) =>
        leadTask.dependencies
          .map((dependency) => dependency.predecessorId)
          .includes(t.id),
      );

      return _.max([
        ...predecessorTasks.map((predecessorTask) => predecessorTask.endDate),
      ]);
    };

    const dateByDependencies = findDateByDependencies(task);
    const dateBySummaryTask = findDateBySummaryTask(task);

    const minDate = DateTime.fromISO(
      _.max([dateByDependencies, dateBySummaryTask]),
    ).plus({ days: 1 });

    return minDate;
  }

  /**
   * Builds a TimelineGraph from the provided array of ProjectTask objects.
   *
   * @param tasks - The array of ProjectTask objects to build the graph from.
   * @returns A new TimelineGraph instance constructed from the provided tasks.
   */
  public buildGraph(tasks: ProjectTask[]): TimelineGraph {
    return new TimelineGraph(tasks);
  }

  /** Synchronizes current task dependencies state with the graph. */
  public syncDependencyGraph() {
    this.dependencyGraph = new TimelineGraph(this.dataService.tasks);
  }

  /**
   * Returns all child task chain in the flat array.
   *
   * @param leadTaskId - id of the task for which searches child tasks
   * @param targetTasks - project tasks array for search (default uses array from data service)
   * @returns all child tasks
   */
  public getAllChildTasks(
    leadTaskId: string,
    targetTasks?: ProjectTask[],
  ): ProjectTask[] {
    const tasks = targetTasks ? targetTasks : this.dataService.tasks;
    if (!this.dataService.checkIsLeadTask(leadTaskId, targetTasks)) {
      return [];
    }

    const findFirstLevelChildren = (taskId: string) =>
      tasks.filter((t) => t.leadTaskId === taskId);

    return findFirstLevelChildren(leadTaskId).flatMap((childTask) => [
      childTask,
      ...this.getAllChildTasks(childTask.id, targetTasks),
    ]);
  }

  /**
   * Removes circular dependency markers from the list of allowed nodes based on the target node and other parameters.
   *
   * @param targetNode - The target node to check against for circular dependencies.
   * @param allowedNodes - The list of nodes to filter for circular dependencies.
   * @param isNodePredecessor - Indicates if the node is a predecessor or dependent. Defaults to true.
   * @param targetTasks - Optional list of target tasks to use for creating a custom dependency graph. Defaults to undefined.
   * @returns A list of allowed nodes with circular dependencies removed.
   */
  private removeCircularDependencyMarkers(
    targetNode: GraphNode,
    allowedNodes: GraphNode[],
    isNodePredecessor = true,
    targetTasks?: ProjectTask[],
  ): GraphNode[] {
    const taskIdsToFilter = [];

    let dependencyGraph: TimelineGraph;

    if (targetTasks) {
      dependencyGraph = new TimelineGraph(targetTasks);
    } else {
      dependencyGraph = this.dependencyGraph;
    }

    allowedNodes.forEach((node) => {
      const [beginNode, endNode] = isNodePredecessor
        ? [node, targetNode]
        : [targetNode, node];

      const willCauseCycle = dependencyGraph.checkIfPathExists(
        beginNode,
        endNode,
      );

      if (willCauseCycle) {
        taskIdsToFilter.push(node);
      }
    });

    allowedNodes = _.difference(allowedNodes, taskIdsToFilter);
    return allowedNodes;
  }

  /**
   * Returns all summary task chain in the flat array.
   *
   * @param taskId - id of the task for which searches summary tasks.
   * @param targetTasks - project tasks array for search (default uses array from data service).
   * @param excludeMain - determines whether to exclude main task from resulting tasks.
   * @returns all parent tasks.
   */
  private getAllSummaryTasks(
    taskId: string,
    targetTasks?: ProjectTask[],
    excludeMain = false,
  ): ProjectTask[] {
    const tasks = targetTasks ? targetTasks : this.dataService.tasks;
    const task = tasks.find((t) => t.id === taskId);
    if (!task.leadTaskId) {
      return [];
    }

    const findFirstLevelSummaryTask = (childTask: ProjectTask) => [
      tasks.find((t) => t.id === childTask.leadTaskId),
    ];

    let summaryTasks = findFirstLevelSummaryTask(task).flatMap(
      (summaryTask) => [
        summaryTask,
        ...this.getAllSummaryTasks(summaryTask.id, targetTasks),
      ],
    );
    if (excludeMain) {
      summaryTasks = summaryTasks.filter((st) => !!st.leadTaskId);
    }
    return summaryTasks;
  }

  /**
   * Removes all cascade parent and children nodes from the allowed nodes list.
   *
   * This method filters out all nodes that are either a summary task or a child task of the target node.
   *
   * @param targetNode - The node for which to remove all cascade parent and children nodes.
   * @param allowedNodes - The list of nodes from which to remove the cascade parent and children nodes.
   * @returns A new list of allowed nodes with all cascade parent and children nodes of the target node removed.
   */
  private removeAllCascadeParentAndChildrenNodes(
    targetNode: GraphNode,
    allowedNodes: GraphNode[],
  ) {
    const allSummaryTask = this.getAllSummaryTasks(targetNode.id);
    const allChildrenTasks = this.getAllChildTasks(targetNode.id);
    return allowedNodes.filter(
      (node) =>
        ![...allSummaryTask, ...allChildrenTasks]
          .map((t) => t.id)
          .includes(node.id),
    );
  }
}
