import {
  GridColumnType,
  GridNavigationColumn,
} from 'src/app/shared-features/grid2/models/grid-column.interface';
import { List, LoadingStrategy } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';

export const REFERENCE_LIST: List = {
  version: 1,
  name: 'reference',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'modified',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 3,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      state: 'settings.reference',
      header: 'shared2.props.name',
      hint: 'shared2.props.name',
      type: GridColumnType.Navigation,
    },
    {
      name: 'code',
      header: 'shared2.props.code',
      hint: 'shared2.props.code',
      type: GridColumnType.String,
    },
    {
      name: 'created',
      header: 'shared2.props.created.value',
      hint: 'shared2.props.created.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'modified',
      header: 'shared2.props.modified.value',
      hint: 'shared2.props.modified.hint',
      type: GridColumnType.DateTime,
    },
    {
      name: 'isActive',
      header: 'shared2.props.isActive.short',
      hint: 'shared2.props.isActive.value',
      type: GridColumnType.Boolean,
    },
    {
      name: 'createdBy',
      header: 'shared2.props.createdBy.value',
      hint: 'shared2.props.createdBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'modifiedBy',
      header: 'shared2.props.modifiedBy.value',
      hint: 'shared2.props.modifiedBy.hint',
      type: GridColumnType.String,
    },
  ],

  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'description',
      field: 'description',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'modified',
      field: 'modified',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'createdBy',
      field: 'createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modifiedBy',
      field: 'modifiedBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
  dataCollection: 'References',
};
