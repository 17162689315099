import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import {
  GridColumnType,
  GridCurrencyColumn,
  GridNavigationColumn,
} from '../models/inner/grid-column.interface';

export const PROJECT_LIST: List = {
  version: 6,
  name: 'projects',
  dataCollection: 'ProjectTotals',
  clrType: 'Project',
  customFieldPrefixForDataField: 'project.',
  views: [
    {
      name: 'my',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 3,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'projectCode',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectDescription',
          visibleByDefault: false,
          width: 3,
        },
        {
          column: 'projectOrganization',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'legalEntity',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'projectProgram',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'sourceProject',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'projectStartDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'projectEndDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'projectBillingType',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectCreated',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectCreatedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectModified',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectModifiedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectExternalUrl',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'projectCurrency',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'estimatedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedRevenue',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedRevenueBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedRevenueOverPeriodBC',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualRevenue',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualRevenueBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualRevenueOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpenses',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedExpensesBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedExpensesOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualExpenses',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualExpensesBC',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualExpensesOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'actualCost',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualCostBC',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualCostOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualCostOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedProfit',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfit',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriodBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriodBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'planningMode',
          visibleByDefault: false,
          width: 1,
        },
      ],
      contextFilter: [
        {
          project: {
            or: [
              {
                managerId: { type: 'guid', value: '#user' },
              },
              {
                coManagers: {
                  any: {
                    userId: { type: 'guid', value: '#user' },
                  },
                },
              },
            ],
          },
        },
      ],
      order: {
        column: 'name',
        reverse: false,
      },
    },
    {
      name: 'active',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 3,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'projectCode',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectManager',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'projectDescription',
          visibleByDefault: false,
          width: 3,
        },
        {
          column: 'projectOrganization',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'projectProgram',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'sourceProject',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'legalEntity',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'projectStartDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'projectEndDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'projectBillingType',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectCreated',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectCreatedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectModified',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectModifiedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectExternalUrl',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'projectCurrency',
          visibleByDefault: false,
          width: 1,
        },

        {
          column: 'estimatedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedRevenue',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedRevenueBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedRevenueOverPeriodBC',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualRevenue',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualRevenueBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualRevenueOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpenses',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedExpensesBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedExpensesOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualExpenses',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualExpensesBC',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualExpensesOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualCost',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualCostBC',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualCostOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualCostOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfit',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfit',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriodBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriodBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'planningMode',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: {
        column: 'name',
        reverse: false,
      },
      contextFilter: [
        {
          project: {
            isActive: true,
          },
        },
      ],
    },
    {
      name: 'monitoring',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 3,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'projectCode',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectManager',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'projectDescription',
          visibleByDefault: false,
          width: 3,
        },
        {
          column: 'projectOrganization',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'projectProgram',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'sourceProject',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'legalEntity',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'state',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectStartDate',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectEndDate',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectBillingType',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectCreated',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectCreatedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectModified',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectModifiedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectExternalUrl',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'projectCurrency',
          visibleByDefault: false,
          width: 1,
        },

        {
          column: 'estimatedHours',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHours',
          visibleByDefault: true,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedRevenue',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'plannedRevenueBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedRevenueOverPeriodBC',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualRevenue',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'actualRevenueBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualRevenueOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpenses',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'plannedExpensesBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedExpensesOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualExpenses',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'actualExpensesBC',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualExpensesOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualCost',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'actualCostBC',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualCostOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualCostOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfit',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfit',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedProfitability',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'plannedProfitabilityBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriodBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitability',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'actualProfitabilityBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriodBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'planningMode',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
      contextFilter: [
        {
          project: {
            isActive: true,
          },
        },
      ],
    },
    {
      name: 'all',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 3,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'projectCode',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectManager',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'projectDescription',
          visibleByDefault: false,
          width: 3,
        },
        {
          column: 'projectOrganization',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'projectProgram',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'sourceProject',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'legalEntity',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'projectStartDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'projectEndDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'projectBillingType',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectCreated',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectCreatedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectModified',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectModifiedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectExternalUrl',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'projectCurrency',
          visibleByDefault: false,
          width: 1,
        },

        {
          column: 'estimatedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedRevenue',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedRevenueBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedRevenueOverPeriodBC',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualRevenue',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualRevenueBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualRevenueOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpenses',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedExpensesBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedExpensesOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualExpenses',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualExpensesBC',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualExpensesOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'actualCost',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualCostBC',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualCostOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualCostOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedProfit',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfit',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriodBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriodBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'planningMode',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: {
        column: 'name',
        reverse: false,
      },
    },
    {
      name: 'program',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 3,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'projectCode',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectManager',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'projectDescription',
          visibleByDefault: false,
          width: 3,
        },
        {
          column: 'projectOrganization',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'projectProgram',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'sourceProject',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'legalEntity',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'projectStartDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'projectEndDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'projectBillingType',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectCreated',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectCreatedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectModified',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectModifiedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectExternalUrl',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'projectCurrency',
          visibleByDefault: false,
          width: 1,
        },

        {
          column: 'estimatedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedRevenue',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedRevenueBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedRevenueOverPeriodBC',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualRevenue',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualRevenueBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualRevenueOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpenses',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedExpensesBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedExpensesOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualExpenses',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualExpensesBC',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualExpensesOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualCost',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualCostBC',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualCostOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualCostOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfit',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfit',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriodBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriodBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'planningMode',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: {
        column: 'name',
        reverse: false,
      },
    },
    {
      name: 'client',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 3,
          totalByDefault: TotalType.Count,
        },
        {
          column: 'projectCode',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectManager',
          visibleByDefault: true,
          width: 2,
        },
        {
          column: 'projectDescription',
          visibleByDefault: false,
          width: 3,
        },
        {
          column: 'projectProgram',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'sourceProject',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'legalEntity',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'state',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'projectStartDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'projectEndDate',
          visibleByDefault: true,
          width: 1,
        },
        {
          column: 'projectBillingType',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectCreated',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectCreatedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectModified',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectModifiedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'projectExternalUrl',
          visibleByDefault: false,
          width: 2,
        },
        {
          column: 'projectCurrency',
          visibleByDefault: false,
          width: 1,
        },

        {
          column: 'estimatedHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHours',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualHoursOverPeriod',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedRevenue',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedRevenueBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedRevenueOverPeriodBC',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualRevenue',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualRevenueBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualRevenueOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualRevenueOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpenses',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedExpensesBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedExpensesOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualExpenses',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualExpensesBC',
          visibleByDefault: false,
          totalByDefault: TotalType.Sum,
          width: 1,
        },
        {
          column: 'actualExpensesOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualExpensesOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfit',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'plannedProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfit',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },
        {
          column: 'actualProfitOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitOverPeriodBC',
          visibleByDefault: false,
          width: 1,
          totalByDefault: TotalType.Sum,
        },

        {
          column: 'plannedProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'plannedProfitabilityOverPeriodBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitability',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriod',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'actualProfitabilityOverPeriodBC',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'planningMode',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: {
        column: 'name',
        reverse: false,
      },
    },
  ],
  columns: [
    {
      name: 'name',
      availableTotals: [TotalType.Count],
      type: GridColumnType.Navigation,
      state: 'project',
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
    } as GridNavigationColumn,
    {
      name: 'projectCode',
      type: GridColumnType.String,
      header: 'shared.columns.code',
      hint: 'shared.columns.code',
    },
    {
      name: 'projectDescription',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.description.header',
      hint: 'projects.projects.list.columns.description.hint',
    },
    {
      name: 'projectManager',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.manager.header',
      hint: 'projects.projects.list.columns.manager.hint',
    },
    {
      name: 'projectProgram',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.program.header',
      hint: 'projects.projects.list.columns.program.hint',
    },
    {
      name: 'sourceProject',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.sourceProject.header',
      hint: 'projects.projects.list.columns.sourceProject.hint',
    },
    {
      name: 'projectOrganization',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.client.header',
      hint: 'projects.projects.list.columns.client.hint',
    },
    {
      name: 'state',
      type: GridColumnType.State,
      header: 'projects.projects.list.columns.state.header',
      hint: 'projects.projects.list.columns.state.hint',
    },
    {
      name: 'projectStartDate',
      type: GridColumnType.Date,
      header: 'projects.projects.list.columns.startDate.header',
      hint: 'projects.projects.list.columns.startDate.hint',
    },
    {
      name: 'projectEndDate',
      type: GridColumnType.Date,
      header: 'projects.projects.list.columns.endDate.header',
      hint: 'projects.projects.list.columns.endDate.hint',
    },
    {
      name: 'projectBillingType',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.billingType.header',
      hint: 'projects.projects.list.columns.billingType.hint',
    },
    {
      name: 'projectExternalUrl',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.externalUrl.header',
      hint: 'projects.projects.list.columns.externalUrl.hint',
    },
    {
      name: 'projectCreated',
      type: GridColumnType.Date,
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
    },
    {
      name: 'projectCreatedBy',
      type: GridColumnType.String,
      header: 'shared.columns.createdBy.title',
      hint: 'shared.columns.createdBy.hint',
    },
    {
      name: 'projectModified',
      type: GridColumnType.Date,
      header: 'shared.columns.modified.title',
      hint: 'shared.columns.modified.hint',
    },
    {
      name: 'projectModifiedBy',
      type: GridColumnType.String,
      header: 'shared.columns.modifiedBy.title',
      hint: 'shared.columns.modifiedBy.hint',
    },
    {
      name: 'projectCurrency',
      type: GridColumnType.Entity,
      header: 'projects.projects.list.columns.currency.header',
      hint: 'projects.projects.list.columns.currency.hint',
    },

    <GridCurrencyColumn>{
      name: 'plannedExpenses',
      header: 'projects.projects.list.columns.plannedExpenses.header',
      hint: 'projects.projects.list.columns.plannedExpenses.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'plannedExpensesBC',
      header: 'projects.projects.list.columns.plannedExpensesBC.header',
      hint: 'projects.projects.list.columns.plannedExpensesBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'plannedExpensesOverPeriod',
      header: 'projects.projects.list.columns.plannedExpensesOverPeriod.header',
      hint: 'projects.projects.list.columns.plannedExpensesOverPeriod.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'plannedExpensesOverPeriodBC',
      header:
        'projects.projects.list.columns.plannedExpensesOverPeriodBC.header',
      hint: 'projects.projects.list.columns.plannedExpensesOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'actualExpenses',
      header: 'projects.projects.list.columns.actualExpenses.header',
      hint: 'projects.projects.list.columns.actualExpenses.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'actualExpensesBC',
      header: 'projects.projects.list.columns.actualExpensesBC.header',
      hint: 'projects.projects.list.columns.actualExpensesBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'actualExpensesOverPeriod',
      header: 'projects.projects.list.columns.actualExpensesOverPeriod.header',
      hint: 'projects.projects.list.columns.actualExpensesOverPeriod.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'actualExpensesOverPeriodBC',
      header:
        'projects.projects.list.columns.actualExpensesOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualExpensesOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },

    <GridCurrencyColumn>{
      name: 'actualCost',
      header: 'projects.projects.list.columns.actualCost.header',
      hint: 'projects.projects.list.columns.actualCost.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'actualCostBC',
      header: 'projects.projects.list.columns.actualCostBC.header',
      hint: 'projects.projects.list.columns.actualCostBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'actualCostOverPeriod',
      header: 'projects.projects.list.columns.actualCostOverPeriod.header',
      hint: 'projects.projects.list.columns.actualCostOverPeriod.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'actualCostOverPeriodBC',
      header: 'projects.projects.list.columns.actualCostOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualCostOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },

    <GridCurrencyColumn>{
      name: 'plannedRevenue',
      header: 'projects.projects.list.columns.plannedRevenue.header',
      hint: 'projects.projects.list.columns.plannedRevenue.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'plannedRevenueBC',
      header: 'projects.projects.list.columns.plannedRevenueBC.header',
      hint: 'projects.projects.list.columns.plannedRevenueBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'plannedRevenueOverPeriod',
      header: 'projects.projects.list.columns.plannedRevenueOverPeriod.header',
      hint: 'projects.projects.list.columns.plannedRevenueOverPeriod.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'plannedRevenueOverPeriodBC',
      header:
        'projects.projects.list.columns.plannedRevenueOverPeriodBC.header',
      hint: 'projects.projects.list.columns.plannedRevenueOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'actualRevenue',
      header: 'projects.projects.list.columns.actualRevenue.header',
      hint: 'projects.projects.list.columns.actualRevenue.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'actualRevenueBC',
      header: 'projects.projects.list.columns.actualRevenueBC.header',
      hint: 'projects.projects.list.columns.actualRevenueBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'actualRevenueOverPeriod',
      header: 'projects.projects.list.columns.actualRevenueOverPeriod.header',
      hint: 'projects.projects.list.columns.actualRevenueOverPeriod.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'actualRevenueOverPeriodBC',
      header: 'projects.projects.list.columns.actualRevenueOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualRevenueOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'plannedProfit',
      header: 'projects.projects.list.columns.plannedProfit.header',
      hint: 'projects.projects.list.columns.plannedProfit.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'plannedProfitBC',
      header: 'projects.projects.list.columns.plannedProfitBC.header',
      hint: 'projects.projects.list.columns.plannedProfitBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'plannedProfitOverPeriod',
      header: 'projects.projects.list.columns.plannedProfitOverPeriod.header',
      hint: 'projects.projects.list.columns.plannedProfitOverPeriod.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'plannedProfitOverPeriodBC',
      header: 'projects.projects.list.columns.plannedProfitOverPeriodBC.header',
      hint: 'projects.projects.list.columns.plannedProfitOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'actualProfit',
      header: 'projects.projects.list.columns.actualProfit.header',
      hint: 'projects.projects.list.columns.actualProfit.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'actualProfitBC',
      header: 'projects.projects.list.columns.actualProfitBC.header',
      hint: 'projects.projects.list.columns.actualProfitBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'actualProfitOverPeriod',
      header: 'projects.projects.list.columns.actualProfitOverPeriod.header',
      hint: 'projects.projects.list.columns.actualProfitOverPeriod.hint',
      type: GridColumnType.Currency,
      currencyCodeProperty: 'projectCurrency.alpha3Code',
    },
    <GridCurrencyColumn>{
      name: 'actualProfitOverPeriodBC',
      header: 'projects.projects.list.columns.actualProfitOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualProfitOverPeriodBC.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Currency,
    },
    <GridCurrencyColumn>{
      name: 'plannedProfitability',
      header: 'projects.projects.list.columns.plannedProfitability.header',
      hint: 'projects.projects.list.columns.plannedProfitability.hint',
      type: GridColumnType.Percent,
    },
    <GridCurrencyColumn>{
      name: 'plannedProfitabilityBC',
      header: 'projects.projects.list.columns.plannedProfitabilityBC.header',
      hint: 'projects.projects.list.columns.plannedProfitabilityBC.hint',
      type: GridColumnType.Percent,
    },
    <GridCurrencyColumn>{
      name: 'plannedProfitabilityOverPeriod',
      header:
        'projects.projects.list.columns.plannedProfitabilityOverPeriod.header',
      hint: 'projects.projects.list.columns.plannedProfitabilityOverPeriod.hint',
      type: GridColumnType.Percent,
    },
    <GridCurrencyColumn>{
      name: 'plannedProfitabilityOverPeriodBC',
      header:
        'projects.projects.list.columns.plannedProfitabilityOverPeriodBC.header',
      hint: 'projects.projects.list.columns.plannedProfitabilityOverPeriodBC.hint',
      type: GridColumnType.Percent,
    },
    <GridCurrencyColumn>{
      name: 'actualProfitability',
      header: 'projects.projects.list.columns.actualProfitability.header',
      hint: 'projects.projects.list.columns.actualProfitability.hint',
      type: GridColumnType.Percent,
    },
    <GridCurrencyColumn>{
      name: 'actualProfitabilityBC',
      header: 'projects.projects.list.columns.actualProfitabilityBC.header',
      hint: 'projects.projects.list.columns.actualProfitabilityBC.hint',
      type: GridColumnType.Percent,
    },
    <GridCurrencyColumn>{
      name: 'actualProfitabilityOverPeriod',
      header:
        'projects.projects.list.columns.actualProfitabilityOverPeriod.header',
      hint: 'projects.projects.list.columns.actualProfitabilityOverPeriod.hint',
      type: GridColumnType.Percent,
    },
    <GridCurrencyColumn>{
      name: 'actualProfitabilityOverPeriodBC',
      header:
        'projects.projects.list.columns.actualProfitabilityOverPeriodBC.header',
      hint: 'projects.projects.list.columns.actualProfitabilityOverPeriodBC.hint',
      type: GridColumnType.Percent,
    },
    {
      name: 'estimatedHours',
      header: 'projects.projects.list.columns.estimatedHours.header',
      hint: 'projects.projects.list.columns.estimatedHours.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
    {
      name: 'actualHours',
      header: 'projects.projects.list.columns.actualHours.header',
      hint: 'projects.projects.list.columns.actualHours.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
    {
      name: 'actualHoursOverPeriod',
      header: 'projects.projects.list.columns.actualHoursOverPeriod.header',
      hint: 'projects.projects.list.columns.actualHoursOverPeriod.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
    {
      name: 'plannedHours',
      header: 'projects.projects.list.columns.plannedHours.header',
      hint: 'projects.projects.list.columns.plannedHours.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
    {
      name: 'plannedHoursOverPeriod',
      header: 'projects.projects.list.columns.plannedHoursOverPeriod.header',
      hint: 'projects.projects.list.columns.plannedHoursOverPeriod.hint',
      availableTotals: [
        TotalType.Sum,
        TotalType.Max,
        TotalType.Min,
        TotalType.Average,
      ],
      type: GridColumnType.Work,
    },
    {
      name: 'planningMode',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.planningMode.header',
      hint: 'projects.projects.list.columns.planningMode.hint',
    },
    {
      name: 'legalEntity',
      type: GridColumnType.String,
      header: 'projects.projects.list.columns.legalEntity.header',
      hint: 'projects.projects.list.columns.legalEntity.hint',
    },
  ],

  dataColumns: [
    {
      column: 'name',
      field: 'project.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectCode',
      field: 'project.code',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectDescription',
      field: 'project.description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectProgram',
      field: 'project.program.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectManager',
      field: 'project.manager.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectOrganization',
      field: 'project.organization.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'state',
      field: [
        'project.state.name',
        'project.state.code',
        'project.state.style',
        'project.state.isEntityProtected',
      ],
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'sourceProject',
      field: 'project.sourceProject.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectEndDate',
      field: 'project.endDate',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectStartDate',
      field: 'project.startDate',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectBillingType',
      field: 'project.billingType.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectExternalUrl',
      field: 'project.externalUrl',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectCreated',
      field: 'project.created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectModified',
      field: 'project.modified',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectCreatedBy',
      field: 'project.createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectModifiedBy',
      field: 'project.modifiedBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedExpenses',
      field: 'plannedExpenses',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedExpensesBC',
      field: 'plannedExpensesBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedExpensesOverPeriod',
      field: 'plannedExpensesOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedExpensesOverPeriodBC',
      field: 'plannedExpensesOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualExpenses',
      field: 'actualExpenses',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualExpensesBC',
      field: 'actualExpensesBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualExpensesOverPeriod',
      field: 'actualExpensesOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualExpensesOverPeriodBC',
      field: 'actualExpensesOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedRevenue',
      field: 'plannedRevenue',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedRevenueBC',
      field: 'plannedRevenueBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedRevenueOverPeriod',
      field: 'plannedRevenueOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedRevenueOverPeriodBC',
      field: 'plannedRevenueOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualRevenue',
      field: 'actualRevenue',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualRevenueBC',
      field: 'actualRevenueBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualRevenueOverPeriod',
      field: 'actualRevenueOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualRevenueOverPeriodBC',
      field: 'actualRevenueOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfit',
      field: 'plannedProfit',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitBC',
      field: 'plannedProfitBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitOverPeriod',
      field: 'plannedProfitOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitOverPeriodBC',
      field: 'plannedProfitOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfit',
      field: 'actualProfit',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitBC',
      field: 'actualProfitBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitOverPeriod',
      field: 'actualProfitOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitOverPeriodBC',
      field: 'actualProfitOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitability',
      field: 'plannedProfitability',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitabilityBC',
      field: 'plannedProfitabilityBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitabilityOverPeriod',
      field: 'plannedProfitabilityOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedProfitabilityOverPeriodBC',
      field: 'plannedProfitabilityOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitability',
      field: 'actualProfitability',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitabilityBC',
      field: 'actualProfitabilityBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitabilityOverPeriod',
      field: 'actualProfitabilityOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualProfitabilityOverPeriodBC',
      field: 'actualProfitabilityOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'estimatedHours',
      field: 'estimatedHours',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualHours',
      field: 'actualHours',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualCost',
      field: 'actualCost',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualCostBC',
      field: 'actualCostBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualCostOverPeriod',
      field: 'actualCostOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualCostOverPeriodBC',
      field: 'actualCostOverPeriodBC',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'actualHoursOverPeriod',
      field: 'actualHoursOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedHours',
      field: 'plannedHours',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'plannedHoursOverPeriod',
      field: 'plannedHoursOverPeriod',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'projectCurrency',
      field: ['project.currency.alpha3Code', 'project.currency.name'],
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'planningMode',
      field: 'project.planningMode.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'legalEntity',
      field: 'project.legalEntity.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
};
