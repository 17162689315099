import {
  Component,
  OnInit,
  Input,
  Injector,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  Inject,
  DestroyRef,
  inject,
} from '@angular/core';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info';
import { CommentedEntityCollectionType } from 'src/app/shared/models/enums/commented-entity-collection-type.enum';
import { ENTITY_COLLECTION } from 'src/app/shared/tokens';
import { IssueCardService } from 'src/app/issues/card/issue-card.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { tap } from 'rxjs';
import { FormHeaderService } from 'src/app/shared/components/chrome/form-header2/form-header.service';
import { OffCanvasService } from 'src/app/core/off-canvas.service';
import { ChromeService } from 'src/app/core/chrome.service';
import { NgbAccordionItem } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'tmt-issue-card',
  templateUrl: './issue-card.component.html',
  styleUrls: ['./issue-card.component.scss'],
  providers: [
    { provide: ENTITY_COLLECTION, useValue: 'Issues' },
    LifecycleService,
    IssueCardService,
    SavingQueueService,
    FormHeaderService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueCardComponent implements OnInit {
  @ViewChild(NgbAccordionItem) private accordionComponent: NgbAccordionItem;

  @Input() entityId: string;

  public activeTab: string;

  protected readonly commentedEntityCollectionType =
    CommentedEntityCollectionType;

  private destroyRef = inject(DestroyRef);

  /**
   * Saves issue name.
   *
   * @param name issue name.
   */
  public saveName = (name: string) =>
    this.dataService
      .collection('Issues')
      .entity(this.entityId)
      .patch({ name })
      .pipe(
        tap(() => {
          this.issueCardService.issueForm
            .get('name')
            .setValue(name, { emitEvent: false });
          this.offCanvasService.onEntityUpdated({ id: this.entityId, name });
          this.cdr.markForCheck();
        }),
      );

  constructor(
    public issueCardService: IssueCardService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private actionPanelService: ActionPanelService,
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private offCanvasService: OffCanvasService,
    private chromeService: ChromeService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit(): void {
    this.actionPanelService.setHasAutosave(true);
    this.issueCardService.load();
    this.chromeService.mainAreaSize$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (
          this.accordionComponent?.collapsed &&
          this.document.defaultView.innerWidth >= 1200 &&
          !this.offCanvasService.offCanvasEntry
        ) {
          this.accordionComponent.expand();
        }
      });
  }

  /**
   * Opens created by user info.
   *
   * @param target target element for info popup service.
   */
  public openCreatedByUserInfo(target: ElementRef): void {
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId: this.issueCardService.issue.createdBy.id,
        },
        injector: this.injector,
      },
    });
  }
}
