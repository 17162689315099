import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { ENTITY_COLLECTION, LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { Transition } from '@uirouter/core';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { ClientListComponent } from './list/client-list.component';
import { CLIENT_LIST } from 'src/app/shared/lists/client.list';
import { ClientFilterComponent } from './list/client-filter/client-filter.component';
import { ClientCardComponent } from './card/client-card.component';
import { ClientProjectsComponent } from './card/client-projects/client-projects.component';
import { ClientSettingsComponent } from './settings/client-settings.component';
import { ClientPnlComponent } from './card/client-pnl/client-pnl.component';
import { PnlModule } from 'src/app/shared-features/pnl/pnl.module';
import { ClientTariffsComponent } from './card/client-tariffs/client-tariffs.component';
import { ResourceRolesModule } from 'src/app/shared-features/resource-roles/resource-roles.module';
import { ClientOverviewModule } from 'src/app/clients/card/client-overview/client-overview.module';
import { ClientTariffsToolbarComponent } from './card/client-tariffs/client-tariffs-toolbar/client-tariffs-toolbar.component';
import { ClientTariffModalComponent } from './card/client-tariffs/client-tariff-modal/client-tariff-modal.component';
import { ProjectTaskSharedModule } from '../projects/card/project-tasks/shared/project-tasks-shared.module';
import { Grid2Module } from '../shared-features/grid2/grid2.module';
import { TariffRatesModule } from '../shared/modules/tariff-rates/tariff-rates.module';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';

@NgModule({
  declarations: [
    ClientListComponent,
    ClientCardComponent,
    ClientFilterComponent,
    ClientProjectsComponent,
    ClientSettingsComponent,
    ClientTariffsComponent,
    ClientTariffsToolbarComponent,
    ClientTariffModalComponent,
    ClientPnlComponent,
  ],
  imports: [
    TariffRatesModule,
    Grid2Module,
    CommonModule,
    SharedModule,
    PnlModule,
    ResourceRolesModule,
    ClientOverviewModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'clients',
          url: '/clients/{view:viewName}?{navigation}&{route}',
          component: ClientListComponent,
          params: {
            route: ROUTE_DEFAULT_PARAMS,
            navigation: 'projects.allClients',
            routeMode: null,
            view: null,
          },
          resolve: [
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
            { provide: LIST, useValue: CLIENT_LIST },
            { provide: ENTITY_COLLECTION, useValue: 'Organizations' },
          ],
        },
        {
          name: 'client',
          url: '/clients/{entityId:guid}?{navigation}&{route}',
          dynamic: true,
          params: {
            navigation: 'projects.allClients',
            route: ROUTE_DEFAULT_PARAMS,
            routeMode: null,
          },
          component: ClientCardComponent,
          redirectTo: 'client.overview',
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },
        {
          name: 'client.projects',
          url: '/projects',
          component: ClientProjectsComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'client.tariffs',
          url: '/tariffs',
          component: ClientTariffsComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'client.pnl',
          url: '/pnl',
          component: ClientPnlComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
    ProjectTaskSharedModule,
  ],
})
export class ClientsModule {}
