import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AppService } from 'src/app/core/app.service';
import { sortBy } from 'lodash';
import { MetaEntityBaseProperty } from 'src/app/shared/models/entities/settings/metamodel.model';

export type CustomFieldMode = 'default' | 'filter';

@Component({
  selector: 'wp-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss'],
})
export class CustomFieldsComponent implements OnInit {
  @Input() entityType: string;
  @Input() fields: MetaEntityBaseProperty[];
  @Input() columnClasses = 'col-6';
  @Input() formGroup: UntypedFormGroup;
  @Input() isVisibleHr = true;
  @Input() mode: CustomFieldMode = 'default';

  constructor(private app: AppService) {}

  private _leftFields: MetaEntityBaseProperty[] = [];
  public get leftFields(): MetaEntityBaseProperty[] {
    return this._leftFields;
  }
  private _rightFields: MetaEntityBaseProperty[] = [];
  public get rightFields(): MetaEntityBaseProperty[] {
    return this._rightFields;
  }

  public ngOnInit(): void {
    if (this.entityType) {
      this.fields = this.app
        .getCustomFields(this.entityType)
        .filter((f) =>
          this.mode === 'default'
            ? f.viewConfiguration.isShownInEntityForms
            : f.viewConfiguration.isShownInEntityListFilters,
        );
    }

    this._leftFields = sortBy(
      this.fields.filter((f) => f.viewConfiguration.formColumn === 'Left'),
      ['viewConfiguration.orderNumber'],
    );

    this._rightFields = sortBy(
      this.fields.filter((f) => f.viewConfiguration.formColumn === 'Right'),
      ['viewConfiguration.orderNumber'],
    );
  }

  /**
   * Retrieves the form control associated with a given field.
   *
   * @param field - The MetaEntityBaseProperty representing the field.
   * @returns The UntypedFormControl associated with the field.
   */
  public getControl(field: MetaEntityBaseProperty): UntypedFormControl {
    return this.formGroup.controls[field.name] as UntypedFormControl;
  }
}
