import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { Constants } from 'src/app/shared/globals/constants';
import { BehaviorSubject } from 'rxjs';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';

@Component({
  selector: 'tmt-issue-creation',
  templateUrl: './issue-creation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueCreationComponent {
  public issueForm: UntypedFormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
  });
  public isSaving$ = new BehaviorSubject<boolean>(false);

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private dataService: DataService,
    private stateService: StateService,
    private activeModal: NgbActiveModal,
    private customFieldService: CustomFieldService,
  ) {
    this.customFieldService.enrichFormGroup(this.issueForm, 'Issue', true);
    this.customFieldService.enrichFormGroupWithDefaultValues(
      this.issueForm,
      'Issue',
    );
  }

  /* Creates competence. */
  public create(): void {
    this.issueForm.markAllAsTouched();

    if (this.issueForm.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving$.next(true);

    const data = this.issueForm.value;
    this.customFieldService.assignValues(
      data,
      this.issueForm.getRawValue(),
      'Issue',
    );

    this.dataService
      .collection('Issues')
      .insert(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.notificationService.successLocal(
            'components.issueCreationComponent.messages.created',
          );
          this.stateService.go('issue', {
            entityId: response.id,
          });
          this.isSaving$.next(false);
          this.activeModal.close();
        },
        error: (error) => {
          this.notificationService.error(error.message);
          this.isSaving$.next(false);
        },
      });
  }

  /* Closes issue creating modal. */
  public cancel(): void {
    this.activeModal.dismiss();
  }
}
