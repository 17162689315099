import { Injectable, Type } from '@angular/core';
import { MetamodelDescriptionFilterComponent } from 'src/app/settings-app/metamodel/metamodel-description/filter/metamodel-description-filter.component';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

@Injectable()
export class MetamodelDescriptionFilterService extends FilterService {
  public override component: Type<FilterDetails> =
    MetamodelDescriptionFilterComponent;
  public override hasDetails = false;
  public override hasViewSelector = false;

  public override placeholder = 'shared2.filters.name.ph';

  protected override getDefaultValues = (): any => {
    const value = {
      text: '',
    };

    return value;
  };

  public override getODataFilter() {
    const result: any[] = super.getODataFilter();

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push({
        or: [{ 'tolower(name)': { contains: text } }],
      });
    }
    return result;
  }
}
