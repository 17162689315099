<label class="group">{{ 'settings.permissionSets.granules.groupData' | translate }}</label>
<table class="table table-bordered compact-table" style="table-layout: fixed; width: 650px">
  <thead>
    <tr>
      <th style="width: 250px; vertical-align: middle">
        {{ 'settings.permissionSets.granules.granular' | translate }}
      </th>
      <th style="width: 200px; text-align: center">
        {{ 'settings.permissionSets.permissions.view' | translate }}
      </th>
      <th style="width: 200px; text-align: center">
        {{ 'settings.permissionSets.permissions.edit' | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{{ 'settings.permissionSets.granules.userProfiles' | translate }}</td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('User.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.userProducts' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserProduct.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserProduct.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>
        {{ 'settings.permissionSets.granules.userGroups' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserGroup.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserGroup.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>
        {{ 'settings.permissionSets.granules.userPermissionSets' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserPermissionSet.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserPermissionSet.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.userCost' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserCostValue.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserCostValue.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.userActivity' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserActivity.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserActivity.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.userRole' | translate }}</td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserRole.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>
        {{ 'settings.permissionSets.granules.userSchedules' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserSchedule.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserSchedule.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.userSettings' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserSetting.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserSetting.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>
        {{ 'settings.permissionSets.granules.userSubstitute' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserSubstitute.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('UserSubstitute.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.groups' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Group.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Group.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.levels' | translate }}</td>
      <td></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Level.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.grades' | translate }}</td>
      <td></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Grade.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.locations' | translate }}</td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Location.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.departments' | translate }}</td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Department.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.resourcePools' | translate }}</td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ResourcePool.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.skills' | translate }}</td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Skill.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>
        {{ 'settings.permissionSets.granules.permissionSets' | translate }}
      </td>
      <td></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('PermissionSet.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>
        {{ 'settings.permissionSets.granules.timeSheetPeriods' | translate }}
      </td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeSheetPeriod.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.activities' | translate }}</td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Activity.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.timeOffTypes' | translate }}</td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeOffType.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.expenseTypes' | translate }}</td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ExpenseType.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.schedules' | translate }}</td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Schedule.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.scheduleExceptions' | translate }}</td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ScheduleException.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.legalEntity' | translate }}</td>
      <td></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('LegalEntity.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.rateMatrices' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('RateMatrix.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('RateMatrix.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.currencies' | translate }}</td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Currency.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.costNormalizationRule' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('CostNormalizationRule.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('CostNormalizationRule.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.roles' | translate }}</td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Role.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.competences' | translate }}</td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Competence.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.invoiceTemplates' | translate }}</td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('InvoiceTemplate.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>
        {{ 'settings.permissionSets.granules.validationRule' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ValidationRule.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ValidationRule.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>
        {{ 'settings.permissionSets.granules.timeSheetTemplate' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeSheetTemplate.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('TimeSheetTemplate.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>
        {{ 'settings.permissionSets.granules.commonReports' | translate }}
      </td>
      <td class="text-center"></td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Report.Common.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>
        {{ 'settings.permissionSets.granules.reportTemplates' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Report.Templates.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Report.Templates.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>
        {{ 'settings.permissionSets.granules.calculatedFields' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('CalculatedField.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('CalculatedField.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.customFields' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('CustomField.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('CustomField.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>

    <tr>
      <td>{{ 'settings.permissionSets.granules.import' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Import.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Import.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.authProviders' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('AuthProvider.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('AuthProvider.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.lifecycles' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Lifecycle.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Lifecycle.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.loginLog' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('LoginLogEntry.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
    </tr>
    <tr>
      <td>{{ 'settings.permissionSets.granules.historyLog' | translate }}</td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('HistoryLogEntry.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td></td>
    </tr>
    <tr>
      <td>
        {{ 'settings.permissionSets.granules.boards' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Board.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Board.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>
        {{ 'shared2.groups.issueTypes' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('IssueType.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('IssueType.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>
        {{ 'shared2.groups.issueResolutions' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('IssueResolution.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('IssueResolution.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr>
      <td>
        {{ 'settings.permissionSets.granules.references' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Reference.All.viewEnabled')"
          type="checkbox"
        />
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('Reference.All.editEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
  </tbody>
</table>

<label class="group">{{ 'settings.permissionSets.granules.groupActions' | translate }}</label>
<table class="table table-bordered compact-table" style="table-layout: fixed; width: 650px">
  <thead>
    <tr>
      <th style="width: 250px; vertical-align: middle">
        {{ 'settings.permissionSets.granules.granular' | translate }}
      </th>
      <th style="width: 200px; text-align: center">
        {{ 'settings.permissionSets.permissions.allow' | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>
        {{ 'settings.permissionSets.granules.manageAccount' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ManageAccount.actionEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
    <tr *ngIf="!onprem">
      <td>
        {{ 'settings.permissionSets.granules.manageBilling' | translate }}
      </td>
      <td class="text-center">
        <input
          class="form-check-input"
          [formControl]="form.get('ManageBilling.actionEnabled')"
          type="checkbox"
        />
      </td>
    </tr>
  </tbody>
</table>
