import { InjectionToken, Type } from '@angular/core';

import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { BoardCardViewProperties } from 'src/app/settings-app/boards/model/board.model';

export interface BoardConfig {
  /** Board ID. */
  id?: string;
  /** Board title. */
  title?: string;
  /** Entity type. */
  entityType: string;
  /** Entity endpoint name. */
  collection: string;
  /** Cards endpoint name. */
  cardCollection?: string;
  /** Entity type of custom field. */
  clrType?: string;
  /** Entity filter service. */
  filterService: typeof FilterService;
  /** Indicates is filter query hasn't nested property. Like `{ project: {property: Value } ... }` */
  isSimpleFilterQuery?: boolean;
  /** Correlation of card properties to entity properties. */
  cardStructure: BoardCardViewProperties[];
  /** Indicates is columns editing allowed.  */
  editAllowed?: boolean;
  /** Item name from `CreateMenuService`.  */
  createMenuButton?: string;
  /** Component for opening in offCanvas. */
  offCanvasComponent?: Type<any>;
}

export const BOARD_CONFIG = new InjectionToken<BoardConfig>('boardConfig');
