import { Injectable, NgZone, Optional } from '@angular/core';
import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';
import { TranslateService } from '@ngx-translate/core';
import { PermissionType } from '../shared/models/inner/permission-type.enum';
import { ClientProfile } from '../shared/models/session/client-profile.model';
import { firstValueFrom, of } from 'rxjs';
import { DataService } from './data.service';
import { catchError, tap } from 'rxjs/operators';
import { DateTime, Settings } from 'luxon';
import {
  ClientSession,
  Permission,
} from '../shared/models/session/client-session.model';
import { AppName } from '../shared/globals/app-name';
import { RoleName } from '../shared/models/enums/role-name.enum';
import { StateService, TransitionService } from '@uirouter/core';
import { Exception } from '../shared/models/exception';
import { LogService } from './log.service';
import {
  Feature,
  PRODUCT_FEATURE_MAPPING,
} from '../shared/models/enums/feature.enum';
import { NamedEntity } from '../shared/models/entities/named-entity.model';
import { registerLocaleData } from '@angular/common';
import { bcp47Normalize } from 'bcp-47-normalize';
import { environment } from 'src/environments/environment';
import { SavingQueueService } from '../shared/services/saving-queue.service';
import { MessageService } from './message.service';
import { AutosaveStateService } from '../shared/services/autosave-state.service';
import { BlockUIService } from './block-ui.service';
import { Language } from '../shared/models/enums/language.enum';
import { ProductSelectorModalComponent } from 'src/app/shared/components/features/product-selector-modal/product-selector-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { LicenseInfo } from 'src/app/shared/models/entities/settings/license-info.model';
import { NavigationItemCustom } from 'src/app/shared/models/navigation/navigation';
import { MetaEntityBaseProperty } from 'src/app/shared/models/entities/settings/metamodel.model';

/**
 * Сервис предназначен для инициализации приложения и работы с сессией.
 */
@Injectable()
export class AppService {
  /** Клиентская сессия. */
  public session: ClientSession;

  public currentCulture: string;

  /** Клиентский профиль. */
  public clientProfile: ClientProfile;
  public navigationItems: NavigationItemCustom[] = [];
  public serviceWorkerRegistration: any;

  private savingQueue: SavingQueueService;

  constructor(
    private log: LogService,
    @Optional() private state: StateService,
    private modal: NgbModal,
    private data: DataService,
    private translate: TranslateService,
    private wpAppConfigService: AppConfigService,
    private wpAuthService: AuthService,
    private zone: NgZone,
    messageService: MessageService,
    autosaveStateService: AutosaveStateService,
    transitionService: TransitionService,
    blockUI: BlockUIService,
  ) {
    this.savingQueue = new SavingQueueService(
      messageService,
      autosaveStateService,
      transitionService,
      log,
      blockUI,
      translate,
    );

    this.savingQueue.delayDuration = 1000;
  }

  /** Resets cache storage, service workers and server session. */
  public resetApp() {
    // Reset cache storage.
    caches.keys().then((keys) => {
      keys.forEach((eachCacheName) => {
        caches.open(eachCacheName).then((eachCache) => {
          console.log(eachCacheName);
          eachCache.keys().then((requests) => {
            requests.forEach((eachRequest) => {
              console.log(eachRequest);
              eachCache.delete(eachRequest);
            });
          });
        });
      });
    });

    // Reset service workers.
    navigator.serviceWorker
      .getRegistrations()
      .then((registrations) => {
        for (const registration of registrations) {
          registration.unregister();
        }
      })
      .then(() => {
        this.data.model
          .action('DestroySession')
          .execute()
          .subscribe(() => {
            location.reload();
          });
      });
  }

  /**
   * Инициализация приложения.
   * Вызывается при старте приложения до инициализации маршрутизатора и основных сервисов.
   */
  public start(): Promise<void> {
    this.log.log('Application is starting...');

    return new Promise((resolve) => {
      this.zone.runOutsideAngular(() => {
        this.wpAppConfigService.load().then(() => {
          this.wpAuthService.init();

          // Redirect after Passport Login.
          if (window.location.href.indexOf('/login') !== -1) {
            // Clear exists login.
            window.localStorage.clear();
          }

          if (window.location.href.indexOf('auth-callback') !== -1) {
            // Обработать ответ WP Passport или сразу продолжить.
            this.wpAuthService.completeAuthentication().then(
              () => initAuthWorker(),
              () => {
                // Обработка не нужна, будет переадресация на end session.
              },
            );
          } else {
            if (!this.wpAuthService.checkAuthSession()) {
              this.wpAuthService.signinSilent().then(
                () => initAuthWorker(),
                () => {
                  // Обработка не нужна, будет переадресация на sign in.
                },
              );
            } else {
              this.wpAuthService.getUser().then((user) => {
                if (!user || user.expired) {
                  this.wpAuthService.signinSilent().then(
                    () => initAuthWorker(),
                    () => {
                      // Обработка не нужна, будет переадресация на sing in.
                    },
                  );
                } else {
                  this.wpAuthService.startSilentRenew();
                  initAuthWorker();
                }
              });
            }
          }

          const initAuthWorker = () =>
            this.registerServiceWorker().then(() => loadSessionAndProfile());

          const loadSessionAndProfile = () => {
            Promise.all([
              this.loadSession().then(() => {
                this.translate.addLangs(['en-US', 'ru-RU']);

                const cultureCode = bcp47Normalize(this.session.culture);

                return Promise.all([
                  firstValueFrom(this.translate.use(this.session.language)),
                  this.localeInitializer(cultureCode).then(
                    () => {
                      this.currentCulture = cultureCode;
                    },
                    () => {
                      this.currentCulture = 'en-US';
                    },
                  ),
                ]).then(() => {
                  this.log.log('Language has been loaded.');
                  this.changeTimeZone();
                  this.changeLocale();
                  this.loadHelpScout();
                  this.checkTrial();
                });
              }),
              this.loadClientProfile(),
              this.loadNavigationItems(),
            ]).then(
              () => {
                this.log.log(
                  `Application is started. Build: ${AppConfigService.config.version}. Environment: ${environment.name}.`,
                );

                resolve();
              },
              () => null,
            );
          };
        });
      });
    });
  }

  /**
   * Loads custom navigation items.
   *
   * @returns Removed items compared to past response or `null`, if error occurred.
   */
  public loadNavigationItems(): Promise<NavigationItemCustom[] | null> {
    return firstValueFrom(
      this.data.model
        .function('GetNavigationItems')
        .get<NavigationItemCustom[]>(),
    ).then(
      (response) => {
        this.log.log('Navigations Items has been loaded.');

        const removedItems = _.differenceBy(
          this.navigationItems,
          response,
          (item) => `${item.area}.${item.group}.${item.entityId}`,
        );

        this.navigationItems = response;

        return removedItems;
      },
      () => {
        this.log.error(`Could not load navigation items.`);
        return null;
      },
    );
  }

  /** Регистрация Service Worker. */
  private async registerServiceWorker() {
    if (navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (navigator.serviceWorker.controller === null) {
          // we get here after a ctrl+f5 OR if there was no previous service worker.
          navigator.serviceWorker.ready.then(() => {
            registration.active.postMessage('claimMe');
          });
        }
      });

      navigator.serviceWorker.addEventListener('message', (event) => {
        const { action } = event.data;
        const port = event.ports[0];

        if (action === 'getAuthTokenHeader') {
          this.wpAuthService
            .getAuthorizationHeaderValue()
            .subscribe((authHeader) => {
              port.postMessage({
                response: authHeader,
              });
            });
        }
      });
    }
  }

  /** Загрузка пользовательской сессии. */
  private loadSession(): Promise<void> {
    this.log.log('Session loading...');
    return firstValueFrom(
      this.data.model.function('GetSession').get<ClientSession>(null, null, {
        $expand: 'configuration/metamodel/entities',
      }),
    ).then(
      (response) => {
        this.log.log('Session has been loaded.');
        this.session = response as ClientSession;
        this.session.timeZoneOffset = DateTime.utc().setZone(
          this.session.timeZone,
        ).offsetNameShort;
      },
      (error: Exception) => {
        const message = `Session wasn't loaded. ${error.code}: ${error.message}`;
        this.log.error(message);
      },
    );
  }

  /** Загрузка клиентского профиля. */
  private loadClientProfile(): Promise<void> {
    return firstValueFrom(
      this.data.model.function('GetClientProfile').get({ clientType: `'web'` }),
    ).then(
      (response: string) => {
        this.log.log('Profile has been loaded.');
        this.parseClientProfile(response);
      },
      () => {
        const error = `Could not load profile.`;
        this.log.error(error);
      },
    );
  }

  /** Сохранение клиентского профиля. */
  public saveProfileLazy() {
    const data = JSON.stringify(this.clientProfile);

    const action = this.data.model
      .action('UpdateClientProfile')
      .execute({
        clientType: 'web',
        clientProfile: data,
      })
      .pipe(
        catchError((val) => {
          this.log.error(`Client profile wasn't saved!`);
          return of(val);
        }),
        tap(() => {
          this.log.log('Client profile is saved.');
        }),
      );

    this.savingQueue.addToQueue('user', action);
  }

  public saveProfile(): Promise<void> {
    this.saveProfileLazy();
    return this.savingQueue.save();
  }

  /**
   * Gets the current language of the application.
   *
   * @returns The current language as a Language enum value.
   */
  public getLanguage(): Language {
    switch (this.session.language.toLowerCase()) {
      case 'ru-ru':
        return Language.ru;
      case 'ru':
        return Language.ru;
      case 'en-us':
        return Language.en;
      case 'en':
        return Language.en;
    }
  }
  /**
   * Converts a culture code to a backward-compatible Language enum value.
   *
   * @param cultureCode The culture code string to convert (e.g., 'ru-ru', 'en-US').
   * @returns The corresponding Language enum value (Language.ru or Language.en).
   */
  public getBackwardCompatibilityLanguage(cultureCode: string): Language {
    switch (cultureCode.toLowerCase()) {
      case 'ru-ru':
      case 'ru':
        return Language.ru;
      case 'en-us':
      case 'en':
        return Language.en;
      default:
    }
  }

  /** Проверка наличия прав на сущность.  */
  public checkEntityPermission(
    entityType: string,
    type: PermissionType,
  ): boolean {
    const entityAccess = this.session.entitiesAccess.find(
      (p) => p.entityType === entityType,
    );
    if (entityAccess) {
      switch (type) {
        case PermissionType.Read:
          return entityAccess.viewAllowed;
        case PermissionType.Modify:
          return entityAccess.editAllowed;
        case PermissionType.Delete:
          return entityAccess.deleteAllowed;
      }
    }
    return false;
  }

  /** Проверка права на гранулу.
   *
   * @deprecated The method should not be used  */
  public checkPermission(
    granularName: string,
    scopeName: string,
    type: PermissionType,
  ): boolean {
    const permission = this.session.permissions.find(
      (p: Permission) =>
        p.granularName === granularName &&
        (!scopeName || p.scopeName === scopeName),
    );
    if (!permission) {
      return false;
    }

    switch (type) {
      case PermissionType.Read:
        return permission.viewEnabled;
      case PermissionType.Modify:
        return permission.editEnabled;
      case PermissionType.Delete:
        return permission.deleteEnabled;
      case PermissionType.Execute:
        return permission.actionEnabled;
    }
  }

  /** Проверка наличия роли у текущего пользователя. */
  public checkRole(roleName: RoleName) {
    return this.session.roles.includes(roleName);
  }

  /** Checks feature availability.
   *
   * @param feature Business module of application
   * @example app.checkFeature(Feature.finance)
   *
   */
  public checkFeature(feature: Feature): boolean {
    return (
      this.session.products?.some((product) =>
        PRODUCT_FEATURE_MAPPING.get(product).has(feature),
      ) ?? false
    );
  }

  /** Проверка прав на модуль. */
  public checkAppAccess(app: AppName): boolean {
    switch (app) {
      case AppName.My:
        return true;
      case AppName.Team:
        return this.session.roles.includes(RoleName.TeamManager);
      case AppName.Projects:
        return this.session.roles.includes(RoleName.ProjectManager);

      case AppName.Resources:
        return (
          this.session.roles.includes(RoleName.ResourceManager) &&
          this.checkFeature(Feature.booking)
        );

      case AppName.Analytics:
        return true;
      case AppName.Clients:
        return environment.production == false;
      case AppName.Billing:
        return (
          this.session.roles.includes(RoleName.BillingManager) &&
          this.checkFeature(Feature.billing)
        );

      case AppName.Finance:
        return (
          this.session.roles.includes(RoleName.FinanceManager) &&
          this.checkFeature(Feature.finance)
        );
      case AppName.Settings:
        return this.session.roles.includes(RoleName.Administrator);
    }
  }

  /** Парсинг клиентского профиля пользователя. */
  private parseClientProfile(data: string): void {
    const profile = JSON.parse(data);

    if (!profile || !profile.version) {
      // TODO Выполнение миграции.
      this.clientProfile = new ClientProfile();
      this.clientProfile.version = '1';
    } else {
      this.clientProfile = profile as ClientProfile;
    }
  }

  /**
   * Установка локали.
   * Установка системной локали в AppModule.
   */
  private changeLocale() {
    this.log.log(`Language is ${this.session.language}`);
    Settings.defaultLocale = this.session.culture;
  }

  /** Установка часового пояса. */
  private changeTimeZone() {
    this.log.log(`Time zone is ${this.session.timeZone}.`);
    Settings.defaultZone = this.session.timeZone;
  }

  /** Checks trial and show settings if it is necessary. */
  private checkTrial() {
    if (
      this.session.configuration.tenantIsInTrial &&
      this.checkRole(RoleName.Administrator)
    ) {
      this.data.model
        .function('GetLicenseInfo')
        .get<LicenseInfo>()
        .subscribe({
          next: (licenseInfo) => {
            if (licenseInfo.products.filter((x) => x.limit > 0).length) {
              return;
            }
            const ref = this.modal.open(ProductSelectorModalComponent, {
              backdrop: false,
            });

            const instance =
              ref.componentInstance as ProductSelectorModalComponent;
            instance.licenseProducts = licenseInfo.products;
          },
          error: () => null,
        });
    }
  }

  /** Инициализация Help Scout Beacon. */
  private loadHelpScout() {
    if (AppConfigService.config.beacon) {
      const beaconId = AppConfigService.config.beacon[this.session.language];

      (window as any).Beacon('init', beaconId);

      (window as any).Beacon('identify', {
        name: this.session.user.name,
        email: this.session.user.email,
      });
    }
  }

  /** Возвращает список доступных ролей. */
  public getRolesEntities(): NamedEntity[] {
    const roles = [] as NamedEntity[];
    for (const role of Object.keys(RoleName)) {
      if (
        role === RoleName.BillingManager &&
        !this.checkFeature(Feature.billing)
      ) {
        continue;
      }

      if (
        role === RoleName.FinanceManager &&
        !this.checkFeature(Feature.finance)
      ) {
        continue;
      }

      if (
        role === RoleName.ResourceManager &&
        !this.checkFeature(Feature.booking)
      ) {
        continue;
      }

      roles.push({
        name: this.translate.instant(`enums.role.${role}`),
        id: role,
      });
    }

    return roles;
  }

  /**
   * Returns custom fields.
   * @param entityType Entity type.
   */
  public getCustomFields(entityType: string): MetaEntityBaseProperty[] {
    const currentLanguage = this.getLanguage();

    const entity = this.session.configuration.metamodel?.entities.find(
      (f) => f.name === entityType,
    );
    if (!entity) {
      return [];
    }

    const primitiveProps = entity.primitiveProperties.filter(
      (x) => x.customFieldId,
    );
    const complexProps = entity.complexProperties.filter(
      (x) => x.customFieldId,
    );
    const navigationProps = entity.navigationProperties.filter(
      (x) => x.customFieldId,
    );

    const fields: MetaEntityBaseProperty[] = [
      ...primitiveProps,
      ...complexProps,
      ...navigationProps,
    ];

    fields.forEach((field) => {
      field.name = _.camelCase(field.name);
      field.viewConfiguration.label = field.viewConfiguration.labels.find(
        (label) => label.language === currentLanguage,
      )?.value;
      field.viewConfiguration.placeholder =
        field.viewConfiguration.placeholders.find(
          (ph) => ph.language === currentLanguage,
        )?.value;
    });

    return fields;
  }

  private localeInitializer(localeId: string): Promise<any> {
    return import(
      /* webpackExclude: /extra|global/ */
      /* webpackInclude: /(az|be|bg|bs|bs-Latn|ca|ca-ES-VALENCIA|ca-FR|ca-IT|cs|cy|da|de|de-AT|de-BE|de-CH|de-LI|de-LU|el|el-CY|en|en-001|en-150|en-AT|en-AU|en-BE|en-CA|en-CH|en-CY|en-DE|en-DK|en-FI|en-FK|en-GB|en-GI|en-HK|en-IE|en-IL|en-MT|en-MY|en-NL|en-NZ|en-SE|en-SG|en-SI|es|et|eu|fi|fr|fr-BE|fr-CA|fr-LU|fr-MC|fr-MF|ga|gl|hr|hr-BA|hu|hy|id|is|it|it-CH|it-SM|kk|ky|lt|lv|mk|nb|nb-SJ|nl|nl-BE|nn|no|pl|pt|ro|ro-MD|ru|ru-BY|ru-KG|ru-KZ|ru-MD|ru-UA|sk|sl|sq|sq-MK|sq-XK|sr|sr-Cyrl|sr-Cyrl-BA|sr-Cyrl-ME|sr-Cyrl-XK|sr-Latn|sr-Latn-BA|sr-Latn-ME|sr-Latn-XK|sv|sv-AX|sv-FI|uk|uz)\.mjs$/ */
      `/node_modules/@angular/common/locales/${localeId}.mjs`
    ).then(
      (module) => registerLocaleData(module.default),
      (error) => {
        console.log(error);
        throw error;
      },
    );
  }
}
