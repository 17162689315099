import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { MetamodelDescriptionFilterService } from 'src/app/settings-app/metamodel/metamodel-description/filter/metamodel-description-filter.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { MetaEntity } from 'src/app/shared/models/entities/settings/metamodel.model';
import {
  Language,
  LocalString,
} from 'src/app/shared/models/enums/language.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ActionPanelService } from 'src/app/core/action-panel.service';

@Component({
  selector: 'tmt-metamodel-description',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './metamodel-description.component.html',
  styleUrl: './metamodel-description.component.scss',
  providers: [
    { provide: FilterService, useClass: MetamodelDescriptionFilterService },
  ],
})
export class MetamodelDescriptionComponent implements OnInit {
  public entities: MetaEntity[];

  public language = Language;
  public hasMessage = false;
  public noDataMessage = this.translateService.instant(
    'shared2.messages.noDisplayData',
  );

  private destroyRef = inject(DestroyRef);

  constructor(
    private dataService: DataService,
    private filterService: FilterService,
    private translateService: TranslateService,
    private actionPanelService: ActionPanelService,
  ) {}

  ngOnInit(): void {
    this.load();

    this.filterService.values$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.load());

    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.load();
      });
  }

  public joinString(strings: string[]): string {
    return strings.join(', ');
  }

  public getFormattedLocalStrings(localStrings: LocalString[]): string {
    return localStrings.map((x) => `${x.language}: ${x.value}`).join(', ');
  }

  /** Loads data. */
  private load(): void {
    const oDataParams = {
      filter: [],
    };

    if (this.filterService.values.text) {
      oDataParams.filter.push({
        'tolower(name)': {
          contains: this.filterService.values.text.toLowerCase(),
        },
      });
    }

    this.dataService
      .collection('MetaEntities')
      .query<MetaEntity[]>(oDataParams)
      .subscribe((entities) => {
        this.entities = entities;
        if (!entities.length) {
          this.hasMessage = true;
          return;
        }
        this.hasMessage = false;
      });
  }
}
