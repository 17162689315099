/** Dependency points coordinates for canvas element. */
export interface DependencyCoordinates {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
}

/** Quadrant of end point in relation to start point. */
export enum GraphicQuadrant {
  first = 1,
  second = 2,
  third = 3,
  fourth = 4,
}

export enum ArrowDirection {
  right = 'right',
  left = 'left',
}
