import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { ReferenceEntry } from 'src/app/settings-app/reference/model/reference.model';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'tmt-reference-entry-line',
  templateUrl: './reference-entry-line.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferenceEntryLineComponent implements OnInit {
  @Input() referenceEntry: ReferenceEntry;
  @Input() entityId: string;

  public isSaving: boolean;
  public form = this.fb.group({
    id: null,
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    code: ['', Validators.maxLength(Constants.formTextMaxLength)],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    isActive: false,
  });

  private referenceEntitiesCollection =
    this.data.collection('ReferenceEntries');

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    if (this.referenceEntry) {
      this.form.patchValue(this.referenceEntry);
    }
  }

  /** Dismisses changes and closes modal. */
  public cancel(): void {
    this.activeModal.dismiss();
  }

  /** Saves data and closes modal. */
  public ok(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared2.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    if (this.referenceEntry) {
      this.editReferenceEntry();
    } else {
      this.addReferenceEntry();
    }
  }

  /**
   *  Returns modal header.
   *
   * @returns Modal header as string.
   */
  public getHeader(): string {
    return this.referenceEntry
      ? 'components.referenceEntryLineComponent.props.editingHeader'
      : 'components.referenceEntryLineComponent.props.creationHeader';
  }

  /** Returns action button name. */
  public getActionBtnName(): string {
    return this.referenceEntry
      ? 'shared2.actions.save'
      : 'shared2.actions.create';
  }

  /** Adds reference entity. */
  private addReferenceEntry(): void {
    const formData = this.form.value;
    const data = {
      name: formData?.name ?? null,
      code: formData?.code ?? null,
      description: formData?.description ?? null,
      isActive: formData?.isActive ?? null,
      referenceId: this.entityId,
    };

    this.referenceEntitiesCollection.insert(data).subscribe({
      next: () => {
        this.isSaving = false;
        this.notification.successLocal(
          'components.referenceEntryLineComponent.messages.created',
        );
        this.activeModal.close(formData);
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.isSaving = false;
        this.cdr.detectChanges();
      },
    });
  }

  /** Edits reference entity. */
  private editReferenceEntry(): void {
    const formData = this.form.value;
    const data = {
      name: formData?.name ?? null,
      code: formData?.code ?? null,
      description: formData?.description ?? null,
      isActive: formData?.isActive ?? null,
    };

    this.referenceEntitiesCollection
      .entity(formData.id)
      .patch(data)
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.notification.successLocal(
            'components.referenceEntryLineComponent.messages.edited',
          );
          this.activeModal.close(formData);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
          this.cdr.detectChanges();
        },
      });
  }
}
