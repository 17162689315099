<ng-container [formGroup]="formGroup">
  <div class="row">
    <div class="col-xs-12 col-lg-10">
      <label class="group">
        {{ 'settings.customFields.card.props.groupSettings' | translate }}
      </label>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-5 col-xs-6">
      @if (fieldEntityType !== 'TimeAllocation') {
        @if (fieldType === customFieldType.string) {
          <div class="form-group">
            <label>{{ 'settings.customFields.card.props.defaultValue.label' | translate }}</label>
            <wp-text-box
              placeholder="{{ 'settings.customFields.card.props.defaultValue.label' | translate }}"
              formControlName="defaultValue"
            ></wp-text-box>
          </div>
        }
        @if (fieldType === customFieldType.dateOnly) {
          <div class="form-group">
            <label>{{ 'settings.customFields.card.props.defaultValue.label' | translate }}</label>
            <wp-date-box formControlName="defaultValue"></wp-date-box>
          </div>
        }
        @if (fieldType === customFieldType.decimal) {
          <div class="form-group">
            <label>{{ 'settings.customFields.card.props.defaultValue.label' | translate }}</label>
            <wp-number-box type="decimal" formControlName="defaultValue"></wp-number-box>
          </div>
        }
        @if (fieldType === customFieldType.integer) {
          <div class="form-group">
            <label>{{ 'settings.customFields.card.props.defaultValue.label' | translate }}</label>
            <wp-number-box type="integer" formControlName="defaultValue"></wp-number-box>
          </div>
        }
      }
      @if (fieldType === customFieldType.reference) {
        <!--      TODO: починить -->
        <!--        <div class="toolbar-container" style="padding-top: 0">-->
        <!--          @if (!readonly) {-->
        <!--            <button-->
        <!--              type="button"-->
        <!--              class="btn btn-default"-->
        <!--              title="{{ 'shared.actions.add' | translate }}"-->
        <!--              (click)="addLookupValue()"-->
        <!--            >-->
        <!--              <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>-->
        <!--              {{ 'shared.actions.add' | translate }}-->
        <!--            </button>-->
        <!--          }-->
        <!--        </div>-->
        <!--        <wp-grid [formArray]="lookupValues" [options]="gridOptions" [readonly]="readonly"></wp-grid>-->
      }
    </div>
    <div class="col-lg-5 col-xs-6">
      <!--    TODO: починить -->
      <!--      @if (fieldEntityType !== 'TimeAllocation') {-->
      <!--        @if (fieldType === customFieldType.reference) {-->
      <!--          <div class="form-group">-->
      <!--            <label>{{ 'settings.customFields.card.props.defaultValue.label' | translate }}</label>-->
      <!--            <wp-select-box-->
      <!--              [values]="lookupValues.value"-->
      <!--              formControlName="defaultValue"-->
      <!--              placeholder="{{ 'settings.customFields.card.props.defaultValue.label' | translate }}"-->
      <!--            >-->
      <!--            </wp-select-box>-->
      <!--            <small class="form-text text-body-secondary">{{-->
      <!--              'settings.customFields.card.props.defaultValue.help' | translate-->
      <!--            }}</small>-->
      <!--          </div>-->
      <!--        }-->
      <!--      }-->

      @if (fieldEntityType !== 'TimeSheetLine' && fieldEntityType !== 'TimeAllocation') {
        <div class="form-group">
          <label>{{ 'settings.customFields.card.props.formColumn' | translate }}</label>
          <wp-select-box
            style="width: 250px"
            [values]="formColumns"
            [allowNull]="false"
            placeholder="{{ 'settings.customFields.card.props.formColumn' | translate }}"
            formControlName="formColumn"
          ></wp-select-box>
        </div>
      }

      <div class="form-group">
        <label>{{ 'settings.customFields.card.props.orderNumber.label' | translate }}</label>
        <wp-number-box
          type="integer"
          [min]="0"
          [allowNull]="false"
          placeholder="{{ 'settings.customFields.card.props.orderNumber.ph' | translate }}"
          formControlName="orderNumber"
        ></wp-number-box>
      </div>
      @if (cardService.isRequiredFieldVisible(fieldEntityType)) {
        <div class="form-group">
          <div class="form-check">
            <input
              formControlName="isRequired"
              type="checkbox"
              class="form-check-input"
              id="isRequired"
            />
            <label class="form-check-label" for="isRequired">
              {{ 'settings.customFields.card.props.isRequired' | translate }}
            </label>
          </div>
        </div>
      }
    </div>
  </div>
</ng-container>
